import React from "react";
import { Layout, Row, Col } from "antd";
import MediaQuery from "react-responsive";

import "../../assets/styles/Process.css";

const { Content } = Layout;

const TradeProcess = () => {
  return (
    <>
      <Layout>
        <Content>
          <div className="section-container trade-process-container">
            <div className="title">
              <div className="section-title title-above">BLUEMENT Trade In</div>
              <div className="section-title-lg">
                Good Bye, 쓰던 시계
                <br />
                Hello, 새로운 시계
              </div>
              <div className="section-sub-title">
                <MediaQuery maxWidth={418.98}>
                  다양한 종류의 시계를
                  <br />
                  착용해보고 싶지 않으신가요?
                </MediaQuery>
                <MediaQuery minWidth={419}>
                  다양한 종류의 시계를 착용해보고 싶지 않으신가요?
                </MediaQuery>
                <br />
                <MediaQuery maxWidth={518.98}>
                  가지고 있는 시계를 즉시 판매하고,
                  <br />
                  다른 모델로 교환할 수 있습니다.
                </MediaQuery>
                <MediaQuery minWidth={519}>
                  가지고 있는 시계를 즉시 판매하고, 다른 모델로 교환할 수
                  있습니다.
                </MediaQuery>
                <br />
                <MediaQuery maxWidth={649.98}></MediaQuery>
                <MediaQuery minWidth={650}></MediaQuery>
                블루먼트에서 다양한 모델을 경험해보세요.
                {/* <MediaQuery maxWidth={649.98}>
                </MediaQuery>
                <MediaQuery minWidth={650}>
                </MediaQuery> */}
              </div>
            </div>

            <Row
              justify="center"
              className="process-container"
              gutter={[
                { xs: 16, sm: 16, md: 30, lg: 50, xl: 50, xxl: 70 },
                { xs: 70, sm: 70, md: 70, lg: 70, xl: 70, xxl: 70 },
              ]}
            >
              <Col span={6} xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                <div className="process-box">
                  <div className="process-icon">
                    <picture>
                      <source
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/building.webp"
                        type="image/webp"
                        loading="lazy"
                      />
                      <source
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/building.avif"
                        type="image/avif"
                        loading="lazy"
                      />
                      <img
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/building.png"
                        alt="매장 방문"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                  <div className="process-content">
                    <div className="process-no">1단계</div>
                    <div className="process-title">매장 방문</div>
                    <div className="process-desc">
                      판매하실 시계를 가지고 부티크에 방문해주세요. 네이버
                      예약을 활용하시면 더욱 편리합니다.
                    </div>
                  </div>
                </div>
              </Col>

              <Col span={6} xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                <div className="process-box">
                  <div className="process-icon">
                    <picture>
                      <source
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/communication.webp"
                        type="image/webp"
                        loading="lazy"
                      />
                      <source
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/communication.avif"
                        type="image/avif"
                        loading="lazy"
                      />
                      <img
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/communication.png"
                        alt="제품 상담 진행"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                  <div className="process-content">
                    <div className="process-no">2단계</div>
                    <div className="process-title">제품 상담 진행</div>
                    <div className="process-desc">
                      시계 전문 큐레이터와 1:1 상담을 진행합니다.
                    </div>
                  </div>
                </div>
              </Col>

              <Col span={6} xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                <div className="process-box">
                  <div className="process-icon">
                    <picture>
                      <source
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/inspection.webp"
                        type="image/webp"
                        loading="lazy"
                      />
                      <source
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/inspection.avif"
                        type="image/avif"
                        loading="lazy"
                      />
                      <img
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/inspection.png"
                        alt="시계 점검 및 조정"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                  <div className="process-content">
                    <div className="process-no">3단계</div>
                    <div className="process-title">시계 점검 및 조정</div>
                    <div className="process-desc">
                      전문 워치 메이커가 판매하실 시계 상태를 진단하고, 구매하실
                      시계의 조정을 진행합니다.
                    </div>
                  </div>
                </div>
              </Col>

              <Col span={6} xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                <div className="process-box">
                  <div className="process-icon">
                    <picture>
                      <source
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/wristwatch.webp"
                        type="image/webp"
                        loading="lazy"
                      />
                      <source
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/wristwatch.avif"
                        type="image/avif"
                        loading="lazy"
                      />
                      <img
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/wristwatch.png"
                        alt="거래 완료"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                  <div className="process-content">
                    <div className="process-no">4단계</div>
                    <div className="process-title">거래 완료</div>
                    <div className="process-desc">
                      필요 시 결제를 진행합니다. 새로운 시계 라이프를 즐기시기
                      바랍니다.
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Content>
      </Layout>
    </>
  );
};

export default TradeProcess;
