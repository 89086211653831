import React from "react";
import { Layout, Row, Col } from "antd";
import MediaQuery from "react-responsive";

import "../../assets/styles/Process.css";

const { Content } = Layout;

const BespokeProcess = () => {
  return (
    <>
      <Layout>
        <Content>
          <div className="section-container bespoke-process-container">
            <div className="title">
              <div className="section-title title-above">
                BLUEMENT Private Order
              </div>
              <div className="section-title-lg">
                최소한의 노력으로
                <br />
                원하는 시계 구매하기
              </div>
              <div className="section-sub-title">
                특별히 찾으시는 시계가 있으신가요?
                <br />
                <MediaQuery maxWidth={449.98}>
                  안전한 구매를 위해서는
                  <br />
                  시간과 비용을 투자해야 합니다.
                </MediaQuery>
                <MediaQuery minWidth={450}>
                  안전한 구매를 위해서는 시간과 비용을 투자해야 합니다.
                </MediaQuery>
                <br />
                <MediaQuery maxWidth={869.98}>
                  블루먼트 1:1 매입 요청 서비스를 통해
                  <br />
                  최소한의 노력으로 원하는 시계를 구매해보세요.
                </MediaQuery>
                <MediaQuery minWidth={870}>
                  블루먼트 1:1 매입 요청 서비스를 통해 최소한의 노력으로 원하는
                  시계를 구매해보세요.
                </MediaQuery>
              </div>
            </div>

            <Row
              justify="center"
              className="process-container"
              gutter={[
                { xs: 16, sm: 16, md: 30, lg: 50, xl: 50, xxl: 70 },
                { xs: 70, sm: 70, md: 70, lg: 70, xl: 70, xxl: 70 },
              ]}
            >
              <Col span={6} xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                <div className="process-box">
                  <div className="process-icon">
                    <picture>
                      <source
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/building.webp"
                        type="image/webp"
                        loading="lazy"
                      />
                      <source
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/building.avif"
                        type="image/avif"
                        loading="lazy"
                      />
                      <img
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/building.png"
                        alt="상담신청 & 방문"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                  <div className="process-content">
                    <div className="process-no">1단계</div>
                    <div className="process-title">상담신청 & 방문</div>
                    <div className="process-desc">
                      하단 양식 제출 후, 네이버 예약으로 방문 일시를 예약하시고
                      부티크로 방문해주세요.
                    </div>
                  </div>
                </div>
              </Col>

              <Col span={6} xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                <div className="process-box">
                  <div className="process-icon">
                    <picture>
                      <source
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/communication.webp"
                        type="image/webp"
                        loading="lazy"
                      />
                      <source
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/communication.avif"
                        type="image/avif"
                        loading="lazy"
                      />
                      <img
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/communication.png"
                        alt="상담 진행"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                  <div className="process-content">
                    <div className="process-no">2단계</div>
                    <div className="process-title">상담 진행</div>
                    <div className="process-desc">
                      매입 요청하신 시계에 대해 상담을 진행합니다. 이 과정에서
                      예약금이 발생할 수 있습니다.
                    </div>
                  </div>
                </div>
              </Col>

              <Col span={6} xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                <div className="process-box">
                  <div className="process-icon">
                    <picture>
                      <source
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/time-is-money.webp"
                        type="image/webp"
                        loading="lazy"
                      />
                      <source
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/time-is-money.avif"
                        type="image/avif"
                        loading="lazy"
                      />
                      <img
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/time-is-money.png"
                        alt="제품 매입 및 안내"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                  <div className="process-content">
                    <div className="process-no">3단계</div>
                    <div className="process-title">제품 매입 및 안내</div>
                    <div className="process-desc">
                      매입이 완료되면 고객님께 안내드립니다. 방문 예약 후
                      부티크에 방문해주세요.
                    </div>
                  </div>
                </div>
              </Col>

              <Col span={6} xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                <div className="process-box">
                  <div className="process-icon">
                    <picture>
                      <source
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/wristwatch.webp"
                        type="image/webp"
                        loading="lazy"
                      />
                      <source
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/wristwatch.avif"
                        type="image/avif"
                        loading="lazy"
                      />
                      <img
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/wristwatch.png"
                        alt="거래 완료"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                  <div className="process-content">
                    <div className="process-no">4단계</div>
                    <div className="process-title">거래 완료</div>
                    <div className="process-desc">
                      새로운 시계 라이프를 즐기시기 바랍니다.
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Content>
      </Layout>
    </>
  );
};

export default BespokeProcess;
