import React, { useEffect, useState, useRef } from "react";
import { Layout } from "antd";
import {
  Animator,
  ScrollContainer,
  ScrollPage,
  MoveOut,
} from "react-scroll-motion";
import { motion, useInView, useAnimation } from "framer-motion";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";
import $ from "jquery";

// Styles
import "../components/About/About.css";

// SVGs
import { ReactComponent as Mouse } from "../assets/svg/mouse.svg";
import ourMoto from "../assets/svg/our-moto.svg";
import blue from "../assets/svg/blue.svg";
import blueM from "../assets/svg/blue_m.svg";
import ment from "../assets/svg/ment.svg";
import mentM from "../assets/svg/ment_m.svg";

import PatekModal from "../components/Notice/PatekModal";
import GoogleMapComponent from "../components/GoogleMap/GoogleMapComponent";

import { AnalyticsContext } from "../Analytics";

const { Content } = Layout;

const About = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [timeRotateRatio, setTimeRotateRatio] = useState(0);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [showNoticeBanner, setShowNoticeBanner] = useState(true);
  const [showNoticeModal, setShowNoticeModal] = useState(false);
  //   const [showLogo, setShowLogo] = useState(false);
  const topHeadingRef = useRef(null);
  const ourStoryTextRef = useRef(null);
  const clockRef = useRef(null);
  const ourStoryRef = useRef(null);
  const ourVisionRef = useRef(null);
  const ourMotoRef = useRef(null);
  const logoRef = useRef(null);
  const logoSectionRef = useRef(null);
  const isInLogoView = useInView(logoRef, { once: true });
  const animateLogo = useAnimation();

  const { analytics } = React.useContext(AnalyticsContext); // Google Analytics

  const onScrollToComponent = (target) => {
    switch (target) {
      case "ourStory":
        ourStoryRef.current.scrollIntoView({ block: "start" });
        break;
      case "ourVision":
        ourVisionRef.current.scrollIntoView({ block: "start" });
        break;
      case "ourMoto":
        ourMotoRef.current.scrollIntoView({ block: "start" });
        break;
      case "logo":
        logoSectionRef.current.scrollIntoView({ block: "start" });
        break;
      default:
        break;
    }
  };

  const handleScroll = () => {
    const position = window.scrollY;
    const scrollHeight = document.body.scrollHeight;
    const clientHeight = document.body.clientHeight;
    const pageHeight = scrollHeight - clientHeight;
    const scrollRatio = (position / pageHeight) * 100;
    setScrollProgress(scrollRatio);

    // let clockStartPoint = 0;
    // let clockHeight = 0;

    // if (topHeadingRef.current && clockRef.current) {
    //   clockStartPoint = topHeadingRef.current.offsetHeight / 3;
    //   clockHeight = clockRef.current.offsetHeight;
    // }
    const clockStartPoint = topHeadingRef.current?.offsetHeight / 3;
    const clockHeight = clockRef.current?.offsetHeight;
    // console.log(clockStartPoint, clockHeight);

    if (position < clockStartPoint) {
      setTimeRotateRatio(0);
    }

    if (position >= clockStartPoint) {
      const diff = position - clockStartPoint;
      setTimeRotateRatio(diff / clockHeight);
    }

    setScrollPosition(position);
  };

  useEffect(() => {
    if (isInLogoView) {
      animateLogo.start("visible");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInLogoView]);

  useEffect(() => {
    // Google Analytics
    analytics.send({ hitType: "pageview", page: "/" });

    const timer = setInterval(() => {
      window.addEventListener("scroll", handleScroll);
    }, 100);

    return () => {
      clearInterval(timer);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const openNoticeModal = () => {
    setShowNoticeModal(true);
    $(".notice-content").css("z-index", "0");
  };

  const closeNoticeModal = () => {
    setShowNoticeModal(!showNoticeModal);
    $(".notice-content").css("z-index", "2");
  };

  return (
    <ScrollContainer>
      <Layout>
        <Content className="about-container">
          {/* 공지 모달 */}
          {showNoticeModal && (
            <PatekModal
              className="patek-modal"
              closeModal={() => closeNoticeModal()}
            ></PatekModal>
          )}

          {/* 스크롤 프로그레스 */}
          <div className="scroll-indicator-container">
            <Mouse className="mouse" />

            <div className="scroll-indicator-bar">
              <div
                className="scroll-indicator-bar-active"
                style={{ height: `${scrollProgress}%` }}
              ></div>
            </div>
          </div>

          {/* 첫 섹션 */}
          <ScrollPage className="top-heading-scrollpage">
            {showNoticeBanner && (
              <div className="notice-banner-sticky">
                <div className="notice-slider-container">
                  <div
                    className="notice-content"
                    onClick={() => openNoticeModal()}
                  >
                    롤렉스 및 파텍 필립 위탁판매 거래 시작
                  </div>
                </div>

                <div className="close-btn">
                  <svg
                    onClick={() => setShowNoticeBanner(false)}
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                  >
                    <g
                      id="close_botton"
                      data-name="close botton"
                      transform="translate(-1870 -70)"
                    >
                      <rect
                        id="button_area"
                        data-name="button area"
                        width="50"
                        height="50"
                        transform="translate(1870 70)"
                      />
                      <path
                        id="x_mark.svg"
                        data-name="x mark.svg"
                        d="M6,18,18,6M6,6,18,18"
                        transform="translate(1883 83)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            )}

            <div className="about-top-heading" ref={topHeadingRef}>
              <div className="text-container">
                <Animator animation={MoveOut(1000, 0)}>
                  <div
                    className="slide-out-left first-line"
                    ref={ourStoryTextRef}
                  >
                    OUR STORY
                  </div>
                </Animator>
                <Animator animation={MoveOut(-1000, 0)}>
                  <div className="slide-out-right">FOR THOSE WHO</div>
                </Animator>
                <Animator animation={MoveOut(1000, 0)}>
                  <div className="slide-out-left">
                    LOVE <span className="bigmoore">Watches.</span>
                  </div>
                </Animator>
                {/* <Animator animation={MoveOut(1000, 0)}>
                  <div className="bigmoore slide-out-left last-line">
                    BLUEMENT
                  </div>
                </Animator> */}
              </div>
            </div>
          </ScrollPage>

          {/* 첫 인사 */}
          <div className="time-minute-section" ref={clockRef}>
            <div className="clock-animation">
              <div
                className="time-rotate"
                style={{ transform: `rotate(${timeRotateRatio * 360}deg)` }}
              >
                <svg
                  id="time.svg"
                  xmlns="http://www.w3.org/2000/svg"
                  width="200"
                  height="199.999"
                  viewBox="0 0 200 199.999"
                >
                  <defs>
                    <clipPath id="clip-path">
                      <rect
                        id="사각형_71"
                        data-name="사각형 71"
                        width="200"
                        height="199.999"
                        fill="none"
                      />
                    </clipPath>
                  </defs>
                  <g
                    id="그룹_103"
                    data-name="그룹 103"
                    clipPath="url(#clip-path)"
                  >
                    <path
                      id="패스_238"
                      data-name="패스 238"
                      d="M100,200A100,100,0,1,1,200,100,100.113,100.113,0,0,1,100,200M100,1a99,99,0,1,0,99,99A99.108,99.108,0,0,0,100,1"
                      fill="#fff"
                    />
                    <rect
                      id="사각형_70"
                      data-name="사각형 70"
                      width="39.966"
                      height="1.003"
                      transform="translate(99.791 99.553) rotate(-25.028)"
                      fill="#1a1818"
                    />
                  </g>
                </svg>
              </div>

              <div
                className="time-rotate"
                style={{ transform: `rotate(${timeRotateRatio * 2 * 360}deg)` }}
              >
                <svg
                  id="minute.svg"
                  xmlns="http://www.w3.org/2000/svg"
                  width="200"
                  height="199.999"
                  viewBox="0 0 200 199.999"
                >
                  <defs>
                    <clipPath id="clip-path">
                      <rect
                        id="사각형_73"
                        data-name="사각형 73"
                        width="200"
                        height="199.999"
                        fill="none"
                      />
                    </clipPath>
                  </defs>
                  <g
                    id="그룹_105"
                    data-name="그룹 105"
                    clipPath="url(#clip-path)"
                  >
                    <path
                      id="패스_239"
                      data-name="패스 239"
                      d="M100,200A100,100,0,1,1,200,100,100.113,100.113,0,0,1,100,200M100,1a99,99,0,1,0,99,99A99.108,99.108,0,0,0,100,1"
                      fill="#fff"
                    />
                    <rect
                      id="사각형_72"
                      data-name="사각형 72"
                      width="1.003"
                      height="84.43"
                      transform="translate(99.498 15.57)"
                      fill="#1a1818"
                    />
                  </g>
                </svg>
              </div>
            </div>

            <div className="text-box">
              <MediaQuery minWidth={800}>
                시계의 가치를 사랑하는 열정 가득한 사람들이 모인
                <br />
                BLUEMENT 입니다.
              </MediaQuery>
              <MediaQuery maxWidth={799.98}>
                <br />
                시계의 가치를 사랑하는
                <br />
                열정 가득한 사람들이 모인
                <br />
                BLUEMENT 입니다.
              </MediaQuery>
            </div>

            <div className="button-box">
              <button
                className="our-story-btn"
                onClick={() => onScrollToComponent("ourStory")}
              >
                OUR STORY {">"}
              </button>
            </div>
          </div>

          {/* Our Story */}
          <div className="story-section our-story" ref={ourStoryRef}>
            <div className="left-box">
              <MediaQuery maxWidth={1199.98}>
                <img
                  srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/about/our-story-m.jpg"
                  alt="our story"
                />
              </MediaQuery>
            </div>
            <div className="right-box">
              <div className="text-box">
                <MediaQuery maxWidth={649.98}>
                  <div className="title">
                    OUR STORY :<br />
                    FOR WATCH LOVERS
                  </div>
                </MediaQuery>
                <MediaQuery minWidth={650}>
                  <div className="title">OUR STORY : FOR WATCH LOVERS</div>
                </MediaQuery>

                <div className="sub-title">
                  <MediaQuery maxWidth={520.98}>
                    시계를 사랑하는 이들의
                    <br />
                    안식처가 될 수 있도록
                  </MediaQuery>
                  <MediaQuery minWidth={521}>
                    시계를 사랑하는 이들의 안식처가 될 수 있도록
                  </MediaQuery>
                </div>
                <div className="desc">
                  삶의 가장 작은 조각은 지금 내가 보내고 있는 시간입니다. 시간을
                  확인하고, 기록하기 위해서 당대 가장 강력한 힘을 가진
                  권력자들은 시계를 만들기 시작했습니다.
                  <br />
                  <br />
                  그리고 이제는 누구나 시계를 소유하고 시간을 확인할 수 있는
                  시대가 되었습니다. 그중 기계식 시계는 전자시계, 스마트워치,
                  휴대용 미디어기기의 발전 등 수많은 도전에도 사람의 손으로
                  만드는 가장 정밀한 기계로서 그 가치를 더하며 발전하고
                  있습니다.
                  <br />
                  <br />
                  기계식 시계를 사랑하는 고객님들의 기대에 부응하는 멋진 시계
                  거래환경을 만들어 가기 위해 블루먼트가 앞장서서
                  도전하겠습니다. 우리가 가는 길이 시계를 사랑하는 분들의
                  안식처가 되기를 바랍니다.
                </div>
              </div>
              <div className="link-box">
                <div onClick={() => onScrollToComponent("ourMoto")}>
                  OUR MOTO{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.121"
                    height="17.121"
                    viewBox="0 0 17.121 17.121"
                  >
                    <path
                      id="page-down.svg"
                      d="M4.5,4.5l15,15m0,0V8.25m0,11.25H8.25"
                      transform="translate(-3.439 -3.439)"
                      fill="none"
                      stroke="#888"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    />
                  </svg>
                </div>

                <div onClick={() => onScrollToComponent("logo")}>
                  BLUEMENT MEANS{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.121"
                    height="17.121"
                    viewBox="0 0 17.121 17.121"
                  >
                    <path
                      id="page-down.svg"
                      d="M4.5,4.5l15,15m0,0V8.25m0,11.25H8.25"
                      transform="translate(-3.439 -3.439)"
                      fill="none"
                      stroke="#888"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    />
                  </svg>
                </div>

                <div
                  className="no-mb"
                  onClick={() => onScrollToComponent("ourVision")}
                >
                  OUR VISION{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.121"
                    height="17.121"
                    viewBox="0 0 17.121 17.121"
                  >
                    <path
                      id="page-down.svg"
                      d="M4.5,4.5l15,15m0,0V8.25m0,11.25H8.25"
                      transform="translate(-3.439 -3.439)"
                      fill="none"
                      stroke="#888"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          {/* Our Moto */}
          <div className="story-section our-moto" ref={ourMotoRef}>
            <div className="left-box">
              <MediaQuery minWidth={1200}>
                <img
                  srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/about/our-moto.jpg"
                  alt="our moto"
                />
              </MediaQuery>

              <MediaQuery maxWidth={1199.98}>
                <img
                  srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/about/our-moto-m.jpg"
                  alt="our story"
                />
              </MediaQuery>
            </div>
            <div className="right-box">
              <div className="text-box">
                <div className="title">OUR MOTO</div>
                <div className="sub-title">
                  <MediaQuery minWidth={640}>
                    투명한 거래, 믿을 수 있는 제품, 시계와 고객에 대한 열정
                  </MediaQuery>
                  <MediaQuery maxWidth={639.98}>
                    투명한 거래, 믿을 수 있는 제품,
                    <br />
                    시계와 고객에 대한 열정
                  </MediaQuery>
                </div>
                <div className="desc">
                  우리가 블루먼트를 만들게 된 이유는 아주 사소한 걱정에서부터
                  시작되었습니다. 아날로그 시계라는 작품의 가치는 날로 더해져
                  감에도 불구하고 이를 정확하게 관리하고 수리, 복원하는 기술은
                  뒤로 한 채, 일반 중고 제품과 같은 수준으로 시계들이 거래되고
                  있는 시장에 대한 걱정이었습니다.
                  <br />
                  <br />
                  IMF 시기 이후 세계 3위 시계 생산 국가였던 한국의 시계 산업이
                  몰락하면서 인력양성이 끊겼던 과거를 넘어서서, 블루먼트에는
                  해외에서 기술을 공부하고, 유수의 브랜드에서 경험을 쌓은 젊고
                  열정 가득한 인력들이 새로운 도전을 위해 모였습니다.
                </div>
              </div>
              <div className="link-box">
                <img srcSet={ourMoto} alt="our moto" />
                {/* <img
                  srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/about/our-moto-circle.png"
                  alt="our moto"
                /> */}
              </div>
            </div>
          </div>

          {/* 블루먼트 뜻 */}
          <div className="bluement-meaning-section" ref={logoSectionRef}>
            <div className="text-container">
              <div className="bigmoore title">We are</div>
              <div></div>
            </div>

            <div className="logo">
              <motion.div
                variants={{
                  hidden: { opacity: 0, x: -100 },
                  visible: { opacity: 1, x: 0 },
                }}
                initial="hidden"
                animate={animateLogo}
                transition={{ duration: 1 }}
                ref={logoRef}
              >
                <MediaQuery minWidth={825}>
                  <img srcSet={blue} alt="BLUE" />
                </MediaQuery>
                <MediaQuery maxWidth={824.98}>
                  <img srcSet={blueM} alt="BLUE" className="img-blue" />
                </MediaQuery>
              </motion.div>
              <motion.div
                variants={{
                  hidden: { opacity: 0, x: 100 },
                  visible: { opacity: 1, x: 0 },
                }}
                initial="hidden"
                animate={animateLogo}
                transition={{ duration: 1 }}
              >
                <MediaQuery minWidth={825}>
                  <img srcSet={ment} alt="MENT" />
                </MediaQuery>
                <MediaQuery maxWidth={824.98}>
                  <img srcSet={mentM} alt="MENT" className="img-ment" />
                </MediaQuery>
              </motion.div>
            </div>

            <div className="desc">
              <motion.div
                className="blue"
                variants={{
                  hidden: { opacity: 0, x: -100 },
                  visible: { opacity: 1, x: 0 },
                }}
                initial="hidden"
                animate={animateLogo}
                transition={{ duration: 1 }}
              >
                “BLUE”는 워치메이킹에서의 독창성과 수집대상인 Timepiece를
                가리킴과 동시에 궁극적으로 우리가 Pre-Owned Watch 시장에
                소개하고자 하는 거래의 투명함과 진정한 신뢰를 상징합니다.
              </motion.div>
              <motion.div
                className="ment"
                variants={{
                  hidden: { opacity: 0, x: 100 },
                  visible: { opacity: 1, x: 0 },
                }}
                initial="hidden"
                animate={animateLogo}
                transition={{ duration: 1 }}
              >
                “MENT”는 시계의 심장인 Movement를 의미하며, 보이지 않는 부분도
                완벽하게 작업하겠다는 의지의 표현입니다. 또한, 고객님께서 우리의
                시계를 만나는 순간(Moment)을 의미하기도 합니다.
              </motion.div>
            </div>
          </div>

          {/* Our Vision */}
          <div className="story-section our-vision" ref={ourVisionRef}>
            <div className="left-box">
              <MediaQuery minWidth={1200}>
                <img
                  srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/about/our-vision.jpg"
                  alt="our vision"
                />
              </MediaQuery>
              <MediaQuery maxWidth={1199.98}>
                <img
                  srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/about/our-vision-m.jpg"
                  alt="our story"
                />
              </MediaQuery>
            </div>
            <div className="right-box">
              <div className="text-box">
                <div className="title">OUR VISION</div>
                <div className="sub-title">
                  <MediaQuery maxWidth={444.98}>
                    누구나 마음놓고
                    <br />
                    거래할 수 있는 플랫폼
                  </MediaQuery>
                  <MediaQuery minWidth={445}>
                    누구나 마음놓고 거래할 수 있는 플랫폼
                  </MediaQuery>
                </div>
                <div className="desc">
                  중고 시계 시장은 높은 단가, 추적이 어려운 제품의 이력과
                  부품관리상태, 자금 거래가 중심이 된 거래로 일반 고객님들이
                  마음 편히 접근하기 어려운 형태로 발전되어 왔습니다.
                  <br />
                  <br />
                  블루먼트는 누구나 마음 놓고 거래할 수 있는 중고 시계거래
                  플랫폼을 만들기 위해서 거래의 모든 과정을 투명하게 새롭게
                  정립하고, 정직하게 점검된 믿을 수 있는 제품을 제공해
                  드리겠습니다.
                  <br />
                  <br />
                  시계 시장에 대한 열정적인 마음으로 고객님들의 갈증을 해소해
                  드리기 위해 고민하겠습니다.
                </div>
              </div>
              <div className="link-box">
                <Link to="/buy/collection">
                  <div className="no-mb">PRE-OWNED WATCH 보러가기 {">"}</div>
                </Link>
              </div>
            </div>
          </div>

          {/* Store */}
          <div className="store-section">
            {/* <div className="animated-text">
              <img
                srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/about/store-animated-text.png"
                alt="Animated Text"
              />
              <img
                srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/about/store-animated-text.png"
                alt="Animated Text"
              />
              <img
                srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/about/store-animated-text.png"
                alt="Animated Text"
              />
              <img
                srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/about/store-animated-text.png"
                alt="Animated Text"
              />
              <img
                srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/about/store-animated-text.png"
                alt="Animated Text"
              />
            </div> */}

            <div className="title">
              <img
                srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/about/store-text.png"
                alt="Blument Boutique"
              />
            </div>

            {/* <div className="store-name">블루먼트 압구정로데오점</div> */}
            <div className="store-info">
              <div className="addr">
                서울 강남구 선릉로153길 26, 성준빌딩 1층
              </div>
              <div className="hour">월 - 토 10:00am - 7:00pm</div>
              <div className="phone">02-545-9701</div>
            </div>

            <div className="store-map">
              <GoogleMapComponent />
            </div>

            <div className="store-button">
              <a href="/" target="_blank" rel="noreferrer">
                <button>방문 예약하기</button>
              </a>
            </div>
          </div>
        </Content>
      </Layout>
    </ScrollContainer>
  );
};

export default About;
