import React from 'react';
import { Divider, Table } from 'antd';
// import { Link } from 'react-router-dom';

import './Privacy.css';

const DATA_IDX = 0;
const COLUMN_IDX = 1;

const Privacy202304 = () => {
  const no2table = [
    [
      {
        key: '1',
        category:
          '이용자 식별, 회원 가입/탈퇴, 나이 확인, 이용자 요청사항 처리, 공지사항 등',
        purpose: '회원 관리 및 본인 확인',
      },
      {
        key: '2',
        category: '매입 문의 시',
        purpose:
          '시계 매입에 대한 문의 내용 확인 및 본인 식별, 방문 예약 확인, 서비스 부정이용 방지, 문의 답변, 분쟁 조정을 위한 기록 보존',
      },
      {
        key: '3',
        category: '일반 문의 시',
        purpose:
          '제휴, 채용, 사이트 이용 등 회사에 문의 시 문의 내용 확인 및 본인 식별, 서비스 부정이용 방지, 문의 답변, 분쟁 조정을 위한 기록 보존',
      },
      {
        key: '4',
        category: '마케팅, 광고',
        purpose: '마케팅·광고 등에 활용',
      },
      {
        key: '5',
        category: '서비스 관련',
        purpose: '고객 문의 응대, 기존 서비스 개선 및 신규 서비스 개발',
      },
    ],
    [
      {
        title: '구분',
        dataIndex: 'category',
        key: 'category',
      },
      {
        title: '이용목적',
        dataIndex: 'purpose',
        key: 'purpose',
      },
    ],
  ];

  const no3table = [
    [
      {
        key: '1',
        category: '필수항목',
        purpose:
          '성명, 이메일 주소, 휴대폰 번호, 생년월일, 성별, 내/외국인 여부, 중복가입 확인정보(DI), 웹사이트 이용정보',
      },
      {
        key: '2',
        category: '선택항목',
        purpose:
          '1) 가입하신 사유, 직업, 결혼여부, 결혼기념일, 관심 카테고리, 주된 휴대폰 사용 목적(개인용/업무용), 가구정보 [배우자 및/또는 자녀의 성명 · 전화번호 · 주소 · E-mail · SNS 계정정보 · 생년월일 등] \n' +
          '2) 시계 구매 관련 정보[구입처, 구입가격 등]\n' +
          '3) 마케팅, 이벤트 내역\n' +
          '\t• 마케팅 활동ㆍ이벤트 관련 정보[마케팅활동 내용 및 결과, 이벤트참여 내용 및 결과]\n' +
          '\t• 쿠폰/이벤트코드 보유 및 사용, 경품선택/수령결과\n' +
          '4) 제휴사 내역\n' +
          '\t• 제휴사 및 제휴가맹점 관련정보 [제휴사명, 제휴사 회원번호, 제휴사 회원가입일, 이벤트 참여일, 제휴사 관련 혜택 및 마케팅활동 정보]\n' +
          '5) 서비스 가입 및 이용시 고객이 입력한 정보\n' +
          '6) 기타 계약의 체결ㆍ유지ㆍ이행ㆍ관리ㆍ행사참여 등과 관련하여 본인이 제공한 정보',
      },
    ],
    [
      {
        title: '구분',
        dataIndex: 'category',
        key: 'category',
      },
      {
        title: '수집 및 이용 항목',
        dataIndex: 'purpose',
        key: 'purpose',
      },
    ],
  ];

  const no4table = [
    [
      {
        key: '1',
        content: '매입 문의 정보',
        purpose:
          '시계 매입에 대한 문의 내용 확인 및 본인 식별, 방문 예약 확인, 서비스 부정이용 방지, 문의 답변, 분쟁 조정을 위한 기록 보존',
        period: '5년',
      },
      {
        key: '2',
        content: '일반 문의 정보',
        purpose:
          '제휴, 채용, 사이트 이용 등 회사에 문의 시 문의 내용 확인 및 본인 식별, 서비스 부정이용 방지, 문의 답변, 분쟁 조정을 위한 기록 보존',
        period: '5년',
      },
    ],
    [
      {
        title: '항목',
        dataIndex: 'content',
        key: 'content',
      },
      {
        title: '보유 근거',
        dataIndex: 'purpose',
        key: 'purpose',
      },
      {
        title: '보유 기간',
        dataIndex: 'period',
        key: 'period',
      },
    ],
  ];

  const no5table = [
    [
      {
        key: '1',
        company: 'Amazon Web Services Inc.',
        content: '매입/일반 문의 시 수집된 개인정보',
        country: 'AWS 서울 리전',
        time: '문의 메일 전송 시점에 네트워크를 통한 전송',
        period:
          '서비스 변경 시까지(현재 회사가 이용 중인 클라우드 서비스 이용 변경 시까지)',
      },
    ],
    [
      {
        title: '수탁 업체',
        dataIndex: 'company',
        key: 'company',
        width: '10%',
      },
      {
        title: '이전 항목',
        dataIndex: 'content',
        key: 'content',
        width: '30%',
      },
      {
        title: '이전 국가',
        dataIndex: 'country',
        key: 'country',
        width: '10%',
      },
      {
        title: '이전 일시 및 방법',
        dataIndex: 'time',
        key: 'time',
        width: '20%',
      },
      {
        title: '개인정보 보유 및 이용기간',
        dataIndex: 'period',
        key: 'period',
        width: 50,
      },
    ],
  ];

  const no9table = [
    [
      {
        key: '1',
        header: '보호책임자 성명',
        content: '이재윤',
      },
      {
        key: '2',
        header: '직책/직급',
        content: '대표',
      },
      {
        key: '4',
        header: '연락처',
        content: '02-545-9702',
      },
      {
        key: '5',
        header: '이메일',
        content: 'support@bluebase.kr',
      },
    ],
    [
      {
        title: '항목',
        dataIndex: 'header',
        key: 'header',
      },
      {
        title: '내용',
        dataIndex: 'content',
        key: 'content',
      },
    ],
  ];

  const no10table = [
    [
      {
        key: '1',
        header: '부서명',
        content: '테크팀',
      },
      {
        key: '2',
        header: '담당자',
        content: '심현정',
      },
      {
        key: '3',
        header: '연락처',
        content: '02-545-9702',
      },
      {
        key: '4',
        header: '이메일',
        content: 'hyunjeong.shim@bluebase.kr',
      },
    ],
    [
      {
        title: '항목',
        dataIndex: 'header',
        key: 'header',
      },
      {
        title: '내용',
        dataIndex: 'content',
        key: 'content',
      },
    ],
  ];
  return (
    <>
      <div className="privacy-container">
        <p>
          {'<'} 주식회사 블루베이스 압구정 로데오점 {'>'}(이하 ‘회사')는
          웹사이트 (<a href="https://bluement.kr">https://bluement.kr</a> 이하
          '블루먼트') 이용 및 제반 서비스 제공 시 「개인정보 보호법」 제30조에
          따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속·원활하게
          처리하기 위하여 다음과 같이 개인정보 처리방침을 수립 · 공개합니다.
        </p>
        <Divider />

        <h2>제1조 [개인정보 수집에 대한 동의] </h2>
        <p>
          회사는 이용자의 개별 서비스 신청 시 개인정보 수집 및 이용에 동의할 수
          있는 절차를 마련하고 있습니다. 이용자는 동의를 거부할 수 있으며 동의를
          거부하는 경우 해당 서비스 이용이 제한될 수 있습니다.
        </p>

        <h2>제2조 [개인정보 수집 및 이용 목적]</h2>
        <p>
          회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는
          개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이
          변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는
          등 필요한 조치를 이행할 예정입니다.
        </p>
        <Table
          dataSource={no2table[DATA_IDX]}
          columns={no2table[COLUMN_IDX]}
          pagination={false}
          scroll={{ x: true }}
        ></Table>

        <h2>제3조 [개인정보 수집 항목 및 방법]</h2>
        <h3>가. 개인정보 수집 항목</h3>
        <p>
          회사는 기본적인 서비스 제공을 위한 필수 정보만을 수집하고 있으며, 선택
          정보의 경우 입력하지 않더라도 서비스 이용이 제한되지 않습니다. 회사는
          수집한 정보를 이용 목적 외의 목적으로 사용하지 않습니다.
        </p>
        <Table
          style={{ whiteSpace: 'pre' }}
          dataSource={no3table[DATA_IDX]}
          columns={no3table[COLUMN_IDX]}
          pagination={false}
          scroll={{ x: true }}
        ></Table>

        <h3>나. 개인정보 수집 방법</h3>
        <p>회사는 다음과 같이 개인정보를 수집합니다.</p>
        <p>
          <span className="circle-number">①</span> 회원 가입하거나 서비스를
          이용할 때 사용자가 직접 제공한 개인정보를 수집
        </p>
        <p>
          <span className="circle-number">②</span> 서비스를 이용할 때 앱,
          웹사이트 등에서 자동으로 생성된 정보(IP주소, 서비스 이용 기록 등)를
          수집
        </p>
        <p>
          <span className="circle-number">③</span> 고객 서비스 상담할 때 전화,
          이메일, 상담게시판, 상담 메뉴 등을 통한 수집
        </p>
        <p>
          <span className="circle-number">④</span> 사용자가 참여한 이벤트,
          프로모션 등을 통한 수집
        </p>
        <p>
          <span className="circle-number">⑤</span> 회사와 제휴한 외부 업체,
          단체를 통해 받은 사용자 동의 등 적법한 절차에 따라 제공된 개인정보를
          수집
        </p>

        <h2>제4조 [개인정보 보유 및 이용기간]</h2>
        <p>
          회사는 이용자의 개인정보 수집 및 이용 동의일로부터 5년간 본 처리방침에
          따라 수집한 매입 문의 및 일반 문의 관련 개인정보를 처리 및 보유합니다.
          단, 관계법령의 규정에 따라 개인정보를 보유하여야 할 필요가 있을 경우
          일정기간 보유되며 이때 보유되는 개인정보의 열람 및 이용은 해당 사유로
          제한됩니다.
        </p>
        <Table
          dataSource={no4table[DATA_IDX]}
          columns={no4table[COLUMN_IDX]}
          pagination={false}
          scroll={{ x: true }}
        ></Table>

        <h2>제5조 [개인정보 위탁처리]</h2>
        <p>
          <span className="circle-number">①</span> 회사는 서비스 제공의 안정성과
          최신 기술을 이용자에게 제공하기 위해 국외에 개인정보를 위탁하고
          있으며, 이용자로부터 취득 또는 생성한 개인정보를 AWS (Amazon Web
          Services Inc.)가 보유하고 있는 데이터베이스(물리적 저장 장소: 서울)에
          저장합니다. AWS는 해당 서버의 물리적인 관리만을 행하고, 이용자의
          개인정보에 접근할 수 없습니다.
        </p>
        <p>
          <span className="circle-number">②</span> 개인정보의 처리를 위탁하는
          경우에는 개인정보 보호의 안전을 기하기 위하여 개인정보보호 관련
          지시엄수, 개인정보에 대한 비밀유지, 제3자 제공의 금지 및 사고시의
          책임부담, 위탁기간, 처리 종료 후의 개인정보의 반환 또는 파기 등을
          명확히 규정하고, 위탁업체가 개인정보를 안전하게 처리하도록 감독합니다.
        </p>
        <p>
          <span className="circle-number">③</span> 위탁업무의 내용이나 수탁자가
          변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록
          하겠습니다.
        </p>
        <Table
          dataSource={no5table[DATA_IDX]}
          columns={no5table[COLUMN_IDX]}
          pagination={false}
          scroll={{ x: true }}
        ></Table>

        <h2>제6조 [개인정보의 제3자 제공]</h2>
        <p>
          회사는 개인정보를 개인정보의 처리목적 및 수집항목에서 고지한 범위
          내에서 사용하며, 정보주체의 사전 동의 없이는 동 범위를 초과하여
          이용하거나 원칙적으로 개인정보를 외부에 공개하지 않습니다. 다만 아래의
          경우에는 예외로 합니다.
        </p>
        <p>
          <span className="circle-number">①</span> 정보주체가 사전에 동의한 경우
        </p>
        <p>
          <span className="circle-number">②</span> 법령의 규정에 의거하거나,
          수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는
          경우
        </p>

        <h2>제7조 [개인정보의 파기]</h2>
        <p>
          <span className="circle-number">①</span> 회사는 원칙적으로 개인정보
          처리 목적이 달성되어 개인정보 처리가 불필요하다고 인정되는 경우와
          이용자가 개인정보의 파기를 요청한 경우에는 지체 없이 해당 개인정보를
          파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.
        </p>
        <p>
          <span className="circle-number">②</span> 정보주체로부터 동의 받은
          개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른
          법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를
          별도의 데이터베이스(DB) 또는 서류함으로 옮겨 내부 방침 및 기타 관련
          법령에 따라 지체없이 파기됩니다. 이때 내부 방침에 따라 별도DB 또는
          문서로 옮겨진 개인정보는 법률에 의한 경우를 제외하고는 이용되지
          않습니다.
        </p>
        <p>
          <span className="circle-number">③</span> 이용자의 개인정보는
          개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일
          이내에, 개인정보처리 목적달성, 해당 서비스의 폐지, 사업의 종료 등 그
          개인정보가 불필요하게 되었을 때에는 개인정보 처리가 불필요한 것으로
          인정되는 날로부터 5일 이내에 파기합니다.
        </p>
        <p>
          <span className="circle-number">④</span> 개인정보 파기의 절차 및
          방법은 다음과 같습니다.
        </p>
        <br />
        <p>1. 파기절차</p>
        <p>
          회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보
          보호책임자의 승인을 받아 개인정보를 파기합니다.
        </p>
        <p>2. 파기방법</p>
        <p>
          전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을
          사용합니다. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
          파기합니다
        </p>

        <h2>제8조 [정보주체와 법정대리인의 권리·의무 및 그 행사방법]</h2>
        <p>
          <span className="circle-number">①</span> 정보주체는 회사에 대해
          언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수
          있습니다.
        </p>
        <p>
          <span className="circle-number">②</span> 제1항에 따른 권리 행사는
          회사에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면,
          전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해
          지체 없이 조치하겠습니다.
        </p>
        <p>
          <span className="circle-number">③</span> 제1항에 따른 권리 행사는
          정보주체의 법정대리인이나 위임을 받은 등 대리인을 통하여 하실 수
          있습니다.이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지
          제11호 서식에 따른 위임장을 제출하셔야 합니다.
        </p>
        <p>
          <span className="circle-number">④</span> 개인정보 열람 및 처리정지
          요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여
          정보주체의 권리가 제한 될 수 있습니다.
        </p>
        <p>
          <span className="circle-number">⑤</span> 개인정보의 정정 및 삭제
          요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는
          경우에는 그 삭제를 요구할 수 없습니다.
        </p>
        <p>
          <span className="circle-number">⑥</span> 회사는 정보주체 권리에 따른
          열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한
          자가 본인이거나 정당한 대리인인지를 확인합니다.
        </p>

        <h2>제9조 [개인정보의 안전성 확보 조치]</h2>
        <p>
          회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고
          있습니다.
        </p>
        <h3>가. 개인정보 취급 직원의 최소화 및 교육</h3>
        <p>
          개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여
          개인정보를 관리하는 대책을 시행하고 있습니다.
        </p>
        <h3>나. 개인정보의 암호화</h3>
        <p>
          이용자의 개인정보는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수
          있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금
          기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.
        </p>
        <h3>다. 접속기록의 보관 및 위변조 방지</h3>
        <p>
          개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고
          있으며,다만, 5만명 이상의 정보주체에 관하여 개인정보를 추가하거나,
          고유식별정보 또는 민감정보를 처리하는 경우에는 2년이상 보관, 관리하고
          있습니다. 또한, 접속기록이 위변조 및 도난, 분실되지 않도록 보안기능을
          사용하고 있습니다.
        </p>
        <h3>라. 개인정보에 대한 접근 제한</h3>
        <p>
          개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의
          부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한
          조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을
          통제하고 있습니다.
        </p>

        <h2>
          제10조 [개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항]
        </h2>
        <h3>가. 쿠키의 사용</h3>
        <p>
          <span className="circle-number">①</span> 회사는 이용자에게 개별적인
          맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는
          ‘쿠키(cookie)’를 사용합니다.
        </p>
        <p>
          <span className="circle-number">②</span> 쿠키는 웹사이트를 운영하는데
          이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의
          정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.
        </p>
        <p className="indent">
          1) 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한
          방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게
          최적화된 정보 제공을 위해 사용됩니다.
        </p>
        <p className="indent">
          2) 쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구 {'>'} 인터넷 옵션{' '}
          {'>'} 개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수
          있습니다.
        </p>
        <p className="indent">
          3) 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수
          있습니다.
        </p>

        <h3>나. Google Analytics를 통한 웹로그 분석</h3>
        <p>
          <span className="circle-number">①</span> 회사는 이용자에게 개별적인
          맞춤서비스를 제공하기 위해 Google Analytics를 통해 이용자의 서비스
          이용에 대한 분석을 실행합니다.
        </p>
        <p>
          <span className="circle-number">②</span> 웹로그는 이용자가 어떻게 웹
          서비스를 이용하고 있는지 분석하기 위해 수집하는 정보입니다.
        </p>
        <p className="indent">
          1) 웹로그 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한
          방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게
          최적화된 정보 제공을 위해 사용됩니다.
        </p>
        <p className="indent">
          2) 웹로그의 설치•운영 및 거부 :
          https://tools.google.com/dlpage/gaoptout/
        </p>
        <p className="indent">
          3) 웹로그 제공을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수
          있습니다.
        </p>

        <h2>제11조 [개인정보 보호책임자]</h2>
        <p>
          <span className="circle-number">①</span> 회사는 개인정보 처리에 관한
          업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리
          및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고
          있습니다.
        </p>
        <p>
          <span className="circle-number">②</span> 정보주체께서는 회사의
          서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의,
          불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자에게 문의하실
          수 있습니다. 회사는 정보주체의 문의에 대해 지체 없이 답변 및
          처리해드릴 것입니다.
        </p>
        <Table
          className="table-column-header"
          dataSource={no9table[DATA_IDX]}
          columns={no9table[COLUMN_IDX]}
          pagination={false}
          showHeader={false}
          scroll={{ x: true }}
        ></Table>

        <h2>제12조 [개인정보 열람청구]</h2>
        <p>
          정보주체는 ⌜개인정보 보호법⌟ 제35조에 따른 개인정보의 열람 청구를
          아래의 부서에 할 수 있습니다. 회사는 정보주체의 개인정보 열람청구가
          신속하게 처리되도록 노력하겠습니다.
        </p>
        <h3>개인정보 열람청구 접수•처리 부서</h3>
        <Table
          className="table-column-header"
          dataSource={no10table[DATA_IDX]}
          columns={no10table[COLUMN_IDX]}
          pagination={false}
          showHeader={false}
          scroll={{ x: true }}
        ></Table>

        <h2>제13조 [권익침해 구제방법]</h2>
        <p>
          정보주체는 개인정보침해로 인한 구제를 받기 위하여
          개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에
          분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의
          신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
        </p>

        <p className="indent">
          1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)
        </p>
        <p className="indent">
          2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
        </p>
        <p className="indent">3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)</p>
        <p className="indent">4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)</p>

        <p>
          「개인정보보호법」 제35조(개인정보의 열람), 제36조(개인정보의
          정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대
          하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의
          침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수
          있습니다.
        </p>

        <p>
          ※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr)
          홈페이지를 참고하시기 바랍니다.
        </p>

        <h2>제14조 [개인정보 처리방침 변경]</h2>
        <p>
          본 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경
          내용의 추가, 삭제, 정정이 있는 경우 변경사항의 시행일 7일 전부터,
          이용자에게 불리하거나 중요한 내용의 경우 시행일 30일 전부터 고지하도록
          하겠습니다.
        </p>
        <br />
        <p>
          ● 이 개인정보 처리방침은 <strong>2023. 4. 20</strong>부터 적용됩니다.
        </p>

        <br />
      </div>
    </>
  );
};

export default Privacy202304;
