import React from "react";
import { Layout, Collapse } from "antd";
import { Link } from "react-router-dom";

import MediaQuery from "react-responsive";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

import "./Footer-mobile.css";

const { Panel } = Collapse;
const { Footer } = Layout;

const FooterMobile = () => {
  return (
    <>
      <Footer className="footer-mobile">
        <div className="footer-collapse-container">
          <Collapse
            accordion
            bordered={false}
            // defaultActiveKey={`footer-reservation`}
            expandIcon={({ isActive }) =>
              isActive ? (
                <FontAwesomeIcon icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} />
              )
            }
            expandIconPosition="end"
          >
            <Panel header="예약하기" key={`footer-reservation`}>
              <div className="collapse-content">
                <a href="/" target="_blank" rel="noreferrer">
                  <div>방문 예약하기</div>
                </a>
                <a href="/" target="_blank" rel="noreferrer">
                  <div>수리 예약하기</div>
                </a>
              </div>
            </Panel>

            <Panel header="고객센터" key={`footer-customerCenter`}>
              <div className="collapse-content">
                <Link to="/contact-us">Contact Us</Link>
              </div>
            </Panel>

            <Panel header="소셜" key={`footer-sns`}>
              <div className="collapse-content">
                <a href="/" target="_blank" rel="noreferrer">
                  <div>Instagram</div>
                </a>
                {/* <a
                  href="https://www.facebook.com/profile.php?id=100092845082760"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div>Facebook</div>
                </a> */}
                <a href="/" target="_blank" rel="noreferrer">
                  <div>YouTube</div>
                </a>
                <a href="/" target="_blank" rel="noreferrer">
                  <div>Twitter</div>
                </a>
              </div>
            </Panel>
          </Collapse>

          <div className="company-info">
            <div className="addr">
              주식회사 블루베이스 압구정 로데오점 | 대표자: 이재윤
              <br />
              사업장 소재지: 서울시 강남구 선릉로153길 26, 1, 2층 (신사동,
              성준빌딩)
              <br />
              <MediaQuery minWidth={390}>
                대표번호: 02-545-9701 | 사업자 등록번호: 894-85-02185 |
                <Link to="/terms/privacy"> 개인정보처리방침</Link>
              </MediaQuery>
              <MediaQuery maxWidth={389.98}>
                대표번호: 02-545-9701 | 사업자 등록번호: 894-85-02185
                <br />
                <Link to="/terms/privacy">개인정보처리방침</Link>
              </MediaQuery>
            </div>

            <div className="copyright">
              <div>© 블루먼트</div>
              <div>대한민국</div>
            </div>
          </div>
        </div>
      </Footer>
    </>
  );
};

export default FooterMobile;
