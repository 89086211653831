import React from "react";
import { Layout, Row, Col } from "antd";
import MediaQuery from "react-responsive";

import "../../assets/styles/Process.css";

const { Content } = Layout;

const BuyProcess = () => {
  return (
    <>
      <Layout>
        <Content>
          <div className="section-container">
            <div className="title">
              <div className="section-title">
                <MediaQuery maxWidth={649.99}>
                  {/* BLUEMENT에서
                  <br /> */}
                  스페셜리스트와 함께하는
                  {/* <br /> */}
                  1:1 쇼핑
                </MediaQuery>
                <MediaQuery minWidth={650}>
                  스페셜리스트와 함께하는 1:1 쇼핑
                </MediaQuery>
              </div>
              <div className="section-sub-title">
                <MediaQuery maxWidth={649.99}>
                  BLUEMENT 부티크에서 전문가에게
                  <br />내 시계를 진단받고, 안전하게 구매하세요.
                </MediaQuery>
                <MediaQuery minWidth={650}>
                  개인 간 거래 또는 가품에 대한 불안감이 있으신가요?
                  <br />
                  블루먼트 인증 시계 프로그램을 통과한 제품을 만나보세요!
                </MediaQuery>
              </div>
            </div>

            <Row
              justify="center"
              className="process-container"
              gutter={[
                { xs: 16, sm: 16, md: 30, lg: 50, xl: 50, xxl: 70 },
                { xs: 70, sm: 70, md: 70, lg: 70, xl: 70, xxl: 70 },
              ]}
            >
              <Col span={6} xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                <div className="process-box">
                  <div className="process-icon">
                    <picture>
                      <source
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/building.webp"
                        type="image/webp"
                        loading="lazy"
                      />
                      <source
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/building.avif"
                        type="image/avif"
                        loading="lazy"
                      />
                      <img
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/building.png"
                        alt="매장 방문"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                  {/* <div className="process-content content-with-link"> */}
                  <div className="process-content">
                    <div className="process-content__text">
                      <div className="process-no">1단계</div>
                      <div className="process-title">매장 방문</div>
                      <div className="process-desc">
                        블루먼트 부티크에 방문해주세요. 네이버 예약을 활용하시면
                        더욱 편리합니다.
                      </div>
                    </div>
                    {/* <div className="process-content__link">
                      <a
                        href="https://booking.naver.com/booking/6/bizes/846736"
                        target="_blank"
                        rel="noreferrer"
                      >
                        네이버 예약 바로가기 →
                      </a>
                    </div> */}
                  </div>
                </div>
              </Col>

              <Col span={6} xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                <div className="process-box">
                  <div className="process-icon">
                    <picture>
                      <source
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/communication.webp"
                        type="image/webp"
                        loading="lazy"
                      />
                      <source
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/communication.avif"
                        type="image/avif"
                        loading="lazy"
                      />
                      <img
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/communication.png"
                        alt="제품 상담"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                  <div className="process-content">
                    <div className="process-content__text">
                      <div className="process-no">2단계</div>
                      <div className="process-title">제품 상담</div>
                      <div className="process-desc">
                        시계 전문 큐레이터와 1:1 상담을 진행합니다.
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col span={6} xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                <div className="process-box">
                  <div className="process-icon">
                    <picture>
                      <source
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/inspection.webp"
                        type="image/webp"
                        loading="lazy"
                      />
                      <source
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/inspection.avif"
                        type="image/avif"
                        loading="lazy"
                      />
                      <img
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/inspection.png"
                        alt="시계 컨디션 확인"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                  <div className="process-content">
                    <div className="process-content__text">
                      <div className="process-no">3단계</div>
                      <div className="process-title">시계 컨디션 확인</div>
                      <div className="process-desc">
                        실제 착용을 위한 조정을 진행합니다.
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col span={6} xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                <div className="process-box">
                  <div className="process-icon">
                    <picture>
                      <source
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/wristwatch.webp"
                        type="image/webp"
                        loading="lazy"
                      />
                      <source
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/wristwatch.avif"
                        type="image/avif"
                        loading="lazy"
                      />
                      <img
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/wristwatch.png"
                        alt="거래 완료"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                  {/* <div className="process-content content-with-link"> */}
                  <div className="process-content">
                    <div className="process-content__text">
                      <div className="process-no">4단계</div>
                      <div className="process-title">거래 완료</div>
                      <div className="process-desc">
                        원하시는 방식으로 결제를 진행한 후, 시계를 수령하세요.
                      </div>
                    </div>
                    {/* <div className="process-content__link">
                      <a
                        href="https://booking.naver.com/booking/6/bizes/846736"
                        target="_blank"
                        rel="noreferrer"
                      >
                        애프터 서비스 알아보기 →
                      </a>
                    </div> */}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Content>
      </Layout>
    </>
  );
};

export default BuyProcess;
