import React from 'react';
import { Layout } from 'antd';
import MediaQuery from 'react-responsive';

import '../components/CPO/CPOIntro.css';

import CPOBanner from '../components/CPO/CPOBanner/CPOBanner';
import CertSection from '../components/CPO/CertSection/CertSection';
import CPOEngineer from '../components/CPO/CPOEngineer/CPOEngineer';
import LastSection from '../components/CPO/LastSection/LastSection';

import { AnalyticsContext } from '../Analytics';
import MetaTag from '../MetaTag';

const { Content } = Layout;

const CPOIntro = () => {
  const { analytics } = React.useContext(AnalyticsContext); // Google Analytics

  React.useEffect(() => {
    // Google Analytics
    analytics.send({
      hitType: 'pageview',
      page: '/apo/bluement-apo',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MetaTag
        title="블루먼트 | 블루먼트 인증시계"
        url="https://www.bluement.kr/apo/bluement-apo"
        keywords="rolex,롤렉스,중고롤렉스,롤렉스매입,롤렉스맞춤형매입,명품시계맞춤형매입,중고롤렉스매입,중고시계,중고시계매입,명품시계매입,블루먼트,bluement,cpo,certified pre-owned watch,pre-owned,preowned,preowned rolex,명품,명품시계,시계,거래,타임피스,거래 플랫폼"
      />

      <Layout className="cpo-intro-layout">
        <Content className="cpo-intro-container">
          <div className="two-line-title">
            <div className="first-line">APPROVED PRE-OWNED</div>
            <div className="second-line">WATCH BY BLUEMENT</div>
          </div>

          <section className="cpo-intro-section cpo-banner">
            <CPOBanner />
          </section>

          <section className="cpo-intro-section cpo-cert">
            <CertSection />
          </section>

          <MediaQuery minWidth={1024}>
            <section className="cpo-intro-section-no-rm">
              <CPOEngineer />
            </section>
          </MediaQuery>
          <MediaQuery maxWidth={1023.98}>
            <div className="cpo-engineering">
              <section className="cpo-intro-section">
                <div className="title-outside cpo-title">
                  <MediaQuery minWidth={560}>
                    엄격한 진단과 점검 그리고 인증 시계
                  </MediaQuery>
                  <MediaQuery maxWidth={559.98}>
                    엄격한 진단과 점검
                    <br />
                    그리고 인증 시계
                  </MediaQuery>
                </div>
              </section>
            </div>
            <CPOEngineer />
          </MediaQuery>

          <section className="cpo-intro-last-section">
            <LastSection />
          </section>
        </Content>
      </Layout>
    </>
  );
};

export default CPOIntro;
