import React from 'react';

import movement from '../../../assets/svg/movement.svg';
import movement2 from '../../../assets/svg/movement2.svg';
import polishing from '../../../assets/svg/polishing.svg';
import waterProof from '../../../assets/svg/waterproof.svg';

const ServiceDetails = () => {
  return (
    <div className="service-details-container">
      <div className="details">
        <div className="details-row">
          <div className="flex-row">
            <div className="detail-card">
              <div className="detail-card-title">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.5"
                  height="19.5"
                  viewBox="0 0 19.5 19.5"
                >
                  <path
                    id="check.svg"
                    d="M9,12.75,11.25,15,15,9.75M21,12a9,9,0,1,1-9-9A9,9,0,0,1,21,12Z"
                    transform="translate(-2.25 -2.25)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                  />
                </svg>
                무브먼트 정확도
              </div>
              <div className="detail-card-desc">
                <strong>타이밍 오차(일 평균) 측정:</strong> 기준 위치에 따른
                오차 범위
              </div>
            </div>

            <div className="detail-card">
              <div className="detail-card-title">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.5"
                  height="19.5"
                  viewBox="0 0 19.5 19.5"
                >
                  <path
                    id="check.svg"
                    d="M9,12.75,11.25,15,15,9.75M21,12a9,9,0,1,1-9-9A9,9,0,0,1,21,12Z"
                    transform="translate(-2.25 -2.25)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                  />
                </svg>
                무브먼트 기능
              </div>
              <div className="detail-card-desc">
                <strong>크라운과 핸즈 작동:</strong> 날짜와 요일 변경, 수동
                와인딩
                <br />
                <strong>크로노그래프 기능:</strong> Start, Stop, Reset
              </div>
            </div>
          </div>
        </div>

        <div className="details-row">
          <div className="flex-row">
            <div className="detail-card">
              <div className="detail-card-title">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.5"
                  height="19.5"
                  viewBox="0 0 19.5 19.5"
                >
                  <path
                    id="check.svg"
                    d="M9,12.75,11.25,15,15,9.75M21,12a9,9,0,1,1-9-9A9,9,0,0,1,21,12Z"
                    transform="translate(-2.25 -2.25)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                  />
                </svg>
                시계 외관
              </div>
              <div className="detail-card-desc">
                <strong>찍힘 및 스크래치 확인</strong>
              </div>
            </div>

            <div className="detail-card">
              <div className="detail-card-title">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.5"
                  height="19.5"
                  viewBox="0 0 19.5 19.5"
                >
                  <path
                    id="check.svg"
                    d="M9,12.75,11.25,15,15,9.75M21,12a9,9,0,1,1-9-9A9,9,0,0,1,21,12Z"
                    transform="translate(-2.25 -2.25)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                  />
                </svg>
                방수 기능
              </div>
              <div className="detail-card-desc">
                <strong>공기압, 진공 실험</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetails;
