import React from "react";
import MediaQuery from "react-responsive";

import "./ProductCard.css";

const BASE_URL_BG =
  "https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/collection/thumbnail/500x/";

const ProductCard = (props) => {
  return (
    <div className="product-card" id={`sr-${props.pid}`}>
      <picture>
        <source
          srcSet={`${BASE_URL_BG}SR-${props.pid}_gray.webp`}
          type="image/webp"
          loading="lazy"
        />
        <source
          srcSet={`${BASE_URL_BG}SR-${props.pid}_gray.avif`}
          type="image/avif"
          loading="lazy"
        />
        <img
          loading="lazy"
          alt={props.model}
          srcSet={`${BASE_URL_BG}SR-${props.pid}_gray.jpg`}
        />
      </picture>

      <div className="product-card-deatils">
        <p className="product-card-deatils__top">
          <MediaQuery minWidth={768}>
            {props.bracelet}
            <span>|</span>
          </MediaQuery>
          {props.dial}
        </p>
        <h2>{props.model}</h2>
        <div className="product-card-deatils__bottom">
          <p className="refNo">{props.refNo}</p>
          <p className="refNo year">{`${props.certificateDate}년`}</p>
        </div>
        <div className="product-card-deatils__bottom price">
          <p className="price">
            {props.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
