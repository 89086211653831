import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
// import { FaBars, FaTimes } from 'react-icons/fa';
import { IconContext } from "react-icons/lib";
import { NavLink } from "react-router-dom";
import { Drawer } from "antd";
import ReactGA from "react-ga4";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebookSquare,
  faYoutube,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

import { ReactComponent as MenuOpenBtn } from "../../assets/svg/menu-btn.svg";
import { ReactComponent as MenuCloseBtn } from "../../assets/svg/close-btn.svg";
import { ReactComponent as CollapseUp } from "../../assets/svg/collapse-up-btn.svg";
import { ReactComponent as CollapseDown } from "../../assets/svg/collapse-down-btn.svg";

import APOBlack from "../../assets/svg/apo_black.svg";
import APOWhite from "../../assets/svg/apo_white.svg";
import APORotate from "../../assets/svg/apo360.svg";

import useWindowSize from "../../hooks/useWindowSize";

import $ from "jquery";

const NAV_ITEMS = [
  ["BUY", ["Collection", "Buy Watch", "Bespoke Order"]],
  ["SELL & TRADE", ["Sell Watch", "Trade Watch"]],
  ["SERVICE", ["종합 관리 서비스", "진단 및 검증 서비스"]],
  ["A.P.O.", ["블루먼트 인증시계", "가격보장 프로그램"]],
  // ['CONTACT US'],
];

function Navbar() {
  const [click, setClick] = useState(false);
  const [isDropdown, setDropdown] = useState(false);
  const [dropdownName, setDropdownName] = useState("");
  const [isMobileMode, setMobileMode] = useState(false);
  const [isMobileSubmenuOpen, setMobileSubmenuOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const isMobileSize = useRef(false);
  const navItems = useRef();
  const browserSize = useWindowSize();

  const handleNavHover = ({ target }) => {
    if (!navItems.current.contains(target) && !isMobileSize.current) {
      handleHoverOut();
    }
  };

  const checkBrowserSize = () => {
    if (browserSize.width <= 775.98) {
      setMobileMode(true);
      isMobileSize.current = true;
      //   setDrawerOpen(true);
      $(".menu-group").addClass("hidden");
    }
    if (browserSize.width > 776) {
      isMobileSize.current = false;
      setMobileMode(false);
      setDrawerOpen(false);
      // setDropdown(true);
      handleHoverOut();
      $(".menu-group").removeClass("hidden");
    }
  };

  useEffect(() => {
    checkBrowserSize();
    document.addEventListener("mouseover", handleNavHover);
    return () => document.removeEventListener("mouseover", handleNavHover);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkBrowserSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [browserSize]);

  const onClose = () => {
    setDrawerOpen(false);
  };

  const handleSNSClick = (e) => {
    // console.log(e);

    // Send a custom event
    ReactGA.event({
      category: "Navbar",
      action: "click",
      label: `${e}-click`, // optional
      value: 2, // optional, must be a number
      nonInteraction: false, // optional, true/false
    });
  };

  const handleHover = (name) => {
    if (!isMobileMode) {
      setDropdown(true);
    }
    setDropdownName(name);
  };

  const handleHoverOut = () => {
    if (!isMobileMode) {
      setDropdown(false);
      setDropdownName("");
    }
  };

  const handleClick = () => {
    setClick(!click);
    setDrawerOpen(true);
  };

  const closeMobileMenu = () => {
    setClick(false);
    setDrawerOpen(false);
    setMobileSubmenuOpen(false);
  };

  const closeMobileSubMenu = () => {
    setClick(false);
    setDrawerOpen(false);
  };

  const handleTab = (name) => {
    setDropdown(true);
    setDropdownName(name);
  };

  const handleMobileSubmenu = (name) => {
    setDropdownName(name);
    setMobileSubmenuOpen(!isMobileSubmenuOpen);
  };

  const handleTabEnd = (name) => {
    setClick(false);
    setDropdown(false);
    setDropdownName("");
  };

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <nav className="navbar-container navbar-padding">
          <div className="navbar-logo-left">
            <Link to="/">
              <img
                srcSet={require("../../assets/bi/logo_bk.png")}
                alt="logo"
                loading="lazy"
              />
            </Link>
          </div>

          <div className="navbar-menu-container ">
            {isMobileMode ? (
              <div className="menu-icon" onClick={handleClick}>
                <MenuOpenBtn className="open-btn" />
              </div>
            ) : null}

            <div className={isMobileMode ? "menu-group hidden" : "menu-group"}>
              <ul
                ref={navItems}
                className={click ? "nav-menu active" : "nav-menu"}
              >
                {NAV_ITEMS.map(([navName, navDropdown = []], index) => (
                  <li
                    key={index}
                    className="nav-item"
                    onMouseEnter={() =>
                      // navDropdown.length && !isMobileSize.current
                      navDropdown.length && !isMobileMode
                        ? handleHover(navName)
                        : handleHoverOut()
                    }
                    onTouchStart={() =>
                      navDropdown.length ? handleTab(navName) : handleTabEnd()
                    }
                  >
                    <NavLink
                      to={
                        navName === "CONTACT US"
                          ? "/contact-us"
                          : // : navName === '인증중고'
                            // ? '/approved-secondhand'
                            "/" + navName.toLowerCase().replace(/[\s.]+/g, "")
                      }
                      // to={'/' + navName.toLowerCase().replace(/[\s.]+/g, '')}
                      className={({ isActive }) =>
                        "nav-links" + (isActive ? " activated" : "")
                      }
                      onClick={closeMobileMenu}
                    >
                      {navName}
                      {navDropdown.length &&
                      dropdownName === navName &&
                      isDropdown ? (
                        <ul className="dropdown">
                          {navDropdown.map((nav, index) => (
                            <NavLink
                              // to={
                              //   '/' +
                              //   navName.toLowerCase() +
                              //   '/' +
                              //   nav.toLowerCase().replace(/\s+/g, '-')
                              // }
                              to={
                                nav === "블루먼트 인증시계"
                                  ? "/apo/bluement-apo"
                                  : nav === "가격보장 프로그램"
                                  ? "/apo/payback-program"
                                  : nav === "종합 관리 서비스"
                                  ? "/" +
                                    navName.toLowerCase().replace(/\./g, "") +
                                    "/complete-care"
                                  : nav === "진단 및 검증 서비스"
                                  ? "/" +
                                    navName.toLowerCase().replace(/\./g, "") +
                                    "/inspection"
                                  : "/" +
                                    navName
                                      .toLowerCase()
                                      .replace(/[\s.]+/g, "") +
                                    "/" +
                                    nav.toLowerCase().replace(/\s+/g, "-")
                              }
                              // className={({ isActive }) =>
                              //   'nav-links' + (isActive ? ' activated' : '')
                              // }
                              className="nav-links"
                              onClick={closeMobileMenu}
                              key={`submenu-${index}`}
                            >
                              <li key={index} onClick={handleTabEnd}>
                                {nav}
                              </li>
                            </NavLink>
                          ))}
                        </ul>
                      ) : null}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {isDropdown && <div className="dropdown-bg" />}

          <Drawer
            placement="right"
            onClose={onClose}
            open={drawerOpen}
            className="mobile-drawer"
            footer={
              <div className="sns-container">
                <a
                  href="https://www.instagram.com/bluement_watch"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => handleSNSClick("instagram")}
                  aria-label="instagram"
                >
                  <div className="sns-icon">
                    <FontAwesomeIcon icon={faInstagram} />
                  </div>
                </a>
                {/* <span>|</span> */}
                {/* <a
                  href="https://www.facebook.com/profile.php?id=100092845082760"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => handleSNSClick('facebook')}
                  aria-label="facebook"
                >
                  <div className="sns-icon">
                    <FontAwesomeIcon icon={faFacebookSquare} />
                  </div>
                </a> */}
                {/* <span>|</span> */}
                <a
                  href="https://www.youtube.com/channel/UCmUAaeGqfcWJwfhHmAhXfTA"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => handleSNSClick("youtube")}
                  aria-label="youtube"
                >
                  <div className="sns-icon">
                    <FontAwesomeIcon icon={faYoutube} />
                  </div>
                </a>
                {/* <span>|</span> */}
                <a
                  href="https://twitter.com/bluement_watch"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => handleSNSClick("twitter")}
                  aria-label="twitter"
                >
                  <div className="sns-icon">
                    <FontAwesomeIcon icon={faTwitter} />
                  </div>
                </a>
              </div>
            }
            closeIcon={<MenuCloseBtn />}
          >
            <ul
              ref={navItems}
              className={click ? "nav-mobile-menu" : "nav-mobile-menu"}
            >
              {NAV_ITEMS.map(([navName, navDropdown = []], index) => (
                <li
                  key={index}
                  className="nav-mobile-item"
                  // onTouchStart={() =>
                  //   navDropdown.length ? handleTab(navName) : handleTabEnd()
                  // }
                >
                  {navDropdown.length ? (
                    // 하위메뉴 있을 때
                    <>
                      <div
                        className="nav-mobile-item__row"
                        onClick={() => handleMobileSubmenu(navName)}
                      >
                        <div className="left">
                          <div className={"nav-mobile-links"}>{navName}</div>
                        </div>
                        <div className="right">
                          {navDropdown.length ? (
                            isMobileSubmenuOpen && dropdownName === navName ? (
                              <CollapseUp />
                            ) : (
                              <CollapseDown />
                            )
                          ) : null}
                        </div>
                      </div>
                      {/* mobile submenu */}

                      <ul
                        className={
                          isMobileSubmenuOpen && dropdownName === navName
                            ? "nav-mobile-menu submenu-active"
                            : "submenu-not-active"
                        }
                      >
                        {navDropdown.map((subitem, index) => (
                          <NavLink
                            // to={
                            //   '/' +
                            //   navName.toLowerCase() +
                            //   '/' +
                            //   subitem.toLowerCase().replace(/\s+/g, '-')
                            // }
                            to={
                              subitem === "블루먼트 인증시계"
                                ? "/apo/bluement-apo"
                                : subitem === "가격보장 프로그램"
                                ? "/apo/payback-program"
                                : subitem === "종합 관리 서비스"
                                ? "/" +
                                  navName.toLowerCase().replace(/\./g, "") +
                                  "/complete-care"
                                : subitem === "진단 및 검증 서비스"
                                ? "/" +
                                  navName.toLowerCase().replace(/\./g, "") +
                                  "/inspection"
                                : "/" +
                                  navName.toLowerCase().replace(/[\s.]+/g, "") +
                                  "/" +
                                  subitem.toLowerCase().replace(/\s+/g, "-")
                            }
                            key={`submenu-${index}`}
                            // className={'nav-mobile-links'}
                            onClick={closeMobileSubMenu}
                          >
                            <li
                              className={
                                navDropdown.length - 1 === index
                                  ? "nav-mobile-subitem mb-10"
                                  : "nav-mobile-subitem"
                              }
                            >
                              {subitem}
                            </li>
                          </NavLink>
                        ))}
                      </ul>
                    </>
                  ) : (
                    // 하위메뉴 없을 때
                    <div className="nav-mobile-item-alone__row">
                      <div className="left">
                        <NavLink
                          // to={
                          //   '/' + navName.toLowerCase().replace(/[\s.]+/g, '')
                          // }
                          to={
                            navName === "CONTACT US"
                              ? "/contact-us"
                              : // : navName === '인증중고'
                                // ? '/approved-secondhand'
                                "/" +
                                navName.toLowerCase().replace(/[\s.]+/g, "")
                          }
                          className={"nav-mobile-links"}
                          onClick={closeMobileMenu}
                        >
                          {navName}
                        </NavLink>
                      </div>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </Drawer>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
