import React, { useState, useEffect, useRef } from "react";
import {
  Layout,
  Row,
  Col,
  Form,
  Checkbox,
  Collapse,
  Empty,
  Button,
  Input,
  Divider,
  Spin,
} from "antd";
import {
  SearchOutlined,
  // MenuFoldOutlined,
  // MenuUnfoldOutlined,
} from "@ant-design/icons";
import $ from "jquery";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";

// React Slick
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import useWindowSize from "../hooks/useWindowSize";
import ProductCard from "../components/Collection/ProductCard/ProductCard";

import { AnalyticsContext } from "../Analytics";
import MetaTag from "../MetaTag";

import { API } from "aws-amplify";
// import awsExports from '../aws-exports';

import "../assets/styles/Collection.css";
// import '../assets/styles/FormStyle.css';

import { Link, useParams } from "react-router-dom";

import "../components/Collection/ProductCard/ProductCard.css";
import MediaQuery from "react-responsive";
const { Content, Sider } = Layout;
const { Panel } = Collapse;

const FILTER_ITEM_LIST = [
  // {
  //   label: 'Reference No.',
  //   values: [
  //     '116503',
  //   ],
  //   name: 'RefNo',
  // },
  {
    label: "Brand",
    values: ["Rolex", "Patek Philippe"],
    name: "brand",
  },
  {
    label: "Model",
    values: [
      "Daytona",
      "Submariner",
      "Submariner Date",
      "Datejust 31",
      "Datejust 36",
      "Datejust 41",
      "Lady Datejust",
      "Day-Date 36",
      "Day-Date 40",
      "Sky Dweller",
      "GMT-Master II",
      "Oyster Perpetual",
      "Explorer II",
    ],
    name: "model",
  },
  {
    label: "Bracelet",
    values: ["Oyster", "Oysterflex", "Jubilee"],
    name: "bracelet",
  },
  {
    label: "Dial",
    values: [
      "White",
      "Black",
      "Bright Black",
      "Bright Blue",
      "Royal Blue",
      "Green",
      "Slate",
      "Slate Roman",
      "White Roman",
      "Chocolate Roman",
      "Silver IX Diamonds",
      "Champ IX Diamonds",
      "Rose IX Diamond",
      "Silver 10BR",
      "Champ 10BR",
      "Aubergine 10BR",
      "Chocolate 10BR",
      "Olive Green 10BR",
      "Champ Colour 10BR",
      "White Mother-of-Pearl 10BR",
    ],
    name: "dial",
  },
];

const COLLECTION_FILTER_MODEL = [
  {
    id: "000",
    model: "전체보기",
    imgUrl: "all",
    keyword: "",
    classkeyword: "all",
  },
  {
    id: "001",
    model: "Daytona",
    imgUrl: "daytona",
    keyword: "daytona",
    classkeyword: "daytona",
  },
  {
    id: "002",
    model: "Lady Datejust",
    imgUrl: "lady-datejust",
    keyword: "lady datejust",
    classkeyword: "lady-datejust",
  },
  {
    id: "003",
    model: "Submariner",
    imgUrl: "submariner-date",
    keyword: "submariner",
    classkeyword: "submariner",
  },
  {
    id: "004",
    model: "Datejust",
    imgUrl: "datejust",
    keyword: "datejust",
    classkeyword: "datejust",
  },
  {
    id: "005",
    model: "Day-Date",
    imgUrl: "day-date",
    keyword: "day-date",
    classkeyword: "day-date",
  },
  {
    id: "006",
    model: "GMT-Master II",
    imgUrl: "gmt-master-ii",
    keyword: "gmt-master ii",
    classkeyword: "gmt-master-ii",
  },
  {
    id: "007",
    model: "Sky Dweller",
    imgUrl: "sky-dweller",
    keyword: "sky dweller",
    classkeyword: "sky-dweller",
  },
  {
    id: "008",
    model: "Oyster Perpetual",
    imgUrl: "oyster-perpetual",
    keyword: "oyster perpetual",
    classkeyword: "oyster-perpetual",
  },
  {
    id: "009",
    model: "Explorer II",
    imgUrl: "explorer-ii",
    keyword: "explorer ii",
    classkeyword: "explorer-ii",
  },
  {
    id: "010",
    model: "Patek Philippe",
    imgUrl: "patek-philippe",
    keyword: "patek philippe",
    classkeyword: "patek-philippe",
  },
];

const FILTER_CATEGORY = ["refNo", "model", "braceletType", "dial", "brand"];

const FILTER_BASE_URL =
  "https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/collection/filter/";

const Collection = () => {
  const params = useParams();
  // let modelName = params.model === undefined ? 'all' : params.model;
  let modelName = params.model;
  // console.log('params', modelName);

  const [form] = Form.useForm();
  const allFilters = useRef({
    refNo: [],
    model: [],
    bracelet: [],
    dial: [],
    brand: [],
  });
  const globalSearch = useRef("");
  const slider = useRef(null);
  // const [collectionItems, setCollectionItems] = useState(COLLECTION_ITEMS);
  const [collectionItems, setCollectionItems] = useState(null);
  const { analytics } = React.useContext(AnalyticsContext);
  const [collapsed, setCollapsed] = useState(false);
  const [hideComponent, setHideComponent] = useState(false);
  const [hidePrev, setHidePrev] = useState(true);
  const [selectedModelFilter, setSelectedModelFilter] = useState("");
  const [selsectedFilterId, setSelectedFilterId] = useState(modelName);

  const browserSize = useWindowSize();
  const allCollectionItems = useRef();

  useEffect(() => {
    analytics.send({ hitType: "pageview", page: "/buy/collection" });

    API.get("bluementapi", "/product").then((res) => {
      // const idx = res.findIndex((item) => item.pid === '023');
      // res.splice(idx, 1);

      allCollectionItems.current = res;
      setCollectionItems(res);

      if (modelName === undefined) return;

      let keywordItem = modelName;
      let keywordClass = modelName;

      if (modelName === "gmt-master-ii") {
        keywordItem = "gmt-master ii";
      } else if (modelName === "sky-dweller") {
        keywordItem = "sky dweller";
      } else if (modelName === "oyster-perpetual") {
        keywordItem = "oyster perpetual";
      } else if (modelName === "lady-datejust") {
        keywordItem = "lady datejust";
      } else if (modelName === "submariner-date") {
        keywordItem = "submariner date";
      } else if (
        modelName === "daytona" ||
        modelName === "datejust" ||
        modelName === "submariner"
      ) {
        // console.log(modelName);
      } else if (modelName === "explorer-ii") {
        keywordItem = "explorer ii";
      } else if (modelName === "day-date") {
        keywordItem = "day-date";
      } else {
        keywordItem = "";
        keywordClass = "all";
      }

      if (keywordClass === "all") {
        setSelectedModelFilter("");
        setSelectedFilterId("all");
      } else {
        setSelectedModelFilter(keywordItem);
        setSelectedFilterId(keywordClass);
        setTimeout(() => {
          $(".img-wrapper-all").removeClass("img-wrapper-selected");
          $(`.img-wrapper-${keywordClass}`).addClass("img-wrapper-selected");
        }, 10);
      }

      onSelectModelFilter(keywordItem, keywordClass);
    });
    // Collection 모델 불러오기
    // API.get('bluementapi', '/collection').then((res) => {
    //   // console.log(res);
    //   allCollectionItems.current = res;
    //   setCollectionItems(res);

    //   if (modelName === undefined) return;

    //   let keywordItem = modelName;
    //   let keywordClass = modelName;

    //   if (modelName === 'gmt-master-ii') {
    //     keywordItem = 'gmt-master ii';
    //   } else if (modelName === 'sky-dweller') {
    //     keywordItem = 'sky dweller';
    //   } else if (modelName === 'oyster-perpetual') {
    //     keywordItem = 'oyster perpetual';
    //   } else if (modelName === 'lady-datejust') {
    //     keywordItem = 'lady datejust';
    //   } else if (modelName === 'submariner-date') {
    //     keywordItem = 'submariner date';
    //   } else if (
    //     modelName === 'daytona' ||
    //     modelName === 'datejust' ||
    //     modelName === 'submariner'
    //   ) {
    //     console.log(modelName);
    //   } else {
    //     keywordItem = '';
    //     keywordClass = 'all';
    //   }

    //   if (keywordClass === 'all') {
    //     setSelectedModelFilter('');
    //     setSelectedFilterId('all');
    //     console.log('all');
    //     console.log(allCollectionItems.current);
    //   } else {
    //     setSelectedModelFilter(keywordItem);
    //     setSelectedFilterId(keywordClass);
    //     console.log('\nkeywordItem', keywordItem);
    //     console.log('keywordClass', keywordClass);
    //     console.log(allCollectionItems.current);
    //     setTimeout(() => {
    //       $('.img-wrapper-all').removeClass('img-wrapper-selected');
    //       $(`.img-wrapper-${keywordClass}`).addClass('img-wrapper-selected');
    //     }, 10);
    //   }

    //   onSelectModelFilter(keywordItem, keywordClass);
    // });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (browserSize.width <= 1090) {
      setCollapsed(true);
      setHideComponent(true);
    }
    if (browserSize.width > 1090) {
      setCollapsed(false);
      setHideComponent(false);
    }
  }, [browserSize]);

  const getSliderIndex = (index) => {
    if (index === 0) {
      setHidePrev(true);
    } else {
      setHidePrev(false);
    }
  };

  const onSelectModelFilter = (keyword, classkeyword) => {
    if (classkeyword !== selsectedFilterId) {
      setSelectedFilterId(classkeyword);
      $("div").removeClass("img-wrapper-selected");
      $(`.img-wrapper-${classkeyword}`).addClass("img-wrapper-selected");
    }

    if (keyword === "") {
      setSelectedModelFilter("");
      globalSearch.current = "";
      filterItems("global");
      return;
    }

    if (keyword === "datejust") {
      allFilters.current.model = ["Datejust 31", "Datejust 36", "Datejust 41"];
      filterItems();
      return;
    } else if (keyword === "day-date") {
      allFilters.current.model = ["Day-Date 40", "Day-Date 36"];
      filterItems();
      return;
    }

    setSelectedModelFilter(keyword);
    globalSearch.current = keyword;
    filterItems("global");
  };

  // const onFilterRefNoChange = (e) => {
  //   // setFilterRefNo(e);
  //   allFilters.current.refNo = e;
  //   filterItems();
  // };
  const onFilterModelChange = (e) => {
    // setFilterModel(e);
    setSelectedFilterId("all");
    $("div").removeClass("img-wrapper-selected");
    $(`.img-wrapper-all`).addClass("img-wrapper-selected");

    allFilters.current.model = e;
    filterItems();
  };
  const onFilterBraceletChange = (e) => {
    // setFilterBracelet(e);
    setSelectedFilterId("all");
    $("div").removeClass("img-wrapper-selected");
    $(`.img-wrapper-all`).addClass("img-wrapper-selected");

    allFilters.current.bracelet = e;
    filterItems();
  };
  const onFilterDialChange = (e) => {
    // setFilterDial(e);
    setSelectedFilterId("all");
    $("div").removeClass("img-wrapper-selected");
    $(`.img-wrapper-all`).addClass("img-wrapper-selected");

    allFilters.current.dial = e;
    filterItems();
  };
  const onFilterBrandChange = (e) => {
    // setFilterDial(e);
    setSelectedFilterId("all");
    $("div").removeClass("img-wrapper-selected");
    $(`.img-wrapper-all`).addClass("img-wrapper-selected");

    allFilters.current.brand = e;
    filterItems();
  };

  const filterItems = (type) => {
    let filteredData = allCollectionItems.current;

    if (type === "global") {
      filteredData = [];
      if (globalSearch.current === "") {
        setCollectionItems(allCollectionItems.current);
        // setCollectionItems(COLLECTION_ITEMS);
        return;
      }

      // let data = [];
      FILTER_CATEGORY.forEach((category) => {
        const filtered = allCollectionItems.current.filter((item) => {
          return item[category]
            .toLowerCase()
            .includes(globalSearch.current.toLowerCase());
        });

        if (filtered.length > 0) {
          filteredData = filteredData.concat(filtered);
        }
      });

      const duplicateRemoved = new Set(filteredData);
      const uniqueData = [...duplicateRemoved];

      setCollectionItems(uniqueData);
      return;
    }

    if (
      allFilters.current.refNo.length === 0 &&
      allFilters.current.model.length === 0 &&
      allFilters.current.bracelet.length === 0 &&
      allFilters.current.dial.length === 0 &&
      allFilters.current.brand.length === 0
    ) {
      // console.log('no filter selected');
    } else {
      const filterKeys = Object.keys(allFilters.current);

      // 선택된 필터가 있을 때
      filterKeys.forEach((key) => {
        // console.log(key);
        if (allFilters.current[key].length > 0) {
          // console.log(allFilters.current[key]);
          allFilters.current[key].forEach((filter) => {
            // console.log(filter);
            filteredData = filteredData.filter((item) =>
              allFilters.current[key].includes(item[key])
            );
          });
        }
      });
    }
    // console.log(filteredData);
    setCollectionItems(filteredData);
  };

  const onSearchChange = (e) => {
    setSelectedFilterId("all");
    $("div").removeClass("img-wrapper-selected");
    $(`.img-wrapper-all`).addClass("img-wrapper-selected");

    globalSearch.current = e.target.value;
    // console.log(e.target.value);
    filterItems("global");
  };

  return (
    <>
      <MetaTag
        title="블루먼트 | Collection"
        url="https://www.bluement.kr/buy/collection"
        keywords="rolex,롤렉스,중고롤렉스,롤렉스매장,압구정롤렉스,압구정명품시계,중고롤렉스매장,중고시계,중고시계매장,명품시계매장,블루먼트,bluement,cpo,certified pre-owned watch,pre-owned,preowned,preowned rolex,명품,명품시계,시계,거래,타임피스,거래 플랫폼"
      />
      <Layout>
        <Content>
          <div className="collection-section">
            <div className="collection-filter-slider collection-section-pd">
              <Slider
                infinite={false}
                slidesToShow={9}
                ref={slider}
                slidesToScroll={1}
                afterChange={getSliderIndex}
                beforeChange={getSliderIndex}
                prevArrow={
                  <>
                    <div
                      className={
                        hidePrev
                          ? "icon-wrapper on-left hidden"
                          : "icon-wrapper on-left"
                      }
                      onClick={() => slider?.current?.slickPrev()}
                    >
                      <FontAwesomeIcon icon={faAngleLeft} />
                    </div>
                  </>
                }
                nextArrow={
                  <>
                    <div
                      className="icon-wrapper on-right"
                      onClick={() => slider?.current?.slickNext()}
                    >
                      <FontAwesomeIcon icon={faAngleRight} />
                    </div>
                  </>
                }
                lazyLoad="ondemand"
                mobileFirst={true}
                responsive={[
                  {
                    breakpoint: 1280,
                    settings: {
                      slidesToShow: 6,
                    },
                  },
                  {
                    breakpoint: 1190,
                    settings: {
                      slidesToShow: 5,
                    },
                  },
                  {
                    breakpoint: 1080,
                    settings: {
                      slidesToShow: 4,
                    },
                  },
                  {
                    breakpoint: 890,
                    settings: {
                      slidesToShow: 4,
                    },
                  },
                  {
                    breakpoint: 800,
                    settings: {
                      slidesToShow: 3,
                    },
                  },
                ]}
              >
                {COLLECTION_FILTER_MODEL.map((item) => (
                  <div
                    className="collection-filter-slider__item"
                    key={item.id}
                    onClick={() =>
                      onSelectModelFilter(item.keyword, item.classkeyword)
                    }
                  >
                    <div
                      className={
                        item.classkeyword === "all"
                          ? `img-wrapper-${item.classkeyword} img-wrapper img-wrapper-selected`
                          : `img-wrapper-${item.classkeyword} img-wrapper`
                      }
                    >
                      <picture>
                        <source
                          srcSet={`${FILTER_BASE_URL}${item.imgUrl}.webp`}
                          type="image/webp"
                          loading="lazy"
                        />
                        <source
                          srcSet={`${FILTER_BASE_URL}${item.imgUrl}.avif`}
                          type="image/avif"
                          loading="lazy"
                        />
                        <img
                          alt={item.model}
                          srcSet={`${FILTER_BASE_URL}${item.imgUrl}.jpg`}
                          loading="lazy"
                        />
                      </picture>
                    </div>
                    <p>{item.model}</p>
                  </div>
                ))}
              </Slider>
            </div>

            <Divider className="collection-divider" />

            <Layout className="collection-section-layout collection-section-pd">
              <Sider
                className={
                  hideComponent
                    ? "collection-filter hidden"
                    : "collection-filter"
                }
                width={250}
                theme="light"
                trigger={null}
                collapsed={collapsed}
                collapsible
              >
                <Form className="collection-filter-form" form={form}>
                  <Form.Item className="collection-filter-search">
                    <Input
                      placeholder="검색"
                      suffix={<SearchOutlined />}
                      className="collection-filter-search__input"
                      onChange={onSearchChange}
                    />
                  </Form.Item>

                  <Collapse
                    // key={item.label}
                    accordion
                    // defaultActiveKey={[0]}
                    ghost
                  >
                    {FILTER_ITEM_LIST.map((item, idx) => (
                      <Panel header={item.label} key={idx}>
                        <Form.Item>
                          {/* {item.name === 'RefNo' ? (
                            <Checkbox.Group
                              name={item.name}
                              options={item.values}
                              onChange={onFilterRefNoChange}
                              className="collection-filter-checkbox"
                            />
                          ) :  */}
                          {item.name === "model" ? (
                            <Checkbox.Group
                              name={item.name}
                              options={item.values}
                              onChange={onFilterModelChange}
                              className="collection-filter-checkbox"
                            />
                          ) : item.name === "bracelet" ? (
                            <Checkbox.Group
                              name={item.name}
                              options={item.values}
                              onChange={onFilterBraceletChange}
                              className="collection-filter-checkbox"
                            />
                          ) : item.name === "dial" ? (
                            <Checkbox.Group
                              name={item.name}
                              options={item.values}
                              onChange={onFilterDialChange}
                              className="collection-filter-checkbox"
                            />
                          ) : item.name === "brand" ? (
                            <Checkbox.Group
                              name={item.name}
                              options={item.values}
                              onChange={onFilterBrandChange}
                              className="collection-filter-checkbox"
                            />
                          ) : null}
                        </Form.Item>
                      </Panel>
                    ))}
                  </Collapse>
                </Form>
              </Sider>

              {collectionItems !== null && collectionItems.length === 0 ? (
                <>
                  <Content>
                    <Row
                      className={
                        hideComponent
                          ? "mobile-search-bar-row"
                          : "mobile-search-bar-row hidden"
                      }
                      justify="center"
                    >
                      <Col span={20}>
                        <Input
                          className="mobile-search-bar collection-filter-search__input"
                          placeholder="Search..."
                          suffix={<SearchOutlined />}
                          onChange={onSearchChange}
                        />
                      </Col>
                    </Row>
                    <Row
                      justify="center"
                      style={{ alignItems: "center", width: "100%" }}
                    >
                      <Col span={24}>
                        <Empty
                          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                          description={
                            <span>
                              해당 모델은 현재 재고가 없습니다. <br />
                              매입을 통한 구매를 원하시면 아래 매입 요청하기
                              버튼을 눌러주세요.
                            </span>
                          }
                        >
                          <Link to="/buy/bespoke-order">
                            <Button
                              size="large"
                              style={{
                                backgroundColor: "#184170",
                                color: "#fff",
                                borderColor: "#184170",
                              }}
                            >
                              매입 요청하기
                            </Button>
                          </Link>
                        </Empty>
                      </Col>
                    </Row>
                  </Content>
                </>
              ) : (
                <>
                  <Content className="grid-content">
                    <Row
                      className={
                        hideComponent
                          ? "mobile-search-bar-row"
                          : "mobile-search-bar-row hidden"
                      }
                      justify="center"
                    >
                      <Col span={20}>
                        <Input
                          className="mobile-search-bar collection-filter-search__input"
                          placeholder="Search..."
                          suffix={<SearchOutlined />}
                          onChange={onSearchChange}
                        />
                      </Col>
                    </Row>

                    {collectionItems !== null ? (
                      <div className="ProductGrid">
                        {collectionItems.map((item) => {
                          return (
                            <Link
                              className="product-card-link"
                              to={`/buy/collection/rolex/${`sr-${item.pid}`}`}
                              key={`SR-${item.pid}`}
                            >
                              <ProductCard
                                model={item.model}
                                refNo={item.refNo}
                                pid={item.pid}
                                dial={item.dial}
                                price={item.price}
                                bracelet={item.braceletType}
                                certificateDate={new Date(
                                  item.certificateDate * 1000
                                ).getFullYear()}
                              />
                            </Link>
                          );
                        })}
                      </div>
                    ) : (
                      <div className="loading-container">
                        <Spin size="large" />
                      </div>
                    )}
                  </Content>
                </>
              )}
            </Layout>
          </div>
          <MediaQuery minWidth={876}>
            <div className="section-gap-150"></div>
          </MediaQuery>
          <MediaQuery minWidth={560} maxWidth={875.98}>
            <div className="section-gap"></div>
          </MediaQuery>
        </Content>
      </Layout>
    </>
  );
};

export default Collection;
