import React from 'react';
// import MediaQuery from 'react-responsive';

const Process = () => {
  return (
    <div className="process-section-container">
      <div className="title">서비스 신청</div>

      <div className="desc">
        예약 시간에 맞춰 매장을 방문해주세요.
        <br />
        전문 큐레이터와 엔지니어가 상담을 도와드립니다.
      </div>

      <div className="process-section-btn">
        <a href="/" target="_blank" rel="noreferrer">
          <button>방문 예약하기</button>
        </a>
      </div>
    </div>
  );
};

export default Process;
