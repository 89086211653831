import React from 'react';

import movement from '../../../assets/svg/movement.svg';
import movement2 from '../../../assets/svg/movement2.svg';
import polishing from '../../../assets/svg/polishing.svg';
import waterProof from '../../../assets/svg/waterproof.svg';

const ServiceDetails = () => {
  return (
    <div className="service-details-container">
      {/* <div className="title">서비스 항목</div> */}

      <div className="details">
        <div className="details-row">
          <div className="flex-row">
            <div className="detail-card">
              <img
                srcSet={movement}
                alt="무브먼트 정확성 테스트"
                className="time"
              />

              <div className="detail-card-title">무브먼트 정확성 테스트</div>
              <div className="detail-card-desc">
                <strong>타이밍 오차(일 평균) 측정:</strong> 기준 위치에 따른
                오차 범위 점검
                <br />
                <strong>오차 조정:</strong> 칼리버에 맞는 기준치로 조정
                <br />
                <strong>파워 리저브 점검:</strong> 오토매틱 모듈 작동 확인
              </div>
            </div>

            <div className="detail-card">
              <img srcSet={movement2} alt="무브먼트 기능 테스트" />

              <div className="detail-card-title">무브먼트 기능 테스트</div>
              <div className="detail-card-desc">
                <strong>크라운 작동:</strong> 날짜와 요일 변경, 수동 와인딩
                <br />
                <strong>크로노그래프 기능:</strong> Start, Stop, Reset
                <br />
                <strong>GMT 핸즈, 회전 베젤</strong>
              </div>
            </div>
          </div>
        </div>

        <div className="details-row">
          <div className="flex-row">
            <div className="detail-card">
              <img srcSet={polishing} alt="폴리싱" />

              <div className="detail-card-title">폴리싱</div>
              <div className="detail-card-desc">
                <strong>점검:</strong> 외장 상태, 세척
                <br />
                <strong>외형 복원:</strong> 새틴 / 리니어 / 서큘러 / 샌드
                피니시, 광택 (일부 제외)
                <br />
                <strong>찍힘 및 스크래치 제거</strong>
              </div>
            </div>

            <div className="detail-card">
              <img srcSet={waterProof} alt="방수 테스트" />

              <div className="detail-card-title">방수 테스트</div>
              <div className="detail-card-desc">
                <strong>공기압, 진공, 수압, 응축 실험</strong>
                <br />
                <strong>방수 확인:</strong> 베젤, 유리(크리스탈), 크라운, 푸셔,
                케이스 백<br />
                <strong>수리 및 조정:</strong> 완벽한 방수 보장
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetails;
