import React from 'react';

import circledMark from '../../../assets/svg/apo360.svg';
import smallMark from '../../../assets/svg/small-mark-wht.svg';

import './LastSection.css';

const LastSection = () => {
  return (
    <>
      <div className="cpo-last-section-layout">
        <div className="cpo-last-section-container">
          <div className="row-container">
            <div className="text-box">
              <div className="title">평가에 대한 약속</div>
              <div className="sub-title">Commitment to Approval</div>
              <div className="desc">
                블루먼트 인증 평가는 각 인증 시계에 대해 최초 판매될 때와 같은
                품질을 유지하기 위해 확인 절차를 따릅니다. 또한, 시계의 심장인
                무브먼트와 기능 그리고 외관 상태 진단 후 철저한 평가와 가치를
                판단합니다.
              </div>
              <div className="desc-important">
                Bluement에서 판매되는 모든 시계는 Approved Pre-Owned Watch를
                획득합니다.
              </div>
            </div>
          </div>
          <img
            srcSet={circledMark}
            alt="circled-cpo-mark"
            loading="lazy"
            className="cpo-mark-rotate"
          />

          <div className="row-container place-on-right">
            <div className="text-box">
              <div className="title">완벽한 아름다움의 회복</div>
              <div className="sub-title">Restoration of Perfect Beauty</div>
              <div className="desc">
                외관과 기계식 무브먼트의 완벽한 아름다움을 회복하고 파워 리저브
                테스트, 방수 기능을 검증하여 각 시계 본연의 기능과 모습을
                되찾습니다.
              </div>
              <div className="desc-important">
                Bluement의 모든 시계는 처음 구매했을 때와 같은 아름다움을
                갖습니다.
              </div>
            </div>
          </div>

          <div className="row-container">
            <div className="text-box">
              <div className="title">신뢰할 수 있는 공간</div>
              <div className="sub-title">The Space you can trust</div>
              <div className="desc">
                Bluement 부티크로 시계를 가져와 시계 전문가들과 상담할 수
                있습니다. 신뢰할 수 있는 시계 진단과 점검으로 시계 상태를 정확히
                확인하고 판매 여부를 결정할 수 있습니다. 내 시계를 판매 후
                블루먼트 인증 시계를 구매할 수 있습니다.
              </div>
              <div className="desc-important">
                보유 중인 시계를 팔거나 새로운 제품을 구매할 수 있습니다.
              </div>
            </div>
          </div>
        </div>

        <div className="row-container">
          <div className="bottom-right-mark">
            <div className="text">
              Approved Pre-Owned
              <br />
              Watch by BLUEMENT
            </div>
            <img srcSet={smallMark} alt="small-cpo-mark" loading="lazy" />
          </div>
        </div>
      </div>
    </>
  );
};

export default LastSection;
