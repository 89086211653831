import React from 'react';
import { Divider } from 'antd';
import { Link } from 'react-router-dom';

import './ContactBanner.css';
import policyImg from '../../../assets/icons/policy.png';
import questionImg from '../../../assets/icons/question.png';

const ContactBanner = ({ bgColor = '#f7f7f7' }) => {
  return (
    <div
      className="contact-banner-container"
      style={{ backgroundColor: bgColor }}
    >
      <div className="left">
        <img srcSet={policyImg} alt={'policy'} loading="lazy" />
        <div className="text-box">
          <div className="content">
            블루먼트 인증시계
            <br />
            블루먼트의 모든 시계는 성능과 품질이 보장된 시계입니다.
          </div>
          <div className="link">
            <Link to="/apo">LEARN MORE</Link>
          </div>
        </div>
      </div>
      <Divider type="vertical" />
      <div className="right">
        <img srcSet={questionImg} alt={'question'} loading="lazy" />
        <div className="text-box">
          <div className="content">
            질문이 있으신가요? <br />
            1:1 문의를 남겨주시거나, 02-545-9701로 연락주세요.
          </div>
          <div className="link">
            <Link to="/contact-us">CONTACT</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactBanner;
