import React from "react";
import MediaQuery from "react-responsive";

import "./Modal.css";

const PatekModal = (props) => {
  const closeModal = () => {
    props.closeModal();

    document.querySelector(".close-btn").style.zIndex = -2;
  };

  return (
    <div className="notice-modal">
      <div className="modal-body">
        <img
          srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/notice/patek-modal.jpg"
          alt="파텍 필립 위탁판매 오픈"
        />

        <div className="text-box">
          <div className="title">위탁판매 거래 시작</div>
          <div className="desc">
            2023년 9월 18일
            <br />
            롤렉스 및 파텍 필립 위탁판매 거래를 시작합니다.
          </div>

          <MediaQuery minWidth={361}>
            <div className="benefit">
              *거래혜택 3가지
              <br />
              안심 위탁 수수료 5%
              <br />
              시계 전문가의 제품 관리와 고객 상담
              <br />
              전문 워치메이커의 인증, 성능테스트, 잔기스 제거, 세척
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={360.98}>
            <div className="benefit">
              *거래혜택 3가지
              <br />
              안심 위탁 수수료 5%
              <br />
              시계 전문가의 제품 관리와 고객 상담
              <br />
              전문 워치메이커의 인증, 성능테스트,
              <br />
              잔기스 제거, 세척
            </div>
          </MediaQuery>
        </div>

        <div className="close-btn">
          <button onClick={closeModal}>닫기</button>
          <a href="/" target="_blank" rel="noreferrer">
            <button onClick={closeModal}>방문예약</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default PatekModal;
