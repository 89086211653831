import React from 'react';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';

import './CertSection.css';

import cpoMarkWhite from '../../../assets/svg/apo_black.svg';
import cpoMarkBlack from '../../../assets/svg/apo_white.svg';

const Switch = styled.div`
  position: relative;
  width: 202px;
  height: 50px;
  margin: 33px auto;
  border-radius: 25px;
  //   background-color: #9c9c9c;
  border: 1px solid #999999;
  span {
    position: absolute;
    width: 100px;
    height: 50px;
    top: -1px;
    border-radius: 25px;
    background-color: #333333;
    transition: all 0.2s ease-in-out;
    z-index: 1;
    ${(
      { value } //props에 따른 삼항연산자 처리
    ) =>
      value === 'white'
        ? 'transform: translateX(-1px)'
        : 'transform: translateX(101px)'}
  }
`;

const button = styled.button`
  //value 타입 정의
  position: relative;
  width: 100px;
  height: 50px;
  color: #666666;
  border-radius: 25px;
  border: 0;
  background-color: transparent;
  font-weight: var(--pretendard-bold);
  font-size: 18px;
  cursor: pointer;
  z-index: 2;
`;

//button 컴포넌트 상속
const InfoBtn = styled(button)`
  ${({ value }) => (value === 'white' ? 'color: #ffffff;' : null)}
  transition: color 0.7s ease;
`;

//button 컴포넌트 상속
const ReviewBtn = styled(button)`
  ${({ value }) => (value === 'black' ? 'color: #ffffff;' : null)}
  transition: color 0.7s ease;
`;

const CertSection = () => {
  const [toggleValue, setToggleValue] = React.useState('white');

  //버튼 클릭시마다 타입을 바꿔줍니다
  const onChangeMode = (type) => {
    setToggleValue(type);
  };

  return (
    <div className="cpo-cert-layout">
      <MediaQuery minWidth={1260}>
        <div className="cpo-cert-container">
          <div className="column-1 title cpo-title">BLUEMENT 인증</div>

          <div className="column-2">
            <div className="paragraph">
              <div className="no">1</div>
              <div className="content">
                <div className="content-title">A.P.O.</div>
                <div className="content-desc">
                  명품 중고 시계 브랜드에 대한 품질과 성능 인증, Bluement 인증
                  시계는 제품 검증과 제품 진단을 통해 외관 품질 및 시계의 기술적
                  성능까지 보증합니다.
                </div>
              </div>
            </div>

            <div className="paragraph">
              <div className="no">2</div>
              <div className="content">
                <div className="content-title">Engineering Asset</div>
                <div className="content-desc">
                  Bluement Approved Pre-Owned Watch는 국내 유일의 다른 누구도
                  진입할 수 없는 수준의 전문성과 기술적 우수성을 제공합니다.
                </div>
              </div>
            </div>

            <div className="paragraph paragraph-3">
              <div className="no">3</div>
              <div className="content">
                <div className="content-title">Offline Contact Point</div>
                <div className="content-desc">
                  Bluement 부티크는 기술 인증의 핵심 역량인 엔지니어룸과 인증
                  시계를 발견할 수 있는 신뢰할 수 있는 공간을 제공합니다.
                </div>
              </div>
            </div>
          </div>

          <div className="column-3">
            <div
              className="cpo-mark-wrapper"
              style={
                toggleValue === 'white'
                  ? { backgroundColor: '#ffffff' }
                  : { backgroundColor: '#000000' }
              }
            >
              <img
                srcSet={toggleValue === 'white' ? cpoMarkWhite : cpoMarkBlack}
                loading="lazy"
                alt="cpo-mark"
              />
            </div>
            <div className="switch">
              <Switch value={toggleValue}>
                {/* span - 토글버튼 */}
                <span />
                {/* white 버튼 */}
                <InfoBtn
                  type="button"
                  value={toggleValue}
                  onClick={() => onChangeMode('white')}
                >
                  White
                </InfoBtn>

                {/* black 버튼 */}
                <ReviewBtn
                  type="button"
                  value={toggleValue}
                  onClick={() => onChangeMode('black')}
                >
                  Black
                </ReviewBtn>
              </Switch>
            </div>
          </div>
        </div>
      </MediaQuery>
      <MediaQuery minWidth={768} maxWidth={1259.98}>
        <div className="cpo-cert-container">
          <div className="column-1 title mb-24 cpo-title">BLUEMENT 인증</div>
          <div className="content-container">
            <div className="column-2">
              <div className="paragraph">
                <div className="no">1</div>
                <div className="content">
                  <div className="content-title">블루먼트 인증 중고</div>
                  <div className="content-desc">
                    명품 중고 시계 브랜드에 대한 품질과 성능 인증, Bluement 인증
                    시계는 제품 검증과 제품 진단을 통해 외관 품질 및 시계의
                    기술적 성능까지 보증합니다.
                  </div>
                </div>
              </div>

              <div className="paragraph">
                <div className="no">2</div>
                <div className="content">
                  <div className="content-title">Engineering Asset</div>
                  <div className="content-desc">
                    Bluement Approved Pre-Owned Watch는 국내 유일의 다른 누구도
                    진입할 수 없는 수준의 전문성과 기술적 우수성을 제공합니다.
                  </div>
                </div>
              </div>

              <div className="paragraph paragraph-3">
                <div className="no">3</div>
                <div className="content">
                  <div className="content-title">Offline Contact Point</div>
                  <div className="content-desc">
                    Bluement 부티크는 기술 인증의 핵심 역량인 엔지니어룸과 인증
                    시계를 발견할 수 있는 신뢰할 수 있는 공간을 제공합니다.
                  </div>
                </div>
              </div>
            </div>

            <div className="column-3">
              <div
                className="cpo-mark-wrapper"
                style={
                  toggleValue === 'white'
                    ? { backgroundColor: '#ffffff' }
                    : { backgroundColor: '#000000' }
                }
              >
                <img
                  srcSet={toggleValue === 'white' ? cpoMarkWhite : cpoMarkBlack}
                  loading="lazy"
                  alt="cpo-mark"
                />
              </div>
              <div className="switch">
                <Switch value={toggleValue}>
                  {/* span - 토글버튼 */}
                  <span />
                  {/* white 버튼 */}
                  <InfoBtn
                    type="button"
                    value={toggleValue}
                    onClick={() => onChangeMode('white')}
                  >
                    White
                  </InfoBtn>

                  {/* black 버튼 */}
                  <ReviewBtn
                    type="button"
                    value={toggleValue}
                    onClick={() => onChangeMode('black')}
                  >
                    Black
                  </ReviewBtn>
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={767.98}>
        <div className="cpo-cert-container-column">
          <div className="column-1 title mb-50 cpo-title">BLUEMENT 인증</div>

          <div className="column-2 mb-100">
            <div className="paragraph">
              <div className="no">1</div>
              <div className="content">
                <div className="content-title">블루먼트 인증 중고</div>
                <div className="content-desc">
                  명품 중고 시계 브랜드에 대한 품질과 성능 인증, Bluement 인증
                  시계는 제품 검증과 제품 진단을 통해 외관 품질 및 시계의 기술적
                  성능까지 보증합니다.
                </div>
              </div>
            </div>

            <div className="paragraph">
              <div className="no">2</div>
              <div className="content">
                <div className="content-title">Engineering Asset</div>
                <div className="content-desc">
                  Bluement Approved Pre-Owned watch는 국내 유일의 다른 누구도
                  진입할 수 없는 수준의 전문성과 기술적 우수성을 제공합니다.
                </div>
              </div>
            </div>

            <div className="paragraph paragraph-3">
              <div className="no">3</div>
              <div className="content">
                <div className="content-title">Offline Contact Point</div>
                <div className="content-desc">
                  Bluement 부티크는 기술 인증의 핵심 역량인 엔지니어룸과 인증
                  시계를 발견할 수 있는 신뢰할 수 있는 공간을 제공합니다.
                </div>
              </div>
            </div>
          </div>

          <div className="column-3">
            <div
              className="cpo-mark-wrapper"
              style={
                toggleValue === 'white'
                  ? { backgroundColor: '#ffffff' }
                  : { backgroundColor: '#000000' }
              }
            >
              <img
                srcSet={toggleValue === 'white' ? cpoMarkWhite : cpoMarkBlack}
                loading="lazy"
                alt="cpo-mark"
              />
            </div>
            <div className="switch">
              <Switch value={toggleValue}>
                {/* span - 토글버튼 */}
                <span />
                {/* white 버튼 */}
                <InfoBtn
                  type="button"
                  value={toggleValue}
                  onClick={() => onChangeMode('white')}
                >
                  White
                </InfoBtn>

                {/* black 버튼 */}
                <ReviewBtn
                  type="button"
                  value={toggleValue}
                  onClick={() => onChangeMode('black')}
                >
                  Black
                </ReviewBtn>
              </Switch>
            </div>
          </div>
        </div>
      </MediaQuery>
    </div>
  );
};

export default CertSection;
