import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';

// Styles
import '../components/BuyWatch/BuyWatch.css';

// Components
import BuyProcess from '../components/BuyWatch/BuyProcess';
import BuyCircledCard from '../components/BuyWatch/BuyCircledCard';
import CollectionSlider from '../components/Collection/CollectionSlider/CollectionSlider';
import GoogleMapComponent from '../components/GoogleMap/GoogleMapComponent';

import { AnalyticsContext } from '../Analytics';
import MetaTag from '../MetaTag';

const { Content } = Layout;

const BuyWatch = () => {
  const { analytics } = React.useContext(AnalyticsContext); // Google Analytics

  useEffect(() => {
    // Google Analytics
    analytics.send({ hitType: 'pageview', page: '/buy/buy-watch' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MetaTag
        title="블루먼트 | Buy Watch"
        url="https://www.bluement.kr/buy/buy-watch"
        keywords="rolex,롤렉스,중고롤렉스,롤렉스매장,중고롤렉스매장,중고시계,중고시계매장,명품시계매장,블루먼트,bluement,cpo,certified pre-owned watch,pre-owned,preowned,preowned rolex,명품,명품시계,시계,거래,타임피스,거래 플랫폼"
      />
      <Layout>
        <Content>
          <div className="section-gap"></div>

          {/* Section 1: 구매 프로세스 */}
          <BuyProcess />

          <div className="section-gap"></div>
          <div className="section-gap"></div>

          {/* Section 2: 구매자를 위한 혜택 */}
          <div className="section-w-bg">
            <div className="section-title">
              {/* <MediaQuery maxWidth={650.98}>
                BLUEMENT에서
                <br />
                구매해야 하는 이유
              </MediaQuery>
              <MediaQuery minWidth={651}>
                구매자를 위한 혜택
              </MediaQuery> */}
              구매자를 위한 혜택
            </div>
            <div className="section-gap"></div>
            <BuyCircledCard />
          </div>

          <div className="section-gap"></div>

          {/* Section 3: Collection */}
          <div className="section-container">
            <div className="section-title">블루먼트 인증시계 컬렉션</div>
            <div className="section-gap-60"></div>
            <CollectionSlider />

            {/* <div className="oval-btn-lg">
              <Link to="/buy/collection">
                <button>컬렉션 더 보기</button>
              </Link>
            </div> */}
          </div>

          <div className="section-gap"></div>

          {/* Section 4: 부티크 방문 */}
          <div className="section-w-bg">
            {/* <div className="section-w-bg visit-boutique"> */}
            <div className="section-title">BLUEMENT 부티크에 방문하세요</div>
            <div className="section-sub-title">
              <MediaQuery maxWidth={500.98}>
                BLUEMENT 부티크에서 <br />
                시계 전문 큐레이터의 상담을 받아보세요.
                <br />
                원하시는 방문 날짜가 있으신가요?
                <br />
                네이버 예약으로 방문 예약을 하실 수 있습니다.
              </MediaQuery>
              <MediaQuery maxWidth={670.98} minWidth={501}>
                BLUEMENT 부티크에서 시계 전문 큐레이터의 상담을 받아보세요.
                <br />
                원하시는 방문 날짜가 있으신가요?
                <br />
                네이버 예약으로 방문 예약을 하실 수 있습니다.
              </MediaQuery>
              <MediaQuery minWidth={671}>
                BLUEMENT 부티크에서 시계 전문 큐레이터의 상담을 받아보세요.
                <br />
                원하시는 방문 날짜가 있으신가요? 네이버 예약으로 방문 예약을
                하실 수 있습니다.
              </MediaQuery>
            </div>
            <div className="google-map-section">
              <GoogleMapComponent />
            </div>

            <div className="oval-btn-lg">
              <a href="/" target="_blank" rel="noreferrer">
                <button>방문 예약하기</button>
              </a>
            </div>
          </div>
        </Content>
      </Layout>
    </>
  );
};

export default BuyWatch;
