import React, { useEffect } from 'react';
import { Layout, Divider } from 'antd';

import ContactForm from '../components/Support/ContactForm/ContactForm';

import { AnalyticsContext } from '../Analytics';
import MetaTag from '../MetaTag';

import '../components/Support/Support.css';

const { Content } = Layout;

const Support = () => {
  const { analytics } = React.useContext(AnalyticsContext);

  useEffect(() => {
    analytics.send({ hitType: 'pageview', page: '/contact-us' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MetaTag
        title="블루먼트 | Contact Us"
        url="https://www.bluement.kr/contact-us"
      />
      <Layout>
        <Content>
          <div className="support-container">
            <div className="support-left">
              <div className="title">Contact Us</div>

              <div className="email">
                <div className="email-title">E-Mail</div>
                <div className="email-content">
                  <span>support</span>@bluebase.kr
                </div>
              </div>

              <div className="boutique">
                <a
                  href="https://naver.me/5zo69W7o"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="boutique-title">BLUEMENT Boutique</div>
                  <div className="boutique-addr">
                    26, Seolleung-ro 153-gil,
                    <br />
                    Gangnam-gu,
                    <br />
                    Seoul, Republic of Korea
                  </div>

                  <div className="boutique-phone">
                    <u>+82 2 545 9701</u>
                  </div>
                </a>
              </div>
            </div>

            <Divider type="vertical" />

            <div className="support-right">
              <div className="show-on-mobile mobile-title">Contact Us</div>
              <div className="top-message">
                궁금한 사항을 문의주시면 친절하게 답변드리겠습니다.
              </div>

              <ContactForm />
            </div>
          </div>
        </Content>
      </Layout>
    </>
  );
};

export default Support;
