import React from 'react';
import { Layout } from 'antd';
import MediaQuery from 'react-responsive';

import { AnalyticsContext } from '../Analytics';
import MetaTag from '../MetaTag';

// Styles
import '../components/CPO/CPOpayback.css';

// Components
// import Payback from '../components/CPO/Payback/Payback';
import Payback2 from '../components/CPO/Payback/Payback2';
import ContactBanner from '../components/CommonSection/ContactBanner/ContactBanner';

const { Content } = Layout;

const CPOpayback = () => {
  const { analytics } = React.useContext(AnalyticsContext); // Google Analytics

  React.useEffect(() => {
    // Google Analytics
    analytics.send({
      hitType: 'pageview',
      page: '/apo/payback-program',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MetaTag
        title="블루먼트 | 가격 보장 프로그램"
        url="https://www.bluement.kr/apo/payback-program"
        keywords="rolex,롤렉스,중고롤렉스,롤렉스매입,롤렉스맞춤형매입,명품시계맞춤형매입,중고롤렉스매입,중고시계,중고시계매입,명품시계매입,블루먼트,bluement,cpo,certified pre-owned watch,pre-owned,preowned,preowned rolex,명품,명품시계,시계,거래,타임피스,거래 플랫폼"
      />
      <Layout>
        <Content className="payback-layout">
          {/* <div className="section-start-gap"></div> */}
          {/* Title */}
          <div className="page-title-container">
            <div className="title">
              <div className="section-title title-above">
                블루먼트 가격보장 프로그램
              </div>
              <div className="section-title-lg text-bold">
                BLUEMENT Guaranteed
                <br />
                by Payback Program
              </div>
              <MediaQuery minWidth={519}>
                <div className="section-sub-title">
                  블루먼트는 시계 가치를 보장하고 거래의 편리함을 제공합니다.
                </div>
              </MediaQuery>
              <MediaQuery maxWidth={518.98}>
                <div className="section-sub-title">
                  블루먼트는 시계 가치를 보장하고
                  <br />
                  거래의 편리함을 제공합니다.
                </div>
              </MediaQuery>
            </div>
          </div>

          <div className="cpo-payback-container">
            <Payback2 />
          </div>

          <ContactBanner bgColor="#ffffff" />
        </Content>
      </Layout>
    </>
  );
};

export default CPOpayback;
