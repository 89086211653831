import React, { useState, useEffect } from "react";
import {
  Layout,
  Form,
  Input,
  Checkbox,
  // Radio,
  Select,
  Modal,
  Typography,
  Row,
  Col,
  Button,
} from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import MediaQuery from "react-responsive";

// import emailjs from '@emailjs/browser';
// import moment from 'moment';

// import ReactGA from 'react-ga4';

import { API } from "aws-amplify";
import awsExports from "../../aws-exports";

// Styles
import "../../assets/styles/FormStyle.css";

const { Content } = Layout;
const { TextArea } = Input;
const { Text } = Typography;

API.configure(awsExports);

const component_options = [
  { label: "시계 박스", value: "시계 박스" },
  { label: "보증 카드", value: "보증 카드" },
  { label: "보증서 홀더", value: "보증서 홀더" },
  { label: "사용설명서", value: "사용설명서" },
  { label: "COSC 인증설명서", value: "COSC 인증설명서" },
  { label: "Full 링크", value: "Full 링크" },
  { label: "Seal(씰)", value: "Seal(씰)" },
];

const TradeForm = () => {
  const [form] = Form.useForm();

  // const [conditionValue, setConditionValue] = useState(null);
  // const [genderValue, setGenderValue] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [componentValues, setComponentValues] = useState(null);
  const [message, setMessage] = useState("");

  const [modalOpen, setModalOpen] = useState(false);

  // Select 옵션
  const [beforeTradeOptions, setBeforeTradeOptions] = useState(null); // Select 옵션 - before trade
  const [afterTradeOptions, setAfterTradeOptions] = useState(null); // Select 옵션 - after trade

  // Select 옵션이 기타일 경우 추가 Input 필수항목으로 지정 필요 여부 결정 (visible도 동시에 결정)
  const [isBeforeTradeRequired, setIsBeforeTradeRequired] = useState(false);
  const [isAfterTradeRequired, setIsAfterTradeRequired] = useState(false);

  const [disable, setDisable] = useState(false);

  // const createOptions = (rolexData) => {
  //   const options = [];

  //   if (rolexData.length > 0) {
  //     rolexData.forEach((model) => {
  //       const modelObj = {
  //         label:
  //           model.refNo + ' / ' + model.bracelet_display + ' / ' + model.dial,
  //         value:
  //           model.refNo + ' / ' + model.bracelet_display + ' / ' + model.dial,
  //       };

  //       options.push(modelObj);
  //     });
  //   }

  //   options.push({
  //     label: '기타',
  //     value: '기타',
  //   });

  //   setBeforeTradeOptions(options);
  //   setAfterTradeOptions(options);
  // };

  useEffect(() => {
    // Cascader 옵션 생성 위한 Rolex 전체 모델 불러오기
    // API.get('buyListApi', '/items').then((res) => {
    //   if (res) {
    //     createOptions(res);
    //   }
    // });

    const selectOptionsDummy = [
      {
        label: "116500LN (Oysterflex Black)",
        value: "116500LN / Oysterflex / Black",
      },
      {
        label: "116500LN (Oysterflex White)",
        value: "116500LN / Oysterflex / White",
      },
      {
        label: "기타",
        value: "기타",
      },
    ];

    setBeforeTradeOptions(selectOptionsDummy);
    setAfterTradeOptions(selectOptionsDummy);
  }, []);

  const handleModalOk = () => {
    // Send a custom event
    // ReactGA.event({
    //   category: 'modal',
    //   action: 'click',
    //   label: `trade-modal-ok`, // optional
    //   value: 6, // optional, must be a number
    //   nonInteraction: false, // optional, true/false
    // });
    // setTimeout(() => {
    //   setModalOpen(false);
    // }, 2000);
    setModalOpen(false);
  };

  const handleModalCancel = () => {
    // Send a custom event
    // ReactGA.event({
    //   category: 'modal',
    //   action: 'click',
    //   label: `trade-modal-close`, // optional
    //   value: 6, // optional, must be a number
    //   nonInteraction: false, // optional, true/false
    // });
    setModalOpen(false);
  };

  const onComponentChange = (checkedValues) => {
    setComponentValues(checkedValues);
  };

  const onMessageChange = (e) => {
    setMessage(e.target.value);
  };

  // form 제출 후 reset
  const onReset = () => {
    form.resetFields();
    onSelectClear();
  };

  // Select의 clear 버튼 클릭 시
  const onSelectClear = () => {
    setIsBeforeTradeRequired(false);
    setIsAfterTradeRequired(false);
  };

  const onBeforeTradeModel = (value, option) => {
    if (value === "기타") {
      setIsBeforeTradeRequired(true);
    } else {
      setIsBeforeTradeRequired(false);
    }
  };

  const onAfterTradeModel = (value, option) => {
    if (value === "기타") {
      setIsAfterTradeRequired(true);
    } else {
      setIsAfterTradeRequired(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Form Finish Failed: ", errorInfo);
  };

  const onFinish = async (values) => {
    // console.log('Success:', values);
    // Send a custom event
    // ReactGA.event({
    //   category: 'form',
    //   action: 'submit',
    //   label: `trade-submit`, // optional
    //   value: 5, // optional, must be a number
    //   nonInteraction: false, // optional, true/false
    // });

    setDisable(true);

    // const componentString =
    //   values.components !== undefined && values.components.length > 0
    //     ? values.components.map((component) => component).join(' / ')
    //     : '없음';

    // const formValues = {
    //   body: {
    //     name: values.name,
    //     phone: values.phone,
    //     email: values.email,
    //     components: componentString,
    //     message: values.message,
    //     source: '홈페이지',
    //   },
    // };

    // const emailContent = {
    //   createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
    //   name: values.name,
    //   phone: values.phone,
    //   email: values.email,
    //   components: componentString,
    //   message: values.message,
    //   source: '홈페이지',
    // };

    // // 기타 모델이 선택되었을 경우, Input 내용 사용
    // if (
    //   values.beforeTradeModel === '기타' &&
    //   values.afterTradeModel === '기타'
    // ) {
    //   formValues.body.etcModelOwn = values.beforeTradeModelInput;
    //   formValues.body.etcModelTrade = values.afterTradeModelInput;

    //   emailContent.ownModel = values.beforeTradeModelInput;
    //   emailContent.tradeModel = values.afterTradeModelInput;
    // } else if (
    //   values.beforeTradeModel === '기타' &&
    //   values.afterTradeModel !== '기타'
    // ) {
    //   formValues.body.etcModelOwn = values.beforeTradeModelInput;
    //   emailContent.ownModel = values.beforeTradeModelInput;

    //   const tradeModelInfo = values.afterTradeModel.split(' / ');
    //   emailContent.tradeModel = values.afterTradeModel;

    //   formValues.body.refNoTrade = tradeModelInfo[0];
    //   formValues.body.braceletTrade = tradeModelInfo[1];
    //   formValues.body.dialTrade = tradeModelInfo[2];
    // } else if (
    //   values.beforeTradeModel !== '기타' &&
    //   values.afterTradeModel === '기타'
    // ) {
    //   formValues.body.etcModelTrade = values.afterTradeModelInput;
    //   emailContent.tradeModel = values.afterTradeModelInput;

    //   const ownModelInfo = values.beforeTradeModel.split(' / ');
    //   emailContent.ownModel = values.beforeTradeModel;

    //   formValues.body.refNoOwn = ownModelInfo[0];
    //   formValues.body.braceletOwn = ownModelInfo[1];
    //   formValues.body.dialOwn = ownModelInfo[2];
    // } else {
    //   const ownModelInfo = values.beforeTradeModel.split(' / ');
    //   const tradeModelInfo = values.afterTradeModel.split(' / ');
    //   emailContent.ownModel = values.beforeTradeModel;
    //   emailContent.tradeModel = values.afterTradeModel;

    //   formValues.body.refNoOwn = ownModelInfo[0];
    //   formValues.body.braceletOwn = ownModelInfo[1];
    //   formValues.body.dialOwn = ownModelInfo[2];

    //   formValues.body.refNoTrade = tradeModelInfo[0];
    //   formValues.body.braceletTrade = tradeModelInfo[1];
    //   formValues.body.dialTrade = tradeModelInfo[2];
    // }

    // emailjs
    //   .send(
    //     'service_joyw7wj',
    //     'template_zdli708',
    //     emailContent,
    //     'g1tBadgOE5cAtev0t'
    //   )
    //   .then(
    //     (result) => {
    //       console.log('email sent successfully.');

    //       onReset();
    //       setModalOpen(true);
    //       setDisable(false);
    //       setTimeout(() => {
    //         window.open(
    //           'https://booking.naver.com/booking/6/bizes/846736/items/5151453',
    //           '_blank',
    //           'noopener, noreferrer'
    //         );
    //       }, 2000);
    //     },
    //     (error) => {
    //       console.log('email send error: ');
    //       console.log(error.text);
    //     }
    //   );

    // DB에 post
    // return await API.post('formApi', '/trade', formValues)
    //   .then((res) => {

    //   })
    //   .catch((err) => {
    //     console.error(
    //       'error creating/updating data to Trade DB: ',
    //       err.response
    //     );
    //   });

    onReset();
    setModalOpen(true);
    setDisable(false);
  };

  return (
    <Layout>
      <Content>
        <Row justify="end" className="form-section-title">
          <Col span={9} className="text-center">
            <div className="form-label">
              <span className="essential">*</span>필수입력
            </div>
          </Col>
        </Row>

        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          requiredMark={true}
          className="service-form"
        >
          {/* 이름 */}
          <Row justify="center">
            <Col span={16}>
              <Form.Item
                label="이름"
                labelCol={{ span: 24 }}
                name="name"
                rules={[{ required: true, message: "이름을 입력해주세요." }]}
              >
                <Input placeholder="홍길동" />
              </Form.Item>
            </Col>
          </Row>

          {/* 연락처 */}
          <Row justify="center">
            <Col span={16}>
              <Form.Item
                label="연락처"
                labelCol={{ span: 24 }}
                name="phone"
                rules={[{ required: true, message: "연락처를 입력해주세요." }]}
              >
                <Input placeholder="010-1234-5678" />
              </Form.Item>
            </Col>
          </Row>

          {/* 이메일 */}
          <Row justify="center">
            <Col span={16}>
              <Form.Item
                label="이메일"
                labelCol={{ span: 24 }}
                name="email"
                rules={[{ required: true, message: "이메일을 입력해주세요." }]}
              >
                <Input placeholder="abc@gmail.com" />
              </Form.Item>
            </Col>
          </Row>

          {/* 판매할 모델명 */}
          <Row
            justify="center"
            className={isBeforeTradeRequired ? "no-mb" : ""}
          >
            <Col
              span={16}
              className={isBeforeTradeRequired ? "refNo-form-col" : "mb-24"}
            >
              <Form.Item
                className="refNo-form-item form-item-with-guideText"
                label="현재 보유 모델"
                labelCol={{ span: 24 }}
                name="beforeTradeModel"
                rules={[
                  {
                    required: true,
                    message: "보유하고 계신 시계의 모델명을 선택해주세요.",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear={true}
                  loading={beforeTradeOptions === null ? true : false}
                  disabled={beforeTradeOptions === null ? true : false}
                  placeholder="모델 선택 또는 검색 (없다면 '기타')"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={beforeTradeOptions}
                  onClear={onSelectClear}
                  onSelect={onBeforeTradeModel}
                />
              </Form.Item>
              <span className="pl-2">
                ※ 목록에 모델이 없다면 '기타'를 입력해주세요.
              </span>
            </Col>
          </Row>

          {/* 판매할 모델명 - 기타 선택 시 입력 필드 */}
          <Row
            justify="center"
            className={isBeforeTradeRequired ? "" : "hidden"}
          >
            <Col span={16}>
              <Form.Item
                name="beforeTradeModelInput"
                rules={[
                  {
                    required: isBeforeTradeRequired,
                    message: "보유하신 모델명을 직접 입력해주세요.",
                  },
                ]}
              >
                <Input placeholder="ex) 116500LN / Black / Oyster" />
              </Form.Item>
            </Col>
          </Row>

          {/* 구성품 */}
          <Row justify="center">
            <Col span={16}>
              <Form.Item
                label="구성품"
                labelCol={{ span: 24 }}
                name="components"
              >
                <Checkbox.Group
                  name="componentsCheckGroup"
                  options={component_options}
                  onChange={onComponentChange}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* 구매할 모델명 */}
          <Row justify="center" className={isAfterTradeRequired ? "no-mb" : ""}>
            <Col
              span={16}
              className={isAfterTradeRequired ? "refNo-form-col" : "mb-24"}
            >
              <Form.Item
                className="refNo-form-item form-item-with-guideText"
                label="교환 희망 모델"
                labelCol={{ span: 24 }}
                name="afterTradeModel"
                rules={[
                  {
                    required: true,
                    message: "교환을 원하시는 시계의 모델명을 선택해주세요.",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear={true}
                  loading={afterTradeOptions === null ? true : false}
                  disabled={afterTradeOptions === null ? true : false}
                  placeholder="모델 선택 또는 검색 (없다면 '기타')"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={afterTradeOptions}
                  onClear={onSelectClear}
                  onSelect={onAfterTradeModel}
                />
              </Form.Item>
              <span className="pl-2">
                ※ 목록에 모델이 없다면 '기타'를 입력해주세요.
              </span>
            </Col>
          </Row>

          {/* 구매할 모델명 - 기타 선택 시 입력 필드 */}
          <Row
            justify="center"
            className={isAfterTradeRequired ? "" : "hidden"}
          >
            <Col span={16}>
              <Form.Item
                name="afterTradeModelInput"
                rules={[
                  {
                    required: isAfterTradeRequired,
                    message: "구매를 희망하는 모델명을 직접 입력해주세요.",
                  },
                ]}
              >
                <Input placeholder="ex) 116500LN / Black / Oyster" />
              </Form.Item>
            </Col>
          </Row>

          {/* 문의 내용 */}
          <Row justify="center">
            <Col span={16}>
              <Form.Item
                label="요청사항"
                labelCol={{ span: 24 }}
                name="message"
              >
                <TextArea
                  autoSize={{
                    minRows: 5,
                    maxRows: 10,
                  }}
                  value={message}
                  onChange={onMessageChange}
                  placeholder={`자세히 작성하시면 상담에 큰 도움이 됩니다.`}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="center form-guide-row">
            <Col span={16}>
              <p className="red-txt text-center form-guide">
                <MediaQuery maxWidth={458.98}>
                  작성해주신 <b>이메일</b>로 <br />
                  담당자가 연락 드릴 예정입니다.
                  <br />
                  원활한 서비스 진행을 위해 <br />
                  이메일에 <b>회신</b> 부탁드립니다.
                </MediaQuery>
                <MediaQuery minWidth={459}>
                  작성해주신 <b>이메일</b>로 담당자가 연락 드릴 예정입니다.
                  <br />
                  원활한 서비스 진행을 위해 이메일에 <b>회신</b> 부탁드립니다.
                </MediaQuery>
              </p>
            </Col>
          </Row>

          {/* 제출 버튼 */}
          <Row justify={"center"}>
            <Col span={16}>
              <Form.Item>
                <Button
                  className="form-btn-submit"
                  htmlType="submit"
                  // style={tailLayout}
                  disabled={disable}
                >
                  교환요청 & 방문예약
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        {modalOpen ? (
          <Modal
            open={modalOpen}
            onOK={handleModalOk}
            onCancel={handleModalCancel}
            className="form-modal"
            title={
              <>
                <CheckCircleFilled className="modal-title-icon" />
                <span className="modal-title-txt">교환 요청 완료</span>
              </>
            }
            footer={[
              <Button
                // key="naver"
                // href="https://booking.naver.com/booking/6/bizes/846736"
                type="primary"
                onClick={handleModalOk}
                className="modal-ok-btn"
              >
                확인
              </Button>,
            ]}
          >
            <p>교환 문의가 접수되었습니다.</p>
            <p>
              작성해주신 이메일로 담당자가 연락 드릴 예정입니다.
              <br />
              원활한 서비스 진행을 위해{" "}
              <Text mark>
                <b>이메일에 회신</b>
              </Text>{" "}
              부탁드립니다.
            </p>
            {/* <p>
              아래 버튼을 클릭하시면, 방문 예약을 위해 네이버 예약 페이지로
              이동합니다.
            </p>
            <p>
              <Text mark>원하시는 방문 날짜와 시간으로 예약 신청</Text>을
              해주시면, 담당자가 확인 후 예약을 확정해드립니다.
            </p> */}
          </Modal>
        ) : null}
      </Content>
    </Layout>
  );
};

export default TradeForm;
