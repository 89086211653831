import React from 'react';
import { Layout, Divider } from 'antd';

// Styles
import '../components/Bespoke/Bespoke.css';

// Components
import BespokeProcess from '../components/Bespoke/BespokeProcess';
import BespokeForm from '../components/Bespoke/BespokeForm';

// import { AnalyticsContext } from '../Analytics';
import MetaTag from '../MetaTag';

const { Content } = Layout;

const Bespoke = () => {
  // const { analytics } = React.useContext(AnalyticsContext); // Google Analytics

  // useEffect(() => {
  //   // Google Analytics
  //   // analytics.send({ hitType: 'pageview', page: '/buy/bespoke-order' });
  // }, []);

  return (
    <>
      <MetaTag
        title="블루먼트 | Bespoke Order"
        url="https://www.bluement.kr/buy/bespoke-order"
        keywords="rolex,롤렉스,중고롤렉스,롤렉스매입,롤렉스맞춤형매입,명품시계맞춤형매입,중고롤렉스매입,중고시계,중고시계매입,명품시계매입,블루먼트,bluement,cpo,certified pre-owned watch,pre-owned,preowned,preowned rolex,명품,명품시계,시계,거래,타임피스,거래 플랫폼"
      />
      <Layout>
        <Content className="bespoke-order">
          <div className="section-start-gap"></div>
          <BespokeProcess />
          <div className="section-gap"></div>

          <Divider />

          <div className="section-gap"></div>

          <div className="section-title form-title">매입 요청하기</div>

          <BespokeForm />

          <div className="section-gap"></div>
        </Content>
      </Layout>
    </>
  );
};

export default Bespoke;
