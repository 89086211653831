import React from "react";
import { Layout } from "antd";
import { Link } from "react-router-dom";

import "./Footers.css";

const { Footer } = Layout;

const Footers = () => {
  return (
    <>
      <Footer className="footer">
        <div className="footer-top-container">
          <div className="footer-top-content">
            <div className="title">예약</div>
            <div className="menu">
              <a href="/" target="_blank" rel="noreferrer">
                <div>방문 예약하기</div>
              </a>
              <a href="/" target="_blank" rel="noreferrer">
                <div>수리 예약하기</div>
              </a>
            </div>
          </div>

          <div className="footer-top-content">
            <div className="title">고객센터</div>
            <div className="menu">
              <Link to="/contact-us">Contact Us</Link>
            </div>
          </div>

          <div className="footer-top-content">
            <div className="title">소셜</div>
            <div className="menu">
              <a href="/" target="_blank" rel="noreferrer">
                <div>Instagram</div>
              </a>
              {/* <a
                href="https://www.facebook.com/profile.php?id=100092845082760"
                target="_blank"
                rel="noreferrer"
              >
                <div>Facebook</div>
              </a> */}
              <a href="/" target="_blank" rel="noreferrer">
                <div>YouTube</div>
              </a>
              <a href="/" target="_blank" rel="noreferrer">
                <div>Twitter</div>
              </a>
            </div>
          </div>
        </div>

        <div className="footer-bottom-container">
          <div className="addr">
            주식회사 블루베이스 압구정 로데오점 | 대표자: 이재윤
            <br />
            사업장 소재지: 서울시 강남구 선릉로153길 26, 1, 2층 (신사동,
            성준빌딩)
            <br />
            대표번호: 02-545-9701 | 사업자 등록번호: 894-85-02185 |
            <Link to="/terms/privacy"> 개인정보처리방침</Link>
          </div>

          <div className="copyright">
            <div>© 블루먼트</div>
            <div>대한민국</div>
          </div>
        </div>
      </Footer>
    </>
  );
};

export default Footers;
