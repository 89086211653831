import React, { useEffect } from 'react';
import { Layout, Divider } from 'antd';

// Styles
import '../components/TradeWatch/TradeWatch.css';

// Components
import TradeProcess from '../components/TradeWatch/TradeProcess';
import TradeForm from '../components/TradeWatch/TradeForm';

// import { AnalyticsContext } from '../Analytics';
import MetaTag from '../MetaTag';

const { Content } = Layout;

const TradeWatch = () => {
  // const { analytics } = React.useContext(AnalyticsContext); // Google Analytics

  // useEffect(() => {
  //   // Google Analytics
  //   analytics.send({ hitType: 'pageview', page: '/sell&trade/trade-watch' });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <>
      <MetaTag
        title="블루먼트 | Trade Watch"
        url="https://www.bluement.kr/sell&trade/trade-watch"
        keywords="rolex,롤렉스,중고롤렉스,롤렉스매입,롤렉스교환,명품시계교환,중고롤렉스매입,중고시계,중고시계매입,명품시계매입,블루먼트,bluement,cpo,certified pre-owned watch,pre-owned,preowned,preowned rolex,명품,명품시계,시계,거래,타임피스,거래 플랫폼"
      />
      <Layout>
        <Content className="trade-watch">
          <div className="section-start-gap"></div>
          <TradeProcess />
          <div className="section-gap"></div>

          <Divider />

          <div className="section-gap"></div>

          <div className="section-title form-title">교환 문의하기</div>

          {/* Trade 요청 form */}
          <TradeForm />

          <div className="section-gap"></div>
        </Content>
      </Layout>
    </>
  );
};

export default TradeWatch;
