import React, { useState, useRef } from 'react';
import {
  Layout,
  Typography,
  Checkbox,
  Form,
  Input,
  Button,
  Row,
  Col,
  Modal,
  // Radio,
  // Cascader,
  Select,
} from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesDown } from '@fortawesome/free-solid-svg-icons';

// import emailjs from '@emailjs/browser';
// import ReactGA from 'react-ga4';
// import moment from 'moment';

// import { API } from 'aws-amplify';
// import awsExports from '../aws-exports';

// Styles
import '../components/SellWatch/SellWatch.css';
import '../assets/styles/FormStyle.css';

// Components
import SellProcess from '../components/SellWatch/SellProcess';
import CircledCard from '../components/SellWatch/SellCircledCard';

// import { AnalyticsContext } from '../Analytics';
import MetaTag from '../MetaTag';

const { Content } = Layout;
const { TextArea } = Input;
const { Text } = Typography;

// API.configure(awsExports);

// const priceToString = (price) => {
//   return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
// };

// const PRICE_GAP = 0.2;

const TXT_REPLACE = {
  box: '시계 박스',
  'guide-paper': '설명서',
  'cosc-paper': 'COSC 인증설명서',
  paper: '보증서',
  'paper-holder': '보증서 홀더',
  'spair-link': 'Full 링크',
  seal: '씰',
};

const SellWatch = () => {
  const [form] = Form.useForm();
  const priceMin = useRef(null);
  const priceMax = useRef(null);
  const sellForm = useRef(null); // 스크롤을 위한 DOM ref
  const [boxPaperValues, setBoxPaperValues] = useState(null);
  const [message, setMessage] = useState('');
  const [displayPrice, setDisplayPrice] = useState(
    '레퍼런스 넘버와 다이얼 색상을 먼저 선택해주세요.'
  );
  // const [loadingPrice, setLoadingPrice] = useState(false);
  const [displayPriceTitle, setDisplayPriceTitle] = useState('예상 가격 : ');
  const [selectedModel, setSelectedModel] = useState('');
  const [selectedModelInfo, setSelectedModelInfo] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  // const { analytics } = React.useContext(AnalyticsContext); // Google Analytics
  const [selectOptions, setSelectOptions] = useState(null); // Cascader 옵션

  const [disable, setDisable] = useState(false);

  // const create_options = (rolexData) => {
  //   const options = [];

  //   if (rolexData.length > 0) {
  //     rolexData.forEach((model) => {
  //       // console.log("model: ", model);
  //       const modelObj = {
  //         label:
  //           model.refNo +
  //           ' (' +
  //           model.bracelet_display +
  //           ' ' +
  //           model.dial +
  //           ')',
  //         value: model.rmc,
  //       };

  //       options.push(modelObj);
  //     });
  //   }

  //   options.push({
  //     label: '기타',
  //     value: '기타',
  //   });

  //   setSelectOptions(options);
  // };

  React.useEffect(() => {
    // Google Analytics page view tracking
    // analytics.send({ hitType: 'pageview', page: '/sell&trade/sell-watch' });

    // Cascader 옵션 생성 위한 Rolex 전체 모델 불러오기
    // API.get('buyListApi', '/items').then((res) => {
    //   if (res) {
    //     create_options(res);
    //   }
    // });

    const selectOptionsDummy = [
      {
        label: '116500LN (Oysterflex Black)',
        value: '116500LN / Oysterflex / Black',
      },
      {
        label: '116500LN (Oysterflex White)',
        value: '116500LN / Oysterflex / White',
      },
      {
        label: '기타',
        value: '기타',
      },
    ];

    setSelectOptions(selectOptionsDummy);
  }, []);

  const onMoveToForm = () => {
    sellForm.current.scrollIntoView({ block: 'start' });
  };

  const handleModalOk = () => {
    // Send a custom event
    // ReactGA.event({
    //   category: 'modal',
    //   action: 'click',
    //   label: `sell-modal-ok`, // optional
    //   value: 6, // optional, must be a number
    //   nonInteraction: false, // optional, true/false
    // });
    setModalOpen(false);
    // setTimeout(() => {
    //   setModalOpen(false);
    // }, 2000);
  };

  const handleModalCancel = () => {
    // Send a custom event
    // ReactGA.event({
    //   category: 'modal',
    //   action: 'click',
    //   label: `sell-modal-close`, // optional
    //   value: 6, // optional, must be a number
    //   nonInteraction: false, // optional, true/false
    // });
    setModalOpen(false);
  };

  const onFinish = async (values) => {
    try {
      // const today = new Date();
      // const timestamp = parseInt(today.getTime());

      // const name = values.name;
      // const phone = values.phone;
      // const email = values.email;
      // let refNo = '기타';
      // let bracelet = '기타';
      // let dial = '기타';
      // let priceMinInt = 0;
      // let priceMaxInt = 0;
      // let priceRange = '없음';

      // Send a custom event
      // ReactGA.event({
      //   category: 'form',
      //   action: 'submit',
      //   label: `sell-form-submit`, // optional
      //   value: 5, // optional, must be a number
      //   nonInteraction: false, // optional, true/false
      // });

      setDisable(true);

      // 크롤링된 가격값이 있는 모델의 경우 (기타 모델 제외)
      // if (!values.model.includes('기타') && priceMin.current !== 0) {
      //   refNo = selectedModelInfo.ref_no;
      //   bracelet = selectedModelInfo.bracelet;
      //   dial = selectedModelInfo.dial;

      //   priceMinInt = parseInt(priceMin.current.replace(/,/gi, '')) * 10000;
      //   priceMaxInt = parseInt(priceMax.current.replace(/,/gi, '')) * 10000;
      //   priceRange = priceMin.current + ' ~ ' + priceMax.current;
      // } else if (priceMin.current === 0) {
      //   // 크롤링된 가격값이 없는 모델일 경우
      //   refNo = selectedModelInfo.ref_no;
      //   bracelet = selectedModelInfo.bracelet;
      //   dial = selectedModelInfo.dial;
      // }

      // const componentsList =
      //   boxPaperValues === null
      //     ? []
      //     : boxPaperValues.map((item) => TXT_REPLACE[item]).join('/');

      // const reqData = {
      //   body: {
      //     createdAt: timestamp,
      //     createdAtDate: moment
      //       .unix(timestamp / 1000)
      //       .format('YYYY-MM-DD HH:mm'),
      //     name: name,
      //     phone: phone,
      //     email: email,
      //     refNo: refNo,
      //     bracelet: bracelet,
      //     dial: dial,
      //     components: componentsList,
      //     priceMin: priceMinInt,
      //     priceMax: priceMaxInt,
      //     priceRange: priceRange,
      //     message: message,
      //     visitedAt: 0,
      //     visitedAtDate: '미정',
      //     source: '홈페이지',
      //   },
      // };

      // const emailContent = {
      //   name: name,
      //   phone: phone,
      //   email: email,
      //   refNo: refNo,
      //   bracelet: bracelet,
      //   dial: dial,
      //   components: componentsList,
      //   priceRange: priceRange,
      //   message: message,
      // };

      onReset();
      setModalOpen(true);
      setDisable(false);
      // setTimeout(() => {
      //   window.open(
      //     'https://booking.naver.com/booking/6/bizes/846736/items/5151469',
      //     '_blank',
      //     'noopener, noreferrer'
      //   );
      // }, 2000);

      // emailjs
      //   .send(
      //     'service_do57zih',
      //     'template_759qb2l',
      //     emailContent,
      //     '3zBvx43751ex39uxe'
      //   )
      //   .then(
      //     (result) => {
      //       // console.log(result.text);
      //       console.log('email sent successfully');

      //       onReset();
      //       setModalOpen(true);
      //       setDisable(false);
      //       setTimeout(() => {
      //         window.open(
      //           'https://booking.naver.com/booking/6/bizes/846736/items/5151469',
      //           '_blank',
      //           'noopener, noreferrer'
      //         );
      //       }, 2000);
      //     },
      //     (error) => {
      //       console.log('email send error: ');
      //       console.log(error.text);
      //     }
      //   );

      // return await API.post('sellInquiryHistoryApi', '/items', reqData)
      //   .then((res) => {
      //     // console.log(res);
      //   })
      //   .catch((err) => {
      //     console.error(
      //       'error creating/updating data to dynamoDB: ',
      //       err.response
      //     );
      //   });
    } catch (error) {
      console.error('error submitting form: ', error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Form Finish Failed: ', errorInfo);
  };

  // form 제출 후 reset
  const onReset = () => {
    form.resetFields();
    setDisplayPriceTitle('예상 가격 : ');
    setDisplayPrice('레퍼런스 넘버와 다이얼 색상을 먼저 선택해주세요.');
    setSelectedModelInfo(null);
    setSelectedModel('');
  };

  // Select의 clear 버튼 클릭 시
  const onSelectClear = () => {
    setSelectedModel('');
    setSelectedModelInfo(null);
    setDisplayPriceTitle('예상 가격 : ');
    setDisplayPrice('레퍼런스 넘버와 다이얼 색상을 먼저 선택해주세요.');
    priceMax.current = 0;
    priceMin.current = 0;
  };

  const onSelectModel = (value, option) => {
    // value: rmc, option: [label, value]

    // 모델 목록에서 '기타' 선택 시
    if (value.includes('기타')) {
      setSelectedModel(value);
      setDisplayPriceTitle('');
      setDisplayPrice(
        '모델 정보 확인을 위해 담당자가 연락드릴 예정입니다. 매입 가격은 매장 방문을 통해 상담 바랍니다.'
      );
      return;
    }

    // 모델 목록에서 '기타' 이외의 모델 선택 시
    setDisplayPriceTitle('예상 가격 : ');
    setDisplayPrice('가격 확인 중...');
    setSelectedModel(option.label);

    // API.get('bluementapi', `/items/${value}`)
    //   .then((res) => {
    //     const recentPriceObject = res[0];
    //     setSelectedModelInfo(recentPriceObject);
    //     const range_min = priceToString(
    //       Math.floor((recentPriceObject.avg * 0.8) / 10000)
    //     );
    //     const range_max = priceToString(
    //       Math.floor(recentPriceObject.avg / 10000)
    //     );
    //     priceMin.current = range_min;
    //     priceMax.current = range_max;
    //     setDisplayPrice(`${range_min} 만원 ~ ${range_max} 만원`);
    //   })
    //   .catch((err) => {
    //     console.error('error fetching price: ', err);
    //   });
  };

  const onBoxPaperChange = (checkedValues) => {
    setBoxPaperValues(checkedValues);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const box_paper_options = [
    { label: '시계 박스', value: 'box' },
    { label: '보증 카드', value: 'paper' },
    { label: '보증서 홀더', value: 'paper-holder' },
    { label: '사용설명서', value: 'guide-paper' },
    { label: 'COSC 인증설명서', value: 'cosc-paper' },
    { label: 'Full 링크', value: 'spair-link' },
    { label: 'Seal(씰)', value: 'seal' },
  ];

  // const onRefNoChange = async (value) => {
  //   // console.log('선택된 시계: ', value);

  //   if (value[0].includes('기타')) {
  //     setSelectedModel(value[0]);
  //     setDisplayPriceTitle('');
  //     setDisplayPrice(
  //       '모델 정보 확인을 위해 담당자가 연락드릴 예정입니다. 매입 가격은 매장 방문을 통해 상담 바랍니다.'
  //     );
  //     return;
  //   }

  //   setDisplayPriceTitle('예상 가격 : ');
  //   setDisplayPrice('가격 확인 중...');
  //   setSelectedModel(value[0] + '/' + value[1]);

  //   const dial = value[1];
  //   // .replace(/\b[a-z]/g, (char) => char.toUpperCase());

  //   const refNo = value[0].split('-')[0];
  //   const bracelet = value[0]
  //     .split('-')[1]
  //     .replace(/^[a-z]/, (char) => char.toUpperCase());
  //   const filterExpressionTxt =
  //     'ref_no = :refNo AND bracelet = :bracelet AND dial = :dial';

  //   API.get('bluementapi', '/items', {
  //     queryStringParameters: {
  //       refNo: refNo,
  //       dial: dial,
  //       bracelet: bracelet,
  //       filter: filterExpressionTxt,
  //     },
  //   })
  //     .then((res) => {
  //       // console.log('response: ', res);
  //       if (res === '') {
  //         setDisplayPriceTitle('');
  //         setDisplayPrice(
  //           '해당 시계의 매입 가격은 매장 방문을 통해 상담 바랍니다.'
  //         );
  //         priceMin.current = 0;
  //         priceMax.current = 0;
  //         return;
  //       }

  //       // console.log(res);

  //       const range_min = priceToString(Math.floor((res.avg * 0.8) / 10000));
  //       const range_max = priceToString(Math.floor(res.avg / 10000));
  //       priceMin.current = range_min;
  //       priceMax.current = range_max;
  //       setDisplayPrice(`${range_min} 만원 ~ ${range_max} 만원`);
  //     })
  //     .catch((err) => {
  //       console.error('error fetching price: ', err);
  //     });
  // };

  // const filter = (inputValue, path) =>
  //   path.some(
  //     (option) =>
  //       option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
  //   );

  return (
    <>
      <MetaTag
        title="블루먼트 | Sell Watch"
        url="https://www.bluement.kr/sell&trade/sell-watch"
        keywords="rolex,롤렉스,중고롤렉스,롤렉스매입,중고롤렉스매입,중고시계,중고시계매입,명품시계매입,블루먼트,bluement,cpo,certified pre-owned watch,pre-owned,preowned,preowned rolex,명품,명품시계,시계,거래,타임피스,거래 플랫폼"
      />
      <Layout>
        <Content>
          <div className="service-form-section">
            <SellProcess />

            <div className="section-gap"></div>

            <div className="btn-animated">
              <button onClick={onMoveToForm}>
                <FontAwesomeIcon
                  icon={faAnglesDown}
                  fade
                  className="btn-icon-left"
                />
                매입 문의하기
                <FontAwesomeIcon
                  icon={faAnglesDown}
                  fade
                  className="btn-icon-right"
                />
              </button>
            </div>

            <div className="section-gap"></div>

            <div className="section-w-bg">
              <div className="section-title">판매자를 위한 혜택</div>
              <div className="section-gap"></div>
              <CircledCard />
            </div>

            <div className="section-gap" ref={sellForm}></div>

            <Row justify="center" className="form-section-title">
              <Col span={18} className="text-center">
                <div className="section-title">매입 문의하기</div>
              </Col>
            </Row>

            <Row justify="end" className="form-section-title">
              <Col span={9} className="text-center">
                <div className="form-label">
                  <span className="essential">*</span>필수입력
                </div>
              </Col>
            </Row>

            <Form
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              requiredMark={true}
            >
              {/* 이름 */}
              <Row justify="center">
                <Col span={16}>
                  <Form.Item
                    label="이름"
                    labelCol={{ span: 24 }}
                    name="name"
                    rules={[
                      { required: true, message: '이름을 입력해주세요.' },
                    ]}
                  >
                    <Input placeholder="이름" />
                  </Form.Item>
                </Col>
              </Row>

              {/* 연락처 */}
              <Row justify="center">
                <Col span={16}>
                  <Form.Item
                    label="연락처"
                    labelCol={{ span: 24 }}
                    name="phone"
                    rules={[
                      { required: true, message: '연락처를 입력해주세요.' },
                    ]}
                  >
                    <Input placeholder="010-1234-5678" />
                  </Form.Item>
                </Col>
              </Row>

              {/* 이메일 */}
              <Row justify="center">
                <Col span={16}>
                  <Form.Item
                    label="이메일"
                    labelCol={{ span: 24 }}
                    name="email"
                    rules={[
                      { required: true, message: '이메일을 입력해주세요.' },
                    ]}
                  >
                    <Input placeholder="abc@gmail.com" />
                  </Form.Item>
                </Col>
              </Row>

              {/* 모델명 */}
              <Row justify="center">
                {/* Reference Number */}
                <Col span={16} className="refNo-form-col">
                  <Form.Item
                    className="refNo-form-item"
                    label="판매 희망 모델"
                    labelCol={{ span: 24 }}
                    name="model"
                    rules={[
                      {
                        required: true,
                        message: '레퍼런스 넘버와 다이얼 색상을 선택해주세요.',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      allowClear={true}
                      loading={selectOptions === null ? true : false}
                      disabled={selectOptions === null ? true : false}
                      placeholder="레퍼런스 넘버 및 다이얼 색상 선택 또는 검색"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={selectOptions}
                      onClear={onSelectClear}
                      onSelect={onSelectModel}
                    />
                  </Form.Item>
                  {/* <Form.Item
                    className="refNo-form-item"
                    label="레퍼런스 넘버 / 다이얼 색상"
                    labelCol={{ span: 24 }}
                    name="model"
                    rules={[
                      {
                        required: true,
                        message: '레퍼런스 넘버와 다이얼 색상을 선택해주세요.',
                      },
                    ]}
                  >
                    <Cascader
                      options={refNo_options}
                      // expandTrigger="hover"
                      allowClear={false}
                      showSearch={{ filter }}
                      placeholder="레퍼런스 넘버 및 다이얼 색상 선택"
                      onChange={onRefNoChange}
                      size="large"
                    />
                  </Form.Item> */}
                  {/* <div>
                    <Text type="secondary" className="expected-price-txt">
                      <span className="expected-price-txt-display-title">
                        {displayPriceTitle}
                      </span>
                      <span
                        className={
                          selectedModel.includes('기타')
                            ? 'expected-price-txt-display'
                            : 'expected-price-txt-display'
                        }
                      >
                        {displayPrice}
                      </span>
                    </Text>
                  </div> */}
                </Col>
              </Row>

              {/* 구성품 */}
              <Row justify="center">
                <Col span={16}>
                  <Form.Item
                    label="구성품"
                    labelCol={{ span: 24 }}
                    name="boxPaper"
                  >
                    <Checkbox.Group
                      name="boxPaperCheckGroup"
                      options={box_paper_options}
                      onChange={onBoxPaperChange}
                      // optionType="button"
                      // buttonStyle="solid"
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* 문의 내용 */}
              <Row justify="center">
                <Col span={16}>
                  <Form.Item
                    name="message"
                    label="요청사항"
                    labelCol={{ span: 24 }}
                  >
                    <TextArea
                      autoSize={{
                        minRows: 5,
                        maxRows: 10,
                      }}
                      value={message}
                      onChange={handleMessageChange}
                      placeholder={`자세히 작성하시면 상담에 큰 도움이 됩니다.`}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* 제출 버튼 */}
              <Row justify={'center'}>
                <Col span={16}>
                  <Form.Item>
                    <Button
                      className="form-btn-submit"
                      htmlType="submit"
                      // style={tailLayout}
                      disabled={disable}
                    >
                      제출 & 방문예약
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>

          {modalOpen ? (
            <Modal
              open={modalOpen}
              onOK={handleModalOk}
              onCancel={handleModalCancel}
              className="form-modal"
              title={
                <>
                  <CheckCircleFilled className="modal-title-icon" />
                  <span className="modal-title-txt">매입 문의 완료</span>
                </>
              }
              footer={[
                <Button
                  // key="naver"
                  // href="https://booking.naver.com/booking/6/bizes/846736"
                  type="primary"
                  onClick={handleModalOk}
                  className="modal-ok-btn"
                >
                  확인
                </Button>,
              ]}
            >
              <p>매입 문의가 접수되었습니다.</p>
              <p>
                시계 상태 확인 및 자세한 매입 상담을 위해서는{' '}
                <Text mark>매장 방문이 반드시 필요합니다.</Text>
              </p>
              <p>잠시 후, 방문 예약을 위해 네이버 예약 페이지로 이동합니다.</p>
              <p>
                <Text mark>원하시는 방문 날짜와 시간으로 예약 신청</Text>을
                해주시면, 담당자가 확인 후 예약을 확정해드립니다.
              </p>
            </Modal>
          ) : null}
          {/* </section> */}
          <div className="section-gap"></div>
        </Content>
      </Layout>
    </>
  );
};

export default SellWatch;
