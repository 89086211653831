import React from "react";
import { createRoot, hydrateRoot } from "react-dom/client";
import { ScrollRestoration } from "react-router-dom";
// import { hydrate}
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate,
} from "react-router-dom";

import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";

// Style
import "./index.css";
import "./index_responsive.css";

import { isMobile } from "react-device-detect";
import MediaQuery from "react-responsive";

// Layouts
import Navbar from "./components/Navbar/Navbar";
import Footers from "./components/Footer/Footers";
import FooterMobile from "./components/Footer/Footer-mobile";
// import Home from './layouts/Home';
import About from "./layouts/About";
import CPOIntro from "./layouts/CPOIntro";
import CPOpayback from "./layouts/CPOpayback";
import CompleteCare from "./layouts/CompleteCare";
import Inspection from "./layouts/Inspection";
import SellWatch from "./layouts/SellWatch";
import BuyWatch from "./layouts/BuyWatch";
import TradeWatch from "./layouts/TradeWatch";
import Bespoke from "./layouts/Bespoke";
import Collection from "./layouts/Collection";
import ProductDetails from "./layouts/ProductDetails";
// import Store from './layouts/Store';
import Support from "./layouts/Support";
import Privacy from "./layouts/Privacy";

import { AnalyticsProvider } from "./Analytics";
import { HelmetProvider } from "react-helmet-async";
import MetaTag from "./MetaTag";

Amplify.configure(awsExports);

const AppLayout = () => {
  return (
    <>
      <HelmetProvider>
        <MetaTag />
        <ScrollRestoration />
        <Navbar />
        <Outlet />

        {isMobile ? (
          <FooterMobile />
        ) : (
          <>
            <MediaQuery minWidth={1000}>
              <Footers />
            </MediaQuery>

            <MediaQuery maxWidth={999.98}>
              <FooterMobile />
            </MediaQuery>
          </>
        )}
      </HelmetProvider>
    </>
  );
};

const router = createBrowserRouter([
  {
    element: <AppLayout />,
    // errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <About />,
      },
      // {
      //   path: '/about',
      //   element: <About />,
      // },
      {
        path: "/apo",
        element: <Navigate to="/apo/bluement-apo" replace />,
      },
      {
        path: "/apo/bluement-apo",
        element: <CPOIntro />,
      },
      {
        path: "/apo/payback-program",
        element: <CPOpayback />,
      },
      {
        path: "/service",
        element: <Navigate to="/service/complete-care" replace />,
      },
      {
        path: "/service/complete-care",
        element: <CompleteCare />,
      },
      {
        path: "/service/inspection",
        element: <Inspection />,
      },
      {
        path: "/buy",
        element: <Navigate to="/buy/collection" replace />,
      },
      {
        path: "/buy/collection",
        element: <Collection />,
      },
      {
        path: "/buy/collection/:model",
        element: <Collection />,
      },
      {
        path: "/buy/collection/rolex/:productId",
        element: <ProductDetails />,
      },
      {
        path: "/buy/buy-watch",
        element: <BuyWatch />,
      },
      {
        path: "/buy/bespoke-order",
        element: <Bespoke />,
      },
      {
        path: "/sell&trade",
        element: <Navigate to="/sell&trade/sell-watch" replace />,
      },
      {
        path: "/sell&trade/sell-watch",
        element: <SellWatch />,
      },
      {
        path: "/sell&trade/trade-watch",
        element: <TradeWatch />,
      },
      {
        path: "/contact-us",
        element: <Support />,
      },
      {
        path: "/terms/privacy",
        element: <Privacy />,
      },
      {
        path: "*",
        element: <Navigate to="/" replace />,
      },
    ],
  },
]);

const rootContainer = document.getElementById("root");
if (rootContainer.hasChildNodes()) {
  hydrateRoot(
    rootContainer,
    <AnalyticsProvider>
      <RouterProvider router={router} />
    </AnalyticsProvider>
  );
} else {
  createRoot(rootContainer).render(
    <AnalyticsProvider>
      <RouterProvider router={router} />
    </AnalyticsProvider>
  );
}

// createRoot(document.getElementById('root')).render(
//   <AnalyticsProvider>
//     <RouterProvider router={router} />
//   </AnalyticsProvider>
// );
