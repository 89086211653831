import React from "react";
import { Layout } from "antd";
import MediaQuery from "react-responsive";

import { AnalyticsContext } from "../Analytics";
import MetaTag from "../MetaTag";

// Components
import IntroSection from "../components/Service/Inspection/IntroSection";
import Process from "../components/Service/Inspection/Process";
import Equipment from "../components/Service/Inspection/Equipment";

// Styles
import "../components/Service/Service.css";

const { Content } = Layout;

const Inspection = () => {
  const { analytics } = React.useContext(AnalyticsContext); // Google Analytics

  React.useEffect(() => {
    // Google Analytics
    analytics.send({ hitType: "pageview", page: "/service/inspection" });

    // 뷰포트 높이를 얻고 1%를 곱하여 vh 단위 값을 얻습니다.
    let vh = window.innerHeight * 0.01;
    // --vh 사용자 정의 속성의 값을 문서의 루트로 설정합니다.
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MetaTag
        title="블루먼트 | 진단 및 검증 서비스"
        url="https://www.bluement.kr/service/inspection"
        keywords="rolex,롤렉스,중고롤렉스,롤렉스수리,명품시계수리,시계수리,명품시계진단,시계진단,명품시계검증,시계검증,롤렉스매입,롤렉스교환,명품시계교환,중고롤렉스매입,중고시계,중고시계매입,명품시계매입,블루먼트,bluement,cpo,certified pre-owned watch,pre-owned,preowned,preowned rolex,명품,명품시계,시계,거래,타임피스,거래 플랫폼"
      />
      <Layout>
        <Content>
          <div className="top-video-container">
            <div className="top-video-content">
              <video className="video-player" autoPlay muted loop playsInline>
                <source
                  src="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/video/inspection-mixed.mp4"
                  type="video/mp4"
                />
              </video>

              <div className="player-overlay">
                <div className="player-overlay-content">
                  <div className="text-box">
                    <div className="title">
                      <MediaQuery minWidth={664}>
                        BLUEMENT INSPECTION SERVICE
                      </MediaQuery>
                      <MediaQuery maxWidth={663.98}>
                        INSPECTION SERVICE
                      </MediaQuery>
                    </div>
                    <div className="sub-title">
                      <MediaQuery minWidth={854}>
                        블루먼트는 워치메이커들의 전문적 지식과 최첨단 장비를
                        바탕으로 믿을 수 있는 서비스를 제공합니다.
                      </MediaQuery>
                      <MediaQuery minWidth={551} maxWidth={853.98}>
                        블루먼트는 워치메이커들의 전문적 지식과 최첨단 장비를
                        바탕으로
                        <br />
                        믿을 수 있는 서비스를 제공합니다.
                      </MediaQuery>
                      <MediaQuery maxWidth={550.98}>
                        블루먼트는 워치메이커들의 전문적 지식과
                        <br />
                        최첨단 장비를 바탕으로
                        <br />
                        믿을 수 있는 서비스를 제공합니다.
                      </MediaQuery>
                    </div>
                  </div>
                </div>

                <div className="arrow-down">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="16.143"
                    viewBox="0 0 28 16.143"
                  >
                    <path
                      id="down"
                      d="M18.636,23.231a1.226,1.226,0,0,1-1.8,0L4.11,9.806a1.4,1.4,0,0,1,.031-1.865,1.226,1.226,0,0,1,1.768-.033L17.737,20.385,29.565,7.909a1.231,1.231,0,0,1,1.246-.386,1.309,1.309,0,0,1,.918.969,1.391,1.391,0,0,1-.366,1.314L18.636,23.231Z"
                      transform="translate(-3.769 -7.481)"
                      fill="#fff"
                      fillRule="evenodd"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div className="inspection-layout">
            <div className="inspection-section">
              <IntroSection />
            </div>
          </div>

          <div className="inspection-section-bk-bg">
            <Equipment />
          </div>

          <div className="inspection-section-bg">
            <Process />
          </div>
        </Content>
      </Layout>
    </>
  );
};

export default Inspection;
