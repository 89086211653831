import React, { useState } from "react";
import Slider from "react-slick";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

import { API } from "aws-amplify";

import "./CollectionSlider.css";
import { Link } from "react-router-dom";

const BASE_URL_BG =
  "https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/collection/slider/400x/";

const CollectionSlider = () => {
  const [products, setProducts] = useState(null);
  const [isHover, setIsHover] = useState(false);
  const slider = React.useRef(null);

  React.useEffect(() => {
    API.get("bluementapi", "/product/ids").then((res) => {
      // const idx = res.findIndex((item) => item.pid === '023');
      // res.splice(idx, 1);
      const shuffledRes = res.sort(() => Math.random() - 0.5);
      setProducts(shuffledRes);
    });
  }, []);

  return (
    <>
      {products && (
        <section
          className="collection-slider-container"
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <Slider
            arrows={true}
            infinite={true}
            autoplay={true}
            speed={1000}
            ref={slider}
            // autoplaySpeed={1000}
            slidesToShow={5}
            slidesToScroll={1}
            swipe={true}
            swipeToSlide={true}
            draggable={true}
            prevArrow={
              <>
                <div
                  className={
                    isHover
                      ? "slider-icon-wrapper on-left"
                      : "slider-icon-wrapper on-left hidden"
                  }
                  onClick={() => slider?.current?.slickPrev()}
                >
                  <FontAwesomeIcon icon={faAngleLeft} />
                </div>
              </>
            }
            nextArrow={
              <>
                <div
                  className={
                    isHover
                      ? "slider-icon-wrapper on-right"
                      : "slider-icon-wrapper on-right hidden"
                  }
                  onClick={() => slider?.current?.slickNext()}
                >
                  <FontAwesomeIcon icon={faAngleRight} />
                </div>
              </>
            }
            cssEase="linear"
            responsive={[
              {
                breakpoint: 1240,
                settings: {
                  slidesToShow: 4,
                },
              },
              {
                breakpoint: 800,
                settings: {
                  slidesToShow: 3,
                },
              },
            ]}
          >
            {products.map((item) => (
              <>
                <div
                  className="collection-slider-card"
                  key={`slider-${item.pid}`}
                >
                  <Link to={`/buy/collection/rolex/sr-${item.pid}`}>
                    <picture>
                      <source
                        srcSet={`${BASE_URL_BG}SR-${item.pid}.webp`}
                        loading="lazy"
                        type="image/webp"
                      />
                      <source
                        srcSet={`${BASE_URL_BG}SR-${item.pid}.avif`}
                        loading="lazy"
                        type="image/avif"
                      />
                      <img
                        alt={item.model}
                        srcSet={`${BASE_URL_BG}SR-${item.pid}.jpg`}
                        loading="lazy"
                      />
                    </picture>
                  </Link>
                </div>
              </>
            ))}
          </Slider>
        </section>
      )}
    </>
  );
};

export default CollectionSlider;
