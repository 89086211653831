import React from "react";
import { Divider } from "antd";
import MediaQuery from "react-responsive";
import Slider from "react-slick";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

const IMG_BASE_URL =
  "https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/service/equipment/";

const EQUIPMENT_LIST = [
  "Workbench",
  "Tester",
  "Inspection & Adjustment",
  "Cleaning",
  "Watchmaking Tools",
  "Polishing",
];

const Equipment = () => {
  const slider = React.useRef(null);
  return (
    <div className="equipment-section-container">
      <div className="title">엔지니어링 장비</div>
      <div className="desc">
        최첨단 엔지니어링 장비의 설계로 최적의 서비스 품질을 제공합니다.
      </div>

      <MediaQuery minWidth={621}>
        <div className="equipment-grid">
          {EQUIPMENT_LIST.map((item, index) => (
            <div className="equipment-item" key={`equipment-${index}`}>
              <div className="equipment-item-name">
                <div className="name">{item}</div>
                <Divider className="border-bottom" />
              </div>
              <div className="equipment-item-img">
                <picture>
                  <source
                    srcSet={`${IMG_BASE_URL}equipment-0${index + 1}.webp`}
                    type="image/webp"
                  />
                  <img
                    srcSet={`${IMG_BASE_URL}equipment-0${index + 1}.jpg`}
                    alt={item}
                  />
                </picture>
              </div>
            </div>
          ))}
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={620.98}>
        <div className="equipment-slider">
          <Slider
            arrows={true}
            infinite={true}
            speed={1000}
            slidesToShow={1}
            slidesToScroll={1}
            swipe={true}
            swipeToSlide={true}
            draggable={true}
            ref={slider}
            prevArrow={
              <>
                <div
                  className="slider-icon-wrapper on-left"
                  onClick={() => slider?.current?.slickPrev()}
                >
                  <FontAwesomeIcon icon={faAngleLeft} className="arrow-icon" />
                </div>
              </>
            }
            nextArrow={
              <>
                <div
                  className="slider-icon-wrapper on-right"
                  onClick={() => slider?.current?.slickNext()}
                >
                  <FontAwesomeIcon icon={faAngleRight} className="arrow-icon" />
                </div>
              </>
            }
            // cssEase="linear"
          >
            {EQUIPMENT_LIST.map((item, index) => (
              <div
                className="equipment-slider-item"
                key={`equipment-slider-${index}`}
              >
                <div className="equipment-slider-item-img">
                  <picture>
                    <source
                      srcSet={`${IMG_BASE_URL}equipment-0${index + 1}.webp`}
                      type="image/webp"
                    />
                    <img
                      srcSet={`${IMG_BASE_URL}equipment-0${index + 1}.jpg`}
                      alt={item}
                    />
                  </picture>
                </div>
                <div className="equipment-slider-item-name">{item}</div>
              </div>
            ))}
          </Slider>
        </div>
      </MediaQuery>
    </div>
  );
};

export default Equipment;
