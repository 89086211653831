import React from "react";
import { Row, Col } from "antd";

import "./CPOBanner.css";

const CPOBanner = () => {
  return (
    <div className="cpo-banner-container">
      <Row justify={"center"}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          xxl={12}
          className="cpo-banner__left"
        >
          <picture>
            <source
              srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/cpo/cpo-banner.webp"
              type="image/webp"
            />
            <source
              srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/cpo/cpo-banner.avif"
              type="image/avif"
            />
            <img
              srcSet={
                "https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/cpo/cpo-banner.jpg"
              }
              alt="CPO_engineer"
            />
          </picture>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          xxl={12}
          className="cpo-banner__right"
        >
          <div className="text-box">
            <div className="title">About APO</div>
            <div className="sub-title">
              Approved Pre-Owned Watch by BLUEMENT Experts
            </div>

            <div className="description">
              블루먼트 인증시계는 전문 워치메이커가 진단하고, 블루먼트의 엄격한
              품질 기준을 적용하여 외관 품질 및 시계 성능까지 보증하는
              시계입니다. 블루먼트 인증시계는 구매일로부터 2년간의 보증 기간이
              제공됩니다.
            </div>

            <div className="description-point">
              블루먼트 인증마크는 국내 유일의 다른 누구도 진입할 수 없는 수준의
              전문성과 기술적 우수성으로 명품 시계를 인증합니다.
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CPOBanner;
