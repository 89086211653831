import React from "react";
import { Layout, Row, Col } from "antd";
import MediaQuery from "react-responsive";

import "../../assets/styles/Process.css";

const { Content } = Layout;

const SellProcess = () => {
  return (
    <>
      <Layout>
        <Content>
          <div className="section-container">
            <div className="title">
              <div className="section-title">
                {/* <span className="section-title__point">BLUEMENT</span>에서
                안전하게 판매하기 */}
                <MediaQuery maxWidth={649.99}>
                  BLUEMENT에서
                  <br />
                  안전하게 판매하기
                </MediaQuery>
                <MediaQuery minWidth={650}>
                  BLUEMENT에서 안전하게 판매하기
                </MediaQuery>
              </div>
              <div className="section-sub-title">
                <MediaQuery maxWidth={649.99}>
                  BLUEMENT 부티크에서 전문가에게
                  <br /> 진단받고, 안전하게 판매하세요.
                </MediaQuery>
                <MediaQuery minWidth={650}>
                  BLUEMENT 부티크에서 전문가에게 진단받고, 안전하게 판매하세요.
                </MediaQuery>
              </div>
            </div>

            <Row
              justify="center"
              className="process-container"
              gutter={[
                { xs: 16, sm: 16, md: 30, lg: 50, xl: 50, xxl: 70 },
                { xs: 70, sm: 70, md: 70, lg: 70, xl: 70, xxl: 70 },
              ]}
            >
              <Col span={6} xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                <div className="process-box">
                  <div className="process-icon">
                    <picture>
                      <source
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/email.webp"
                        type="image/webp"
                        loading="lazy"
                      />
                      <source
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/email.avif"
                        type="image/avif"
                        loading="lazy"
                      />
                      <img
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/email.png"
                        alt="상담신청 & 방문"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                  <div className="process-content">
                    <div className="process-no">1단계</div>
                    <div className="process-title">상담신청 & 방문</div>
                    <div className="process-desc">
                      하단 양식에서 예상 가격을 확인해보세요. 양식 제출 후
                      네이버 예약으로 방문 예약하시면 됩니다.
                    </div>
                  </div>
                </div>
              </Col>

              <Col span={6} xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                <div className="process-box">
                  <div className="process-icon">
                    <picture>
                      <source
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/communication.webp"
                        type="image/webp"
                        loading="lazy"
                      />
                      <source
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/communication.avif"
                        type="image/avif"
                        loading="lazy"
                      />
                      <img
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/communication.png"
                        alt="상담 진행"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                  <div className="process-content">
                    <div className="process-no">2단계</div>
                    <div className="process-title">상담 진행</div>
                    <div className="process-desc">
                      예약 시간에 부티크로 방문해주세요. 큐레이터가 상담을
                      시작합니다.
                    </div>
                  </div>
                </div>
              </Col>

              <Col span={6} xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                <div className="process-box">
                  <div className="process-icon">
                    <picture>
                      <source
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/inspection.webp"
                        type="image/webp"
                        loading="lazy"
                      />
                      <source
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/inspection.avif"
                        type="image/avif"
                        loading="lazy"
                      />
                      <img
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/inspection.png"
                        alt="시계 진단 및 점검"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                  <div className="process-content">
                    <div className="process-no">3단계</div>
                    <div className="process-title">시계 진단 및 점검</div>
                    <div className="process-desc">
                      전문 워치 메이커에게 시계의 진단과 점검을 맡겨주세요. 현재
                      시계 상태를 정확히 알 수 있습니다.
                    </div>
                  </div>
                </div>
              </Col>

              <Col span={6} xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                <div className="process-box">
                  <div className="process-icon">
                    <picture>
                      <source
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/wallet.webp"
                        type="image/webp"
                        loading="lazy"
                      />
                      <source
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/wallet.avif"
                        type="image/avif"
                        loading="lazy"
                      />
                      <img
                        srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/icon/wallet.png"
                        alt="매입가 확인 및 입금"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                  <div className="process-content">
                    <div className="process-no">4단계</div>
                    <div className="process-title">매입가 확인 및 입금</div>
                    <div className="process-desc">
                      판매여부를 결정해주세요. 거래 체결 후 신속하게 입금됩니다.
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Content>
      </Layout>
    </>
  );
};

export default SellProcess;
