import React from "react";
import { Row, Col } from "antd";
// import MediaQuery from 'react-responsive';

// import './BuyCircledCard.css';
import "../../assets/styles/CircledCard.css";

const BuyCircledCard = () => {
  return (
    <>
      <Row
        justify="space-around"
        className="circledCard-container"
        gutter={[0, 100]}
      >
        <Col xs={24} sm={24} md={24} lg={7} xl={7} xxl={7}>
          <div className="circledCard">
            <div className="circledCard-thumbnail">
              <picture>
                <source
                  srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/benefit/buy01-1.webp"
                  type="image/webp"
                  loading="lazy"
                />
                <source
                  srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/benefit/buy01-1.avif"
                  type="image/avif"
                  loading="lazy"
                />
                <img
                  srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/benefit/buy01-1.jpg"
                  alt="가품에 대한 300% 보상"
                  loading="lazy"
                />
              </picture>
            </div>

            <div className="circledCard-content">
              <div className="circledCard-title card-title">
                가품에 대한
                <br />
                300% 보상
              </div>
              <div className="circledCard-desc card-desc">
                블루먼트에서 인증된 모든 상품은 정품임을 보증하며, 가품일 경우
                구매가의 300%를 보상합니다.
              </div>
            </div>
          </div>
        </Col>

        <Col xs={24} sm={24} md={24} lg={7} xl={7} xxl={7}>
          <div className="circledCard">
            <div className="circledCard-thumbnail">
              <picture>
                <source
                  srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/benefit/buy02-1.webp"
                  type="image/webp"
                  loading="lazy"
                />
                <source
                  srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/benefit/buy02-1.avif"
                  type="image/avif"
                  loading="lazy"
                />
                <img
                  srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/benefit/buy02-1.jpg"
                  alt="할인 혜택 및 추가 서비스 제공"
                  loading="lazy"
                />
              </picture>
            </div>

            <div className="circledCard-content">
              <div className="circledCard-title card-title">
                할인 혜택 및
                <br />
                추가 서비스 제공
              </div>
              <div className="circledCard-desc card-desc">
                구매 수량별 할인을 더한 쇼핑 기회를 제공합니다. 구매에 따른
                폴리싱 서비스도 받을 수 있습니다.
              </div>
            </div>
          </div>
        </Col>

        <Col xs={24} sm={24} md={24} lg={7} xl={7} xxl={7}>
          <div className="circledCard">
            <div className="circledCard-thumbnail">
              <picture>
                <source
                  srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/benefit/buy03-1.webp"
                  type="image/webp"
                  loading="lazy"
                />
                <source
                  srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/benefit/buy03-1.avif"
                  type="image/avif"
                  loading="lazy"
                />
                <img
                  srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/benefit/buy03-1.jpg"
                  alt="블루먼트 케어 서비스"
                  loading="lazy"
                />
              </picture>
            </div>

            <div className="circledCard-content">
              <div className="circledCard-title card-title">
                블루먼트
                <br />
                케어 서비스
              </div>
              <div className="circledCard-desc card-desc">
                2년간 연 1회 무상으로 정밀진단 및 생활 컨디션 체크 서비스를 받아
                시계를 최상의 상태로 유지할 수 있습니다.
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default BuyCircledCard;
