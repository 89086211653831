import React, { useEffect } from "react";
import { Layout, Divider, Collapse, Spin } from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";

import { useParams, Link } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { AnalyticsContext } from "../Analytics";
import MetaTag from "../MetaTag";

import "../assets/styles/ProductDetails.css";
import cpoMark from "../assets/svg/apo_black.svg";
import ContactBanner from "../components/CommonSection/ContactBanner/ContactBanner";
import orbitvuIcon from "../assets/svg/360_icon.svg";

import { API } from "aws-amplify";

const { Content } = Layout;
const { Panel } = Collapse;

const MODEL_KR = {
  daytona: "데이토나",
  "datejust 31": "데이트저스트",
  "datejust 36": "데이트저스트",
  "datejust 41": "데이트저스트",
  "lady datejust": "레이디-데이트저스트",
  submariner: "서브마리너",
  "submariner date": "서브마리너 데이트",
  "gmt-master ii": "GMT-Master II",
  "sky dweller": "스카이-드웰러",
  "oyster perpetual": "오이스터 퍼페츄얼",
  "explorer ii": "익스플로러 II",
};

const MODEL_ROUTE = {
  daytona: "daytona",
  "datejust 31": "datejust",
  "datejust 36": "datejust",
  "datejust 41": "datejust",
  "lady datejust": "lady-datejust",
  submariner: "submariner",
  "submariner date": "submariner-date",
  "gmt-master ii": "gmt-master-ii",
  "sky dweller": "sky-dweller",
  "oyster perpetual": "oyster-perpetual",
  "explorer ii": "explorer-ii",
};

const BASE_URL_BG =
  "https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/collection/thumbnail/1000x/";

const BASE_URL_THUMB =
  "https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/collection/thumbnail/100x/";

const ProductDetails = () => {
  const { analytics } = React.useContext(AnalyticsContext); // Google Analytics
  const params = useParams();
  const [itemDetails, setItemDetails] = React.useState(null);
  const slider = React.useRef(null);

  const pidNum2 = params.productId.split("-")[1];

  const pagingThumb = (index) => {
    if (index === 1) {
      return <img srcSet={orbitvuIcon} alt="product-thumb" />;
    }
    return (
      <img
        srcSet={`${BASE_URL_THUMB}SR-${itemDetails.pid}_white.jpg`}
        alt="product-thumb"
      />
    );
  };

  useEffect(() => {
    // Google Analytics
    analytics.send({
      hitType: "pageview",
      page: `/buy/collection/rolex/${params.productId}`,
    });

    API.get("bluementapi", `/product/item/${pidNum2}`).then((res) => {
      // console.log(res);
      setItemDetails(res);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {itemDetails ? (
        <MetaTag
          title={`블루먼트 | ${itemDetails.model} ${itemDetails.refNo}`}
          url={`https://www.bluement.kr/buy/collection/rolex/${params.productId}`}
          keywords="rolex,롤렉스,중고롤렉스,롤렉스매입,롤렉스맞춤형매입,명품시계맞춤형매입,중고롤렉스매입,중고시계,중고시계매입,명품시계매입,블루먼트,bluement,cpo,certified pre-owned watch,pre-owned,preowned,preowned rolex,명품,명품시계,시계,거래,타임피스,거래 플랫폼"
        />
      ) : null}

      <Layout>
        <Content>
          {itemDetails !== null ? (
            <div className="product-detail-section">
              <Slider
                dots={true}
                dotsClass="slick-dots slick-thumb"
                infinite={true}
                speed={500}
                slidesToShow={1}
                slidesToScroll={1}
                ref={slider}
                arrows={true}
                customPaging={pagingThumb}
                prevArrow={
                  <>
                    <div
                      className="icon-wrapper on-left"
                      onClick={() => slider?.current?.slickPrev()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="80"
                        height="80"
                        viewBox="0 0 80 80"
                      >
                        <g id="botton_left" transform="translate(-290 -559)">
                          <rect
                            id="left_box"
                            width="80"
                            height="80"
                            transform="translate(290 559)"
                            fill="none"
                          />
                          <path
                            id="left.svg"
                            d="M13.751,7.785a.972.972,0,0,1,1.374,0l9.722,9.722a.972.972,0,1,1-1.374,1.374L14.438,9.846,5.4,18.881A.972.972,0,0,1,4.03,17.507l9.722-9.722Z"
                            transform="translate(316.654 613.462) rotate(-90)"
                            fill="#aaa"
                            fillRule="evenodd"
                          />
                        </g>
                      </svg>
                    </div>
                  </>
                }
                nextArrow={
                  <>
                    <div
                      className="icon-wrapper on-right"
                      onClick={() => slider?.current?.slickNext()}
                    >
                      <svg
                        id="botton_right"
                        xmlns="http://www.w3.org/2000/svg"
                        width="80"
                        height="80"
                        viewBox="0 0 80 80"
                      >
                        <rect
                          id="right_box"
                          width="80"
                          height="80"
                          fill="none"
                        />
                        <path
                          id="right.svg"
                          d="M13.751,7.785a.972.972,0,0,1,1.374,0l9.722,9.722a.972.972,0,1,1-1.374,1.374L14.438,9.846,5.4,18.881A.972.972,0,0,1,4.03,17.507l9.722-9.722Z"
                          transform="translate(53.346 25.538) rotate(90)"
                          fill="#333"
                          fillRule="evenodd"
                        />
                      </svg>
                    </div>
                  </>
                }
                className="product-detail__img"
              >
                <div className="slider-item">
                  <div className="cpo-mark">
                    <img src={cpoMark} alt="product" />
                  </div>
                  <picture className="product-img">
                    <source
                      srcSet={`${BASE_URL_BG}SR-${itemDetails.pid}_white.webp`}
                      type="image/webp"
                    />
                    <source
                      srcSet={`${BASE_URL_BG}SR-${itemDetails.pid}_white.avif`}
                      type="image/avif"
                    />
                    <img
                      srcSet={`${BASE_URL_BG}SR-${itemDetails.pid}_white.jpg`}
                      alt="product-thumb"
                    />
                  </picture>
                </div>

                <div className="slider-item">
                  <iframe
                    src={itemDetails.orbitvu}
                    width="650"
                    height="650"
                    scrolling="no"
                    marginWidth="0"
                    marginHeight="0"
                    frameBorder="0"
                    vspace="0"
                    hspace="0"
                    allow="fullscreen"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                    title="360 viewer"
                  >
                    Could not load 360 viewer.
                    <br />
                    Contact to support@bluebase.kr
                  </iframe>
                </div>
              </Slider>

              <div className="product-detail__details-container">
                <div className="product-detail__details">
                  <div className="top-section">
                    <div className="brand">{itemDetails.brand}</div>
                    <div className="brand"></div>
                    <div className="model">{itemDetails.model}</div>
                    <div className="refNo">{itemDetails.refNo}</div>

                    <div className="keywords">
                      {itemDetails.braceletType}
                      <span>|</span>
                      {itemDetails.dial}
                    </div>

                    <div className="description">
                      {itemDetails.description
                        .split("\n")
                        .map((line, index) => {
                          if (line.includes("인증중고")) {
                            return (
                              <>
                                <Link
                                  to="/approved-secondhand/payback-program"
                                  target="_blank"
                                >
                                  <strong>{line}</strong>
                                </Link>
                                <br /> <br />
                              </>
                            );
                          } else if (line.includes(`\\`)) {
                            const lightLine = line.split(`\\`);
                            return (
                              <>
                                {lightLine[0]} <br /> {lightLine[1]} <br />{" "}
                                <br />
                              </>
                            );
                          } else if (
                            index ===
                            itemDetails.description.split("\n").length - 1
                          ) {
                            return <>{line}</>;
                          } else {
                            return (
                              <>
                                {line} <br /> <br />
                              </>
                            );
                          }
                        })}
                    </div>

                    <div className="more">
                      <Link
                        to={`/buy/collection/${
                          MODEL_ROUTE[itemDetails.model.toLowerCase()]
                        }`}
                      >
                        {MODEL_KR[itemDetails.model.toLowerCase()]} 더보기
                      </Link>
                    </div>

                    <div className="bluement-guaranteed">
                      <div className="flex-row item">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <defs>
                            <clipPath id="clip-path">
                              <rect
                                id="사각형_94"
                                dataname="사각형 94"
                                width="24"
                                height="24"
                                fill="none"
                              />
                            </clipPath>
                          </defs>
                          <g id="certified.svg" transform="translate(0 0)">
                            <g
                              id="그룹_134"
                              dataname="그룹 134"
                              transform="translate(0 0)"
                              clipPath="url(#clip-path)"
                            >
                              <path
                                id="패스_395"
                                dataname="패스 395"
                                d="M32.861,26.917a.731.731,0,0,0-1.024.137l-4.1,5.375-2.545-3.124a.73.73,0,1,0-1.133.923L27,33.846a1.047,1.047,0,0,0,.72.4h.061a.818.818,0,0,0,.183-.028.719.719,0,0,0,.138-.055,1.318,1.318,0,0,0,.434-.385L33,27.941a.73.73,0,0,0-.137-1.024"
                                transform="translate(-16.52 -18.509)"
                                fill="#262626"
                              />
                              <path
                                id="패스_396"
                                dataname="패스 396"
                                d="M23.8,14.2l-1.22-1.984a.286.286,0,0,1,0-.293L23.73,9.9a1.35,1.35,0,0,0-.525-1.852L21.16,6.925a.287.287,0,0,1-.15-.254l.013-2.337a1.351,1.351,0,0,0-1.35-1.357h-.007l-2.337.013h0a.287.287,0,0,1-.252-.149L15.954.795A1.35,1.35,0,0,0,14.1.27L12.073,1.426a.287.287,0,0,1-.293,0L9.8.2A1.35,1.35,0,0,0,7.921.672L6.75,2.688a.29.29,0,0,1-.253.143L4.168,2.8A1.35,1.35,0,0,0,2.8,4.168L2.832,6.5a.288.288,0,0,1-.143.252L.673,7.921A1.35,1.35,0,0,0,.2,9.8l1.22,1.983a.288.288,0,0,1,0,.293L.27,14.1A1.35,1.35,0,0,0,.8,15.954l2.046,1.121a.286.286,0,0,1,.149.253l-.013,2.337a1.34,1.34,0,0,0,.4.962h0a1.341,1.341,0,0,0,.955.4h.007l2.337-.013h0a.287.287,0,0,1,.252.15L8.047,23.2A1.351,1.351,0,0,0,9.9,23.73l2.029-1.156a.288.288,0,0,1,.293,0L14.2,23.8a1.337,1.337,0,0,0,.7.2,1.357,1.357,0,0,0,.332-.041,1.34,1.34,0,0,0,.839-.631l1.171-2.016a.294.294,0,0,1,.253-.143l2.329.033A1.35,1.35,0,0,0,21.2,19.832L21.169,17.5a.288.288,0,0,1,.143-.253l2.016-1.171A1.35,1.35,0,0,0,23.8,14.2m-.653.833a.51.51,0,0,1-.239.318l-2.016,1.171a1.125,1.125,0,0,0-.56.988l.033,2.329a.5.5,0,0,1-.15.37.508.508,0,0,1-.37.15h-.089l.4-.359h0l-.4.359-2.24-.031a1.122,1.122,0,0,0-.989.559l-1.171,2.016a.513.513,0,0,1-.712.179l-1.984-1.22a1.124,1.124,0,0,0-1.146-.019L9.485,23a.512.512,0,0,1-.7-.2L7.66,20.757a1.118,1.118,0,0,0-.993-.584l-2.337.012a.517.517,0,0,1-.366-.15.509.509,0,0,1-.15-.366l.013-2.337a1.117,1.117,0,0,0-.329-.8,1.129,1.129,0,0,0-.255-.191L1.2,15.219a.513.513,0,0,1-.2-.7l1.156-2.03a1.124,1.124,0,0,0-.019-1.146L.914,9.357a.513.513,0,0,1,.179-.712L3.11,7.473a1.127,1.127,0,0,0,.56-.989L3.636,4.156a.513.513,0,0,1,.52-.52l2.329.033a1.126,1.126,0,0,0,.988-.56L8.645,1.093A.513.513,0,0,1,9.357.914l1.983,1.22a1.125,1.125,0,0,0,1.146.019L14.516,1a.512.512,0,0,1,.7.2L16.34,3.243a1.123,1.123,0,0,0,.986.584h.006l2.337-.013h0a.513.513,0,0,1,.513.516l-.013,2.337a1.125,1.125,0,0,0,.585.993L22.8,8.78a.513.513,0,0,1,.2.7l-1.156,2.029a1.124,1.124,0,0,0,.019,1.146l1.22,1.984a.509.509,0,0,1,.06.394"
                                transform="translate(0 0)"
                                fill="#262626"
                              />
                            </g>
                          </g>
                        </svg>
                        <div className="text">정품 인증</div>
                      </div>

                      <div className="flex-row item">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <defs>
                            <clipPath id="clip-path">
                              <rect
                                id="사각형_95"
                                dataname="사각형 95"
                                width="24"
                                height="24"
                                fill="none"
                              />
                            </clipPath>
                          </defs>
                          <g id="warranty.svg" transform="translate(0 0.094)">
                            <g
                              id="그룹_136"
                              dataname="그룹 136"
                              transform="translate(0 -0.094)"
                              clipPath="url(#clip-path)"
                            >
                              <path
                                id="패스_397"
                                dataname="패스 397"
                                d="M23.776,2.715a.541.541,0,0,0-.368-.13L21.274,2.6A16.4,16.4,0,0,1,13.358.691L12.227.069a.588.588,0,0,0-.553,0l-1.088.6A16.373,16.373,0,0,1,2.637,2.6L.494,2.585a.547.547,0,0,0-.368.13A.347.347,0,0,0,0,3.023L.7,8a20.2,20.2,0,0,0,3.485,8.71,26.631,26.631,0,0,0,7.45,7.108.582.582,0,0,0,.614,0l.006,0h0a26.628,26.628,0,0,0,7.447-7.1A20.2,20.2,0,0,0,23.194,8l.7-4.981a.347.347,0,0,0-.123-.308m-1.554,5.2a19.523,19.523,0,0,1-3.37,8.41A25.633,25.633,0,0,1,11.947,23a25.627,25.627,0,0,1-6.9-6.676A19.517,19.517,0,0,1,1.676,7.908L1.037,3.376l1.59.013A17.527,17.527,0,0,0,11.14,1.32L11.952.87l.856.47a17.3,17.3,0,0,0,8.476,2.049l1.581-.013Z"
                                transform="translate(0 0.098)"
                                fill="#262626"
                              />
                              <path
                                id="패스_398"
                                dataname="패스 398"
                                d="M32.279,23.7a.765.765,0,0,0-1.072.144l-4.3,5.629L24.244,26.2a.765.765,0,0,0-1.186.966l3.086,3.789a1.094,1.094,0,0,0,.753.422h.064a.834.834,0,0,0,.192-.029.776.776,0,0,0,.145-.057,1.379,1.379,0,0,0,.455-.4l4.67-6.116a.765.765,0,0,0-.144-1.072"
                                transform="translate(-15.782 -16.136)"
                                fill="#262626"
                              />
                            </g>
                          </g>
                        </svg>
                        <div className="text">성능, 품질보증 (2년)</div>
                      </div>
                    </div>

                    <div className="price">
                      {itemDetails.price
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      원
                    </div>

                    <div className="square-btn-lg">
                      <a href="/" target="_blank" rel="noreferrer">
                        <button>매장 방문 예약</button>
                      </a>
                    </div>
                  </div>

                  <Divider className="divider" />

                  <div className="bottom-section">
                    <Collapse
                      bordered={false}
                      defaultActiveKey={["watch-details"]}
                      expandIcon={({ isActive }) =>
                        isActive ? (
                          <FontAwesomeIcon icon={faAngleUp} />
                        ) : (
                          <FontAwesomeIcon icon={faAngleDown} />
                        )
                      }
                      expandIconPosition="end"
                    >
                      <Panel header="상세정보" key="watch-details">
                        <div className="watch-details">
                          <div>SKU : {itemDetails.stockNo}</div>
                          <div>모델번호 : {itemDetails.refNo}</div>
                          <div>브레슬릿 : {itemDetails.braceletType}</div>

                          <div className="important-info">
                            <div>
                              <span className="text-bold">스탬핑 년도</span> :{" "}
                              {new Date(
                                itemDetails.certificateDate * 1000
                              ).getFullYear()}
                            </div>
                            {/* <div>
                              <span className="text-bold">
                                정품 보증서 및 구성품
                              </span>{' '}
                              : {itemDetails.watchComponents}
                            </div>
                            <div>
                              <span className="text-bold">컨디션</span> :{' '}
                              {itemDetails.watchCondition}
                            </div> */}
                          </div>
                        </div>
                      </Panel>
                      <Panel header="케이스 & 다이얼" key="case-dial">
                        <div>
                          <div>케이스 : {itemDetails.case}</div>
                          <div>베젤 : {itemDetails.bezel}</div>
                          <div>사이즈 : {itemDetails.size}mm</div>
                          <div>다이얼 : {itemDetails.dial}</div>
                          <div>크리스탈 : 사파이어 크리스탈</div>
                          <div>방수 : {itemDetails.waterResistance}m</div>
                        </div>
                      </Panel>
                      <Panel header="기능" key="functional">
                        <div>
                          <div>무브먼트 : 셀프와인딩</div>
                          <div>
                            파워리저브 : 약 {itemDetails.powerReserve} 시간
                          </div>
                          <div>기능 : {itemDetails.functional}</div>
                        </div>
                      </Panel>
                      <Panel header="브레슬릿" key="bracelet">
                        <div>
                          {/* <div>브레슬릿 종류 : {itemDetails.braceletType}</div> */}
                          <div>소재 : {itemDetails.braceletMaterial}</div>
                          <div>버클 유형 : {itemDetails.buckle}</div>
                          <div>링크 : Full</div>
                        </div>
                      </Panel>
                    </Collapse>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="loading-container">
              <Spin size="large" />
            </div>
          )}

          <ContactBanner />
        </Content>
      </Layout>
    </>
  );
};

export default ProductDetails;
