import React from "react";
import { Layout } from "antd";

import { AnalyticsContext } from "../Analytics";
import MetaTag from "../MetaTag";

// Components
import IntroSection from "../components/Service/CompleteCare/IntroSection";
import Process from "../components/Service/CompleteCare/Process";

// Styles
import "../components/Service/Service.css";

const { Content } = Layout;

const BASE_URL_BG =
  "https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/service/top-image/complete-care/complete-care_";

const CompleteCare = () => {
  const { analytics } = React.useContext(AnalyticsContext); // Google Analytics

  React.useEffect(() => {
    // Google Analytics
    analytics.send({ hitType: "pageview", page: "/service/complete-care" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MetaTag
        title="블루먼트 | 종합 관리 서비스"
        url="https://www.bluement.kr/service/complete-care"
        keywords="rolex,롤렉스,중고롤렉스,롤렉스수리,명품시계수리,시계수리,롤렉스매입,롤렉스교환,명품시계교환,중고롤렉스매입,중고시계,중고시계매입,명품시계매입,블루먼트,bluement,cpo,certified pre-owned watch,pre-owned,preowned,preowned rolex,명품,명품시계,시계,거래,타임피스,거래 플랫폼"
      />
      <Layout>
        <Content>
          <div className="top-image-container">
            <div className="top-image-content">
              <picture>
                <source
                  srcSet={`${BASE_URL_BG}3840px.webp 3840w, 
                ${BASE_URL_BG}1920px.webp 1920w, 
                ${BASE_URL_BG}1536px.webp 1536w, 
                ${BASE_URL_BG}1152px.webp 1152w, 
                ${BASE_URL_BG}768px.webp 768w, 
                ${BASE_URL_BG}576px.webp 576w, 
                ${BASE_URL_BG}384px.webp 384w`}
                  alt="complete-care-service-top"
                  type="image/webp"
                />
                <img
                  srcSet={`${BASE_URL_BG}3840px.jpg 3840w, 
                ${BASE_URL_BG}1920px.jpg 1920w, 
                ${BASE_URL_BG}1536px.jpg 1536w, 
                ${BASE_URL_BG}1152px.jpg 1152w, 
                ${BASE_URL_BG}768px.jpg 768w, 
                ${BASE_URL_BG}576px.jpg 576w, 
                ${BASE_URL_BG}384px.jpg 384w`}
                  alt="complete-care-service-top"
                />
              </picture>
            </div>
          </div>

          <div className="complete-care-layout">
            <div className="complete-care-section">
              <IntroSection />
            </div>
          </div>

          <div className="complete-care-section-bg">
            <Process />
          </div>
        </Content>
      </Layout>
    </>
  );
};

export default CompleteCare;
