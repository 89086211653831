import React, { useState } from 'react';
import { Form, Row, Col, Input, Typography, Button, Modal } from 'antd';
import emailjs from '@emailjs/browser';
import ReactGA from 'react-ga4';

import { CheckCircleFilled } from '@ant-design/icons';

import './ContactForm.css';

const { Title, Text } = Typography;
const { TextArea } = Input;

const ContactForm = () => {
  const [form] = Form.useForm();
  const [message, setMessage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const onReset = () => {
    form.resetFields();
  };

  const onFinish = (values) => {
    // Send a custom event
    ReactGA.event({
      category: 'form',
      action: 'submit',
      label: `contact-submit`, // optional
      value: 5, // optional, must be a number
      nonInteraction: false, // optional, true/false
    });
    onReset();
    setModalOpen(true);

    // emailjs
    //   .send('service_u87r3ih', 'template_ph05v29', values, 'AundnrZiqSX_4ktK5')
    //   .then(
    //     (result) => {
    //       onReset();
    //       setModalOpen(true);
    //     },
    //     (error) => {
    //       console.log('email send error: ');
    //       console.log(error.text);
    //     }
    //   );
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Form Finish Failed: ', errorInfo);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleModalOk = () => {
    // Send a custom event
    ReactGA.event({
      category: 'modal',
      action: 'click',
      label: `support-modal-ok`, // optional
      value: 6, // optional, must be a number
      nonInteraction: false, // optional, true/false
    });

    setModalOpen(false);
  };

  const handleModalCancel = () => {
    // Send a custom event
    ReactGA.event({
      category: 'modal',
      action: 'click',
      label: `support-modal-close`, // optional
      value: 6, // optional, must be a number
      nonInteraction: false, // optional, true/false
    });

    setModalOpen(false);
  };

  return (
    <>
      <div className="contact-form-section">
        <Form
          className="contact-form"
          form={form}
          // ref={formRef}
          requiredMark={true}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row justify="center">
            <Col span={24}>
              <Form.Item
                label="이름"
                labelCol={{ span: 24 }}
                name="name"
                rules={[
                  {
                    required: true,
                    message: '이름을 입력해주세요.',
                  },
                ]}
              >
                <Input placeholder="이름을 입력해주세요." />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="center">
            <Col span={24}>
              <Form.Item
                label="연락처"
                labelCol={{ span: 24 }}
                name="phone"
                rules={[
                  {
                    required: true,
                    message: '연락처를 입력해주세요.',
                  },
                ]}
              >
                <Input placeholder="010-1234-5678" />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="center">
            <Col span={24}>
              <Form.Item
                label="이메일"
                labelCol={{ span: 24 }}
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: '올바르지 않은 이메일 형식입니다.',
                  },
                  {
                    required: true,
                    message: '이메일을 입력해주세요.',
                  },
                ]}
              >
                <Input placeholder="abc@gmail.com" />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="center">
            <Col span={24}>
              <Form.Item
                label="문의 내용"
                labelCol={{ span: 24 }}
                name="message"
                rules={[
                  {
                    required: true,
                    message: '문의 내용을 입력해주세요.',
                  },
                ]}
              >
                <TextArea
                  // autoSize={{
                  //   minRows: 3,
                  //   maxRows: 20,
                  // }}
                  className="contact-form-textarea"
                  value={message}
                  onChange={handleMessageChange}
                  placeholder="문의 내용을 입력해주세요."
                />
              </Form.Item>
            </Col>
          </Row>

          <Row justify={'center'}>
            <Col span={24}>
              <Form.Item className="no-mb">
                <div className="form-submit-btn-wrapper">
                  <Button className="form-submit-btn" htmlType="submit">
                    문의하기
                  </Button>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        {modalOpen ? (
          <Modal
            open={modalOpen}
            onOK={handleModalOk}
            onCancel={handleModalCancel}
            className="form-modal"
            title={
              <>
                <CheckCircleFilled className="modal-title-icon" />
                <span className="modal-title-txt">1:1 문의 완료</span>
              </>
            }
            footer={[
              <Button
                // key="naver"
                // href="https://booking.naver.com/booking/6/bizes/846736"
                type="primary"
                onClick={handleModalOk}
                className="modal-ok-btn"
              >
                확인
              </Button>,
            ]}
          >
            <p>1:1 문의가 접수되었습니다.</p>
            <p>
              작성해주신 이메일로 담당자가 연락 드릴 예정입니다.
              <br />
              원활한 서비스 진행을 위해{' '}
              <Text mark>
                <b>이메일에 회신</b>
              </Text>{' '}
              부탁드립니다.
            </p>
          </Modal>
        ) : null}
      </div>
    </>
  );
};

export default ContactForm;
