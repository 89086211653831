import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Divider } from 'antd';
import { motion, useInView, useAnimation } from 'framer-motion';
import { isMobile } from 'react-device-detect';

import PaybackBenefitIcon01 from '../../../assets/svg/payback-benefit-01.svg';
import PaybackBenefitIcon02 from '../../../assets/svg/payback-benefit-02.svg';
import PaybackBenefitIcon03 from '../../../assets/svg/payback-benefit-03.svg';

const Payback2 = () => {
  const benefitRef01 = useRef(null);
  const benefitRef02 = useRef(null);
  const benefitRef03 = useRef(null);
  const lastSectionRef = useRef(null);
  const isInBenefitView01 = useInView(benefitRef01, { once: true });
  const isInBenefitView02 = useInView(benefitRef02, { once: true });
  const isInBenefitView03 = useInView(benefitRef03, { once: true });
  const isInLastSectionView = useInView(lastSectionRef, { once: true });

  const animateBenefit01 = useAnimation();
  const animateBenefit02 = useAnimation();
  const animateBenefit03 = useAnimation();
  const animateLastSection = useAnimation();

  useEffect(() => {
    if (isInBenefitView01) {
      animateBenefit01.start('visible');
    }

    if (isInBenefitView02) {
      animateBenefit02.start('visible');
    }

    if (isInBenefitView03) {
      animateBenefit03.start('visible');
    }

    if (isInLastSectionView) {
      animateLastSection.start('visible');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isInBenefitView01,
    isInBenefitView02,
    isInBenefitView03,
    isInLastSectionView,
  ]);

  return (
    <>
      <div className="payback-container">
        {/* 상단 설명 & 버튼 모음 */}
        <div className="payback-top-box">
          <div className="left-content">
            블루먼트에서 제품 구매 후 2년 이내로 소장 시계 매각하면서 동시에
            새로운 제품을 구매하면, 기존 시계에 대해 합리적인 매각 보장율을
            제공합니다.
          </div>

          <div className="right-content">
            <div className="button-container">
              <Link to="/buy/collection" target="_blank">
                <button>
                  <span>구매하기</span>
                  <FontAwesomeIcon icon={faAngleRight} />
                </button>
              </Link>

              <Link to="/sell&trade/sell-watch" target="_blank">
                <button>
                  <span>판매하기</span>
                  <FontAwesomeIcon icon={faAngleRight} />
                </button>
              </Link>
            </div>

            <div className="button-container">
              <Link to="/sell&trade/trade-watch" target="_blank">
                <button>
                  <span>Trade In</span> <FontAwesomeIcon icon={faAngleRight} />
                </button>
              </Link>

              <Link to="/contact-us" target="_blank">
                <button>
                  <span>문의하기</span> <FontAwesomeIcon icon={faAngleRight} />
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="payback-benefit-container">
          <div className="title">가격 보장 혜택</div>

          <div className="payback-benefit-box">
            {isMobile ? (
              <>
                <div className="payback-benefit">
                  <img srcSet={PaybackBenefitIcon01} alt="2년 후 재매입" />

                  <div>
                    <div className="benefit-title">2년 후 재매입</div>
                    <div className="benefit-desc">
                      블루먼트와 지속적인 거래를 유지합니다.
                    </div>
                  </div>
                </div>

                <Divider />

                <div className="payback-benefit">
                  <img srcSet={PaybackBenefitIcon02} alt="최대 80% 혜택" />

                  <div>
                    <div className="benefit-title">최대 80% 혜택</div>
                    <div className="benefit-desc">
                      빠른 시간 내에 안전하고 높은 매매가로 시계 판매가
                      가능합니다.
                    </div>
                  </div>
                </div>

                <Divider />

                <div className="payback-benefit">
                  <img
                    srcSet={PaybackBenefitIcon03}
                    alt="Trade Watch 이용 가능"
                  />

                  <div>
                    <div className="benefit-title">Trade In 서비스</div>
                    <div className="benefit-desc">
                      내 시계를 한 번에 매각하고 구매할 수 있는 ONE-STOP
                      서비스로 거래의 편의와 안전을 제공합니다.
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <motion.div
                  variants={{
                    hidden: { opacity: 0, y: 75 },
                    visible: { opacity: 1, y: 0 },
                  }}
                  initial="hidden"
                  animate={animateBenefit01}
                  transition={{ duration: 0.5, delay: 0.25 }}
                  ref={benefitRef01}
                  className="payback-benefit"
                >
                  <img srcSet={PaybackBenefitIcon01} alt="2년 후 재매입" />

                  <div>
                    <div className="benefit-title">2년 후 재매입</div>
                    <div className="benefit-desc">
                      블루먼트와 지속적인 거래를 유지합니다.
                    </div>
                  </div>
                </motion.div>

                <Divider />

                <motion.div
                  variants={{
                    hidden: { opacity: 0, y: 75 },
                    visible: { opacity: 1, y: 0 },
                  }}
                  initial="hidden"
                  animate={animateBenefit02}
                  transition={{ duration: 0.5, delay: 0.25 }}
                  ref={benefitRef02}
                  className="payback-benefit"
                >
                  <img srcSet={PaybackBenefitIcon02} alt="최대 80% 혜택" />

                  <div>
                    <div className="benefit-title">최대 80% 혜택</div>
                    <div className="benefit-desc">
                      빠른 시간 내에 안전하고 높은 매매가로 시계 판매가
                      가능합니다.
                    </div>
                  </div>
                </motion.div>

                <Divider />

                <motion.div
                  variants={{
                    hidden: { opacity: 0, y: 75 },
                    visible: { opacity: 1, y: 0 },
                  }}
                  initial="hidden"
                  animate={animateBenefit03}
                  transition={{ duration: 0.5, delay: 0.25 }}
                  ref={benefitRef03}
                  className="payback-benefit"
                >
                  <img
                    srcSet={PaybackBenefitIcon03}
                    alt="Trade Watch 이용 가능"
                  />

                  <div>
                    <div className="benefit-title">Trade In 서비스</div>
                    <div className="benefit-desc">
                      내 시계를 한 번에 매각하고 구매할 수 있는 ONE-STOP
                      서비스로 거래의 편의와 안전을 제공합니다.
                    </div>
                  </div>
                </motion.div>
              </>
            )}
          </div>

          <div className="desc-footer">
            <div>*기준 가격 : 최초 구매 가격, 부가세 포함한 가격</div>
            <div>
              *시계 진단 및 감정을 통해 매입가를 확정하고 매입 여부를 결정할 수
              있으며, 재매입 대금은 기준보다 감액할 수 있습니다.
            </div>
          </div>
        </div>
      </div>

      <div className="payback-last-section" ref={lastSectionRef}>
        {isMobile ? (
          <div className="title">
            오늘 새로운 시계를 구매하고
            <br />
            가격 보장 프로그램을 통해
            <br />
            <span className="txt-dark-gray">최대 80% 혜택</span>을 받아보세요.
          </div>
        ) : (
          <motion.div
            className="title"
            variants={{
              hidden: { opacity: 0, y: 75 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate={animateLastSection}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            오늘 새로운 시계를 구매하고
            <br />
            가격 보장 프로그램을 통해
            <br />
            <span className="txt-dark-gray">최대 80% 혜택</span>을 받아보세요.
          </motion.div>
        )}

        <Divider />

        <div className="white-sub-title">
          Bluement Guaranteed
          <br />
          by Payback Program
        </div>
      </div>
    </>
  );
};

export default Payback2;
