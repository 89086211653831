import React, { useState, useEffect } from "react";
import {
  Layout,
  Form,
  Input,
  Button,
  // Radio,
  Select,
  InputNumber,
  Modal,
  Typography,
  Row,
  Col,
} from "antd";
import { CheckCircleFilled } from "@ant-design/icons";

// import emailjs from "@emailjs/browser";
// import moment from "moment";

// import ReactGA from "react-ga4";

import { API } from "aws-amplify";
import awsExports from "../../aws-exports";

// Styles
import "../../assets/styles/FormStyle.css";
import MediaQuery from "react-responsive";

const { Content } = Layout;
const { TextArea } = Input;
const { Text } = Typography;

API.configure(awsExports);

const BespokeForm = () => {
  const [form] = Form.useForm();

  const [message, setMessage] = useState("");

  const [modalOpen, setModalOpen] = useState(false);

  const [selectOptions, setSelectOptions] = useState(null); // Select 옵션
  // Select 옵션이 기타일 경우 추가 Input 필수항목으로 지정 필요 여부 결정 (visible도 동시에 결정)
  const [isInputRequired, setIsInputRequired] = useState(false);

  const [disable, setDisable] = useState(false);

  // const createOptions = (rolexData) => {
  //   const options = [];

  //   if (rolexData.length > 0) {
  //     rolexData.forEach((model) => {
  //       const modelObj = {
  //         label:
  //           model.refNo +
  //           ' (' +
  //           model.bracelet_display +
  //           ' ' +
  //           model.dial +
  //           ')',
  //         value: model.rmc + ' / ' + model.bracelet + ' / ' + model.dial,
  //       };

  //       options.push(modelObj);
  //     });
  //   }

  //   options.push({
  //     label: '기타',
  //     value: '기타',
  //   });

  //   setSelectOptions(options);
  // };

  useEffect(() => {
    // Select 옵션 생성 위한 Rolex 전체 모델 불러오기
    // API.get('bluementapi', '/model').then((res) => {
    //   createOptions(res);
    // });

    const selectOptionsDummy = [
      {
        label: "116500LN (Oysterflex Black)",
        value: "116500LN / Oysterflex / Black",
      },
      {
        label: "116500LN (Oysterflex White)",
        value: "116500LN / Oysterflex / White",
      },
      {
        label: "기타",
        value: "기타",
      },
    ];

    setSelectOptions(selectOptionsDummy);

    // });
  }, []);

  const handleModalOk = () => {
    // Send a custom event
    // ReactGA.event({
    //   category: 'modal',
    //   action: 'click',
    //   label: `bespoke-modal-ok`, // optional
    //   value: 6, // optional, must be a number
    //   nonInteraction: false, // optional, true/false
    // });
    // setTimeout(() => {
    //   setModalOpen(false);
    // }, 2000);
    setModalOpen(false);
  };

  const handleModalCancel = () => {
    // Send a custom event
    // ReactGA.event({
    //   category: 'modal',
    //   action: 'click',
    //   label: `bespoke-modal-close`, // optional
    //   value: 6, // optional, must be a number
    //   nonInteraction: false, // optional, true/false
    // });
    setModalOpen(false);
  };

  // form 제출 후 reset
  const onReset = () => {
    form.resetFields();
    onSelectClear();
  };

  // Select의 clear 버튼 클릭 시
  const onSelectClear = () => {
    setIsInputRequired(false);
  };

  const onSelectModel = (value, option) => {
    if (value === "기타") {
      setIsInputRequired(true);
    } else {
      setIsInputRequired(false);
    }
  };

  const onMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Bespoke Form Finish Failed: ", errorInfo);
  };

  const onFinish = async (values) => {
    // Send a custom event
    // ReactGA.event({
    //   category: 'form',
    //   action: 'submit',
    //   label: `bepoke-submit`, // optional
    //   value: 5, // optional, must be a number
    //   nonInteraction: false, // optional, true/false
    // });

    setDisable(true);

    // const formValues = {
    //   body: {
    //     name: values.name,
    //     phone: values.phone,
    //     email: values.email,
    //     priceWanted: values.price,
    //     message: values.message,
    //     source: '홈페이지',
    //   },
    // };

    // const emailContent = {
    //   createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
    //   name: values.name,
    //   phone: values.phone,
    //   email: values.email,
    //   price: values.price,
    //   message: values.message,
    //   source: '홈페이지',
    // };

    // // 기타 모델이 선택되었을 경우, wantedModelInput 내용 사용
    // if (values.wantedModel === '기타') {
    //   formValues.body.etcModel = values.wantedModelInput;
    //   emailContent.model = values.wantedModelInput;
    // } else {
    //   const modelInfo = values.wantedModel.split('/');
    //   emailContent.model = values.wantedModel;
    //   formValues.body.refNo = modelInfo[0];
    //   formValues.body.bracelet = modelInfo[1];
    //   formValues.body.dial = modelInfo[2];
    // }

    // emailjs
    //   .send(
    //     'service_do57zih',
    //     'template_v62zqdh',
    //     emailContent,
    //     '3zBvx43751ex39uxe'
    //   )
    //   .then(
    //     (result) => {
    //       console.log('email sent successfully.');

    //       onReset();
    //       setModalOpen(true);
    //       setDisable(false);
    //       setTimeout(() => {
    //         window.open('/', '_blank', 'noopener, noreferrer');
    //       }, 2000);
    //     },
    //     (error) => {
    //       console.log('email send error: ');
    //       console.log(error.text);
    //     }
    //   );

    onReset();
    setModalOpen(true);
    setDisable(false);

    // DB에 post
    // return await API.post('formApi', '/bespoke', formValues)
    //   .then((res) => {})
    //   .catch((err) => {
    //     console.error(
    //       'error creating/updating data to Bespoke DB: ',
    //       err.response
    //     );
    //   });
  };

  return (
    <Layout>
      <Content>
        <Row justify="end" className="form-section-title">
          <Col span={9} className="text-center">
            <div className="form-label">
              <span className="essential">*</span>필수입력
            </div>
          </Col>
        </Row>

        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          requiredMark={true}
          className="service-form"
        >
          {/* 이름 */}
          <Row justify="center">
            <Col span={16}>
              <Form.Item
                label="이름"
                labelCol={{ span: 24 }}
                name="name"
                rules={[{ required: true, message: "이름을 입력해주세요." }]}
              >
                <Input placeholder="홍길동" />
              </Form.Item>
            </Col>
          </Row>

          {/* 연락처 */}
          <Row justify="center">
            <Col span={16}>
              <Form.Item
                label="연락처"
                labelCol={{ span: 24 }}
                name="phone"
                rules={[{ required: true, message: "연락처를 입력해주세요." }]}
              >
                <Input placeholder="010-1234-5678" />
              </Form.Item>
            </Col>
          </Row>

          {/* 이메일 */}
          <Row justify="center">
            <Col span={16}>
              <Form.Item
                label="이메일"
                labelCol={{ span: 24 }}
                name="email"
                rules={[
                  { required: true, message: "이메일을 입력해주세요." },
                  {
                    type: "email",
                    message: "이메일 형식이 올바르지 않습니다.",
                  },
                ]}
              >
                <Input placeholder="abc@gmail.com" />
              </Form.Item>
            </Col>
          </Row>

          {/* 구매 희망 모델명 */}
          <Row justify="center" className={isInputRequired ? "no-mb" : ""}>
            <Col
              span={16}
              className={isInputRequired ? "refNo-form-col" : "mb-24"}
            >
              <Form.Item
                className="refNo-form-item form-item-with-guideText"
                label="구매 희망 모델"
                labelCol={{ span: 24 }}
                name="wantedModel"
                rules={[
                  {
                    required: true,
                    message: "구매를 희망하시는 모델명을 선택해주세요.",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear={true}
                  loading={selectOptions === null ? true : false}
                  disabled={selectOptions === null ? true : false}
                  placeholder="모델 선택 또는 검색 (없다면 '기타')"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={selectOptions}
                  onClear={onSelectClear}
                  onSelect={onSelectModel}
                />
              </Form.Item>
              <span className="pl-2">
                ※ 목록에 모델이 없다면 '기타'를 입력해주세요.
              </span>
            </Col>
          </Row>

          {/* 구매 희망 모델명 - 기타 선택 시 입력 필드 */}
          <Row justify="center" className={isInputRequired ? "" : "hidden"}>
            <Col span={16}>
              <Form.Item
                name="wantedModelInput"
                rules={[
                  {
                    required: isInputRequired,
                    message: "구매를 희망하시는 모델명을 직접 입력해주세요.",
                  },
                ]}
              >
                <Input placeholder="ex) 116500LN / Black / Oyster" />
              </Form.Item>
            </Col>
          </Row>

          {/* 구매 희망 가격 */}
          <Row justify="center">
            <Col span={16} className="mb-24">
              <Form.Item
                className="form-item-with-guideText"
                label="구매 희망 가격 (숫자만)"
                labelCol={{ span: 24 }}
                name="price"
                rules={[
                  {
                    required: true,
                    message: "원하시는 구매 가격을 입력해주세요.",
                  },
                  {
                    type: "number",
                    message: "숫자만 입력해주세요.",
                  },
                ]}
              >
                {/* <Input placeholder="10000000" /> */}
                <InputNumber
                  addonAfter="원"
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
              <span className="pl-2">※ 제품 구입가는 변경될 수 있습니다.</span>
            </Col>
          </Row>

          {/* 문의 내용 */}
          <Row justify="center">
            <Col span={16}>
              <Form.Item
                label="요청사항"
                labelCol={{ span: 24 }}
                name="message"
              >
                <TextArea
                  autoSize={{
                    minRows: 5,
                    maxRows: 10,
                  }}
                  value={message}
                  onChange={onMessageChange}
                  placeholder={`자세히 작성하시면 상담에 큰 도움이 됩니다.
예) 예물 할 수 있는 상품 찾고 있습니다. 6개월 후 결혼 예정입니다.`}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="center form-guide-row">
            <Col span={16}>
              <p className="red-txt text-center form-guide">
                <MediaQuery maxWidth={458.98}>
                  작성해주신 <b>이메일</b>로 <br />
                  담당자가 연락 드릴 예정입니다.
                  <br />
                  원활한 서비스 진행을 위해 <br />
                  이메일에 <b>회신</b> 부탁드립니다.
                </MediaQuery>
                <MediaQuery minWidth={459}>
                  작성해주신 <b>이메일</b>로 담당자가 연락 드릴 예정입니다.
                  <br />
                  원활한 서비스 진행을 위해 이메일에 <b>회신</b> 부탁드립니다.
                </MediaQuery>
              </p>
            </Col>
          </Row>

          {/* 제출 버튼 */}
          <Row justify={"center"}>
            <Col span={16}>
              <Form.Item>
                <Button
                  className="form-btn-submit"
                  htmlType="submit"
                  disabled={disable}
                >
                  매입요청 & 방문예약
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        {modalOpen ? (
          <Modal
            open={modalOpen}
            onOK={handleModalOk}
            onCancel={handleModalCancel}
            className="form-modal"
            title={
              <>
                <CheckCircleFilled className="modal-title-icon" />
                <span className="modal-title-txt">매입 요청 완료</span>
              </>
            }
            footer={[
              <Button
                // key="naver"
                // href="https://booking.naver.com/booking/6/bizes/846736"
                type="primary"
                onClick={handleModalOk}
                className="modal-ok-btn"
              >
                확인
              </Button>,
            ]}
          >
            <p>매입 요청 문의가 접수되었습니다.</p>
            <p>
              작성해주신 이메일로 담당자가 연락 드릴 예정입니다.
              <br />
              원활한 서비스 진행을 위해{" "}
              <Text mark>
                <b>이메일에 회신</b>
              </Text>{" "}
              부탁드립니다.
            </p>
            {/* <p>
              아래 버튼을 클릭하시면, 방문 예약을 위해 네이버 예약 페이지로
              이동합니다.
            </p>
            <p>
              <Text mark>원하시는 방문 날짜와 시간으로 예약 신청</Text>을
              해주시면, 담당자가 확인 후 예약을 확정해드립니다.
            </p> */}
          </Modal>
        ) : null}
      </Content>
    </Layout>
  );
};

export default BespokeForm;
