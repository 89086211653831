import React from "react";
import { Helmet } from "react-helmet-async";

const MetaTag = (props) => {
  // props로 content 내용을 불러올 예정임
  const og_img =
    "https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/og-img.jpeg";

  return (
    <Helmet>
      <title>{props.title}</title>

      <meta name="description" content={props.description} />
      <meta name="keywords" content={props.keywords} />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={props.title} />
      <meta property="og:site_name" content={props.title} />
      <meta property="og:description" content={props.description} />
      <meta property="og:image" content={og_img} />
      <meta property="og:image:secure_url" content={og_img} />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:url" content={props.url} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={props.title} />
      <meta name="twitter:description" content={props.description} />
      <meta name="twitter:image" content={og_img} />
      <meta name="twitter:image:secure_url" content={og_img} />
      <meta property="twitter:image:type" content="image/jpeg" />
      <meta property="twitter:url" content={props.url} />

      <link rel="canonical" href={props.url} />
    </Helmet>
  );
};

MetaTag.defaultProps = {
  title: "블루먼트 | BLUEMENT",
  description: "Approved Pre-Owned Luxury Watch by BLUEMENT Experts",
  keywords:
    "rolex,롤렉스,중고롤렉스,블루먼트,bluement,cpo,certified pre-owned watch,pre-owned,preowned,preowned rolex,명품,명품시계,시계,거래,타임피스,거래 플랫폼",
  url: "https://www.bluement.kr",
};

export default MetaTag;
