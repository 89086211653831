import { createContext, useEffect } from 'react';
import ReactGA from 'react-ga4';

export const AnalyticsContext = createContext();

export const AnalyticsProvider = ({ children }) => {
  useEffect(() => {
    ReactGA.initialize('G-PRRY0JDB7Q');
  }, []);

  return (
    <AnalyticsContext.Provider value={{ analytics: ReactGA }}>
      {children}
    </AnalyticsContext.Provider>
  );
};
