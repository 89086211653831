import React from "react";

import "./CPOEngineer.css";

const CPOEngineer = () => {
  return (
    <div className="cpo-engineer-container">
      <div className="inner-container">
        <div className="title cpo-title">
          엄격한 진단과 점검 그리고 인증 시계
        </div>
        <div className="box-container">
          <div className="box-left">
            <picture>
              <source
                srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/cpo/cpo-engineering-color.webp"
                loading="lazy"
                type="image/webp"
              />
              <source
                srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/cpo/cpo-engineering-color.avif"
                loading="lazy"
                type="image/avif"
              />
              <img
                srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/cpo/cpo-engineering-color.jpg"
                loading="lazy"
                alt="cpo-engineering"
              />
            </picture>
          </div>
          <div className="box-right-container">
            <div className="box-right">
              <div className="content">
                <div className="paragraph">
                  <div className="paragraph-title">Quality Testing</div>

                  <div className="paragraph-desc">
                    명품 시계 브랜드 매뉴얼을 마스터한 전문 워치메이커는 개별
                    시계의 최상의 품질을 테스트하고 진단합니다. <br />
                    <br />
                    -가품에 대한 300% 보상
                    <br />
                    -성능 및 품질에 2년간 보증
                    <br />
                    -인증 시계로의 안전한 거래 가능
                    <br />
                    -30개 이상 항목의 엄격한 진단과 점검을 통과
                  </div>
                </div>

                <div className="paragraph">
                  <div className="paragraph-title">Approval</div>

                  <div className="paragraph-desc">
                    <p>
                      30개 이상의 항목 검사를 통과한 제품에 Bluement Approved
                      Pre-Owned 시계의 자격이 부여됩니다. 모든 인증 시계는 2년의
                      Bluement 보증과 함께 판매됩니다.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CPOEngineer;
