import React from 'react';
// import MediaQuery from 'react-responsive';

import ServiceDetails from './ServiceDetails';

const IntroSection = () => {
  return (
    <div className="intro-section-container">
      <div className="title">진단 및 검증 서비스</div>

      <div className="desc">
        안전하고 신뢰할 수 있는 진단 및 검증 프로그램으로 내 시계 상태와 시계
        가치를 정확히 확인해 보세요.
        <br />내 시계가 가지고 있는 가치와 이야기를 시계 전문가들이 정확히
        설명해 드립니다.
      </div>

      <ServiceDetails />
    </div>
  );
};

export default IntroSection;
