import React from "react";
import { Row, Col } from "antd";
// import MediaQuery from 'react-responsive';

import "../../assets/styles/CircledCard.css";

const SellCircledCard = () => {
  return (
    <>
      <Row
        justify="space-around"
        className="circledCard-container"
        gutter={[0, 100]}
      >
        <Col xs={24} sm={24} md={24} lg={7} xl={7} xxl={7}>
          <div className="circledCard">
            <div className="circledCard-thumbnail">
              <picture>
                <source
                  srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/benefit/sell01-1.webp"
                  type="image/webp"
                  loading="lazy"
                />
                <source
                  srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/benefit/sell01-1.avif"
                  type="image/avif"
                  loading="lazy"
                />
                <img
                  srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/benefit/sell01-1.jpg"
                  alt="안전한 상담과 즉시 현금 지급"
                  loading="lazy"
                />
              </picture>
            </div>

            <div className="circledCard-content">
              <div className="circledCard-title card-title">
                안전한 상담,
                <br />
                즉시 현금 지급
              </div>
              <div className="circledCard-desc card-desc">
                명품 브랜드 수준의 부티크에서 1:1 안전 상담을 진행합니다. 거래
                완료 후 현장에서 바로 대금이 입금됩니다.
              </div>
            </div>
          </div>
        </Col>

        <Col xs={24} sm={24} md={24} lg={7} xl={7} xxl={7}>
          <div className="circledCard">
            <div className="circledCard-thumbnail">
              <picture>
                <source
                  srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/benefit/sell02.webp"
                  type="image/webp"
                  loading="lazy"
                />
                <source
                  srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/benefit/sell02.avif"
                  type="image/avif"
                  loading="lazy"
                />
                <img
                  srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/benefit/sell02.jpg"
                  alt="재매각 상품의 가격 보장"
                  loading="lazy"
                />
              </picture>
            </div>

            <div className="circledCard-content">
              <div className="circledCard-title card-title">
                재매각 상품의
                <br />
                가격 보장
              </div>
              <div className="circledCard-desc card-desc">
                블루먼트에서 구매 이력이 있다면, 누구나 보유 시계를 2년 이내
                처분 시 합리적인 매각률을 보장받습니다.
              </div>
            </div>
          </div>
        </Col>

        <Col xs={24} sm={24} md={24} lg={7} xl={7} xxl={7}>
          <div className="circledCard">
            <div className="circledCard-thumbnail">
              <picture>
                <source
                  srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/sample/sell_section2-1.webp"
                  type="image/webp"
                  loading="lazy"
                />
                <source
                  srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/sample/sell_section2-1.avif"
                  type="image/avif"
                  loading="lazy"
                />
                <img
                  srcSet="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/sample/sell_section2-1.jpg"
                  alt="워치메이커의 정확한 상태 및 성능 진단"
                  loading="lazy"
                />
              </picture>
            </div>

            <div className="circledCard-content">
              <div className="circledCard-title card-title">
                워치메이커의 정확한
                <br />
                상태 및 성능 진단
              </div>
              <div className="circledCard-desc card-desc">
                명품 시계 브랜드 매뉴얼을 마스터한 전문 워치메이커가 시계의
                상태와 성능을 철저하게 검수 및 진단합니다.
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SellCircledCard;
