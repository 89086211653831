import React from 'react';

import ServiceDetails from './ServiceDetails';

const IntroSection = () => {
  return (
    <div className="intro-section-container">
      <div className="title">종합 관리 서비스</div>

      <div className="desc">
        블루먼트의 종합 관리 서비스는 일상 생활에서 내 시계의 성능과 품질을
        유지할 수 있도록 도와줍니다.
      </div>

      <ServiceDetails />

      <div className="video">
        <video autoPlay loop muted playsInline>
          <source
            src="https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/video/complete-care-mixed.mp4"
            type="video/mp4"
          />
        </video>
      </div>
    </div>
  );
};

export default IntroSection;
