import React from 'react';
import { Layout } from 'antd';

// import { AnalyticsContext } from '../../Analytics.js';

import Privacy202304 from '../components/Policy/Privacy/Privacy202304';
import MetaTag from '../MetaTag.js';

const { Content } = Layout;

const Privacy = () => {
  return (
    <>
      <MetaTag
        title="블루먼트 | 개인정보처리방침"
        url="https://www.bluement.kr/terms/privacy"
      />
      <Layout>
        <Content>
          {/* <HeadingBanner
            titleImgUrl="privacy-policy.png"
            imgUrl="hall-hands-04-squashed"
            title="개인정보처리방침"
          /> */}

          <div className="privacy-section">
            <h1 className="section-title">개인정보처리방침</h1>
            <Privacy202304 />
          </div>
        </Content>
      </Layout>
    </>
  );
};

export default Privacy;
